var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\">";
output += runtime.suppressValue(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing Book: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name"))), env.opts.autoescape);
output += "</div>\n\n<div class=\"modal-body ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"newest")?"spaced":""), env.opts.autoescape);
output += "\">\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"newest")) {
output += "\n\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "showOlder")) {
output += "\n\t\t\t<div class=\"newer\"><span class=\"fal fa-exclamation-circle\"/> This e-book failed it's latest encryption attempt. You are viewing the <strong>older, active</strong> metadata. Click here to view the <strong>newer, pending</strong> metadata.</div>\n\t\t";
;
}
else {
output += "\n\t\t\t<div class=\"newer\"><span class=\"fal fa-exclamation-circle\"/> This e-book failed it's latest encryption attempt. You are viewing the <strong>newer, pending</strong> metadata. Click here to view the <strong>older, active</strong> metadata.</div>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\t<div class=\"book-metadata\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Book name</label>\n\t\t\t\t<div class=\"pre-title\"><strong>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "</strong></div><br/>\n\t\t\t\t<label>\n\t\t\t\t\tIf you don't want to use the value above as the book's name, type another name into the box below.\n\t\t\t\t\t<input type=\"text\" name=\"title\" placeholder=\"New book name\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPublisher\n\t\t\t\t\t<div class=\"textual bold\">\n\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "fullname"), env.opts.autoescape);
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label>\n\t\t\t\t\teISBN\n\t\t\t\t\t<div class=\"textual bold\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "identifier"), env.opts.autoescape);
output += "</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tUniversal ID\n\t\t\t\t\t<div class=\"textual bold\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "universal_id"), env.opts.autoescape);
output += "</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tYear of study\n\t\t\t\t\t<select name=\"grade\" data-placeholder=\"Year of study\" multiple ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tLanguage\n\t\t\t\t\t<select name=\"language\" data-placeholder=\"Book language\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tBook subject\n\t\t\t\t\t<select name=\"subject\" data-placeholder=\"Book subject\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tTarget markets\n\t\t\t\t\t<select name=\"markets\" data-placeholder=\"Book markets\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"row collapse\">\n\t\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tRegions\n\t\t\t\t\t\t\t<select name=\"regions\" data-placeholder=\"Book regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff") || (runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL"))?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t\t<div class=\"large-11 large-offset-1\">\n\t\t\t\t\t\t\t<label id=\"regionExceptions\">\n\t\t\t\t\t\t\t\tExcept\n\t\t\t\t\t\t\t\t<select name=\"regionExceptions\" data-placeholder=\"Book regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t\t\t<option value=\"\">\n\t\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "countryMap");
if(t_3) {var t_1;
if(runtime.isArray(t_3)) {
var t_2 = t_3.length;
for(t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1][0]
frame.set("key", t_3[t_1][0]);
var t_5 = t_3[t_1][1]
frame.set("country", t_3[t_1][1]);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "regionExclusions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regionExclusions"),t_4)?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(t_5, env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
} else {
t_1 = -1;
var t_2 = runtime.keys(t_3).length;
for(var t_6 in t_3) {
t_1++;
var t_7 = t_3[t_6];
frame.set("key", t_6);
frame.set("country", t_7);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_6, env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "regionExclusions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regionExclusions"),t_6)?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(t_7, env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allRegions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL")?"checked":""), env.opts.autoescape);
output += "/> All regions</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<span class=\"price-tooltip ";
output += runtime.suppressValue((!(lineno = 114, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"hide":""), env.opts.autoescape);
output += "\">(Specify at least one price. Any left empty will be automatically converted every day.)</span>\n\t\t\t\t<span class=\"price-tooltip ";
output += runtime.suppressValue(((lineno = 115, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"hide":""), env.opts.autoescape);
output += "\"><strong>Price fields are locked because you do not have permission to edit prices!</strong></span>\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-2 columns aud priceBlock\">\n\t\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "aud") || runtime.contextOrFrameLookup(context, frame, "aud") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "aud")):""), env.opts.autoescape);
output += "\" max=\"10000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || !(lineno = 119, colno = 162, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns eur priceBlock\">\n\t\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "eur") || runtime.contextOrFrameLookup(context, frame, "eur") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "eur")):""), env.opts.autoescape);
output += "\" max=\"10000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || !(lineno = 124, colno = 162, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns gbp priceBlock\">\n\t\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "gbp") || runtime.contextOrFrameLookup(context, frame, "gbp") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "gbp")):""), env.opts.autoescape);
output += "\" max=\"10000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || !(lineno = 129, colno = 162, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns usd priceBlock\">\n\t\t\t\t\t\t<label>USD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "usd") || runtime.contextOrFrameLookup(context, frame, "usd") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "usd")):""), env.opts.autoescape);
output += "\" max=\"10000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || !(lineno = 134, colno = 162, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns zar end priceBlock\">\n\t\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "zar") || runtime.contextOrFrameLookup(context, frame, "zar") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "zar")):""), env.opts.autoescape);
output += "\" max=\"10000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || !(lineno = 139, colno = 162, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "priceChangePermission"), "priceChangePermission", context, []))?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tCurricula (optional)\n\t\t\t\t\t<select name=\"curriculum\" data-placeholder=\"Book curriculum\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t<option value=\"\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_10 = runtime.contextOrFrameLookup(context, frame, "curriculumList");
if(t_10) {var t_9 = t_10.length;
for(var t_8=0; t_8 < t_10.length; t_8++) {
var t_11 = t_10[t_8];
frame.set("cur", t_11);
frame.set("loop.index", t_8 + 1);
frame.set("loop.index0", t_8);
frame.set("loop.revindex", t_9 - t_8);
frame.set("loop.revindex0", t_9 - t_8 - 1);
frame.set("loop.first", t_8 === 0);
frame.set("loop.last", t_8 === t_9 - 1);
frame.set("loop.length", t_9);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_11),"value"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "curriculum") && (lineno = 153, colno = 83, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "curriculum")),"indexOf"), "curriculum[\"indexOf\"]", context, [runtime.memberLookup((t_11),"value")])) > -1?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_11),"name"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tEdition\n\t\t\t\t\t<input type=\"text\" name=\"edition\" placeholder=\"Book edition\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "edition")), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tContent Rating\n\t\t\t\t\t<select name=\"contentrating\" data-placeholder=\"Content Rating\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tPrint ISBN\n\t\t\t\t\t\t\t<input type=\"text\" name=\"printIsbn\" placeholder=\"Print ISBN\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "print_isbn")), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<input type=\"checkbox\" name=\"printIsbnCheck\"> No Print ISBN\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPublication date (optional)\n\t\t\t\t\t<input type=\"text\" name=\"pubDate\" placeholder=\"Click to choose date\" readonly/>\n\t\t\t\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "reviewed") && !runtime.contextOrFrameLookup(context, frame, "bulkSignoff")) {
output += "<div class=\"input-overlay hide\" title=\"Clear field\"><span class=\"fal fa-times\"/></div>";
;
}
output += "\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"books")),"changeTags")))) {
output += "\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tTags (optional)\n\t\t\t\t\t<select name=\"tags\" data-placeholder=\"Tags\" multiple></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"allowPreview\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "allow_preview")?"checked":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\tPreview book in ITSI Store\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns pages-section hide\">\n\t\t\t\t<label>\n\t\t\t\t\tSpecify pages to preview:\n\t\t\t\t\t<input type=\"text\" name=\"previewPages\" placeholder=\"Eg: 1-5, 7, 15-20, 22, 25-30\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "preview_pages"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tContributors (one per line)\n\t\t\t\t\t<textarea name=\"contributors\" placeholder=\"Book contributors\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += " rows=\"8\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "contributors"),"\n")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>Keywords (optional, one per line)\n\t\t\t\t\t<textarea name=\"keywords\" placeholder=\"Book keywords\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += " rows=\"8\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "keywords"),"\n")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Abstract (optional)\n\t\t\t\t\t<textarea name=\"abstract\" placeholder=\"Book abstract\" readonly rows=\"8\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "abstract"), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<!--<div class=\"row\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "extension") == "pdf") {
output += "\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>PDF Restrictions (<a name=\"restrict-details\">What are these?</a>)\n\t\t\t\t\t<strong><div name=\"restrictions\">";
output += runtime.suppressValue(env.getFilter("showPdfRestrictions").call(context, runtime.contextOrFrameLookup(context, frame, "pdfRestrictions")), env.opts.autoescape);
output += "</div></strong>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>-->\n\t</div>\n\t<div class=\"book-cover-image-chooser\">\n\t\t<label>Click below to choose or change cover image</label><br/>\n\t\t<div class=\"book-cover-image\"></div>\n\t\t<br />\n\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"books")),"disableFtpImports")))) {
output += "\n\t\t<label>\n\t\t\t<input type=\"checkbox\" name=\"disableFtpImports\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "disable_ftp_import")?"checked":""), env.opts.autoescape);
output += ">&nbsp;&nbsp;Disable FTP imports\n\t\t</label>\n\t\t";
;
}
output += "\n\t</div>\n</div>\n\n<div class=\"modal-footer\">\n\t";
if(runtime.contextOrFrameLookup(context, frame, "state") != 1 && !runtime.contextOrFrameLookup(context, frame, "bulkSignoff")) {
output += "<button class=\"button small save success\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"newest") && !runtime.contextOrFrameLookup(context, frame, "showOlder")?"disabled":""), env.opts.autoescape);
output += ">Save Changes</button>&nbsp;&nbsp;&nbsp;";
;
}
output += "\n\t<!--<a class=\"button small changelog\">View Changelog</a>&nbsp;&nbsp;&nbsp;-->\n\t";
if(!runtime.contextOrFrameLookup(context, frame, "bulkSignoff")) {
output += "<a class=\"button small secondary cancel\">Cancel</a>&nbsp;&nbsp;&nbsp;";
;
}
output += "\n\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
