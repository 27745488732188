module.exports=[
	{
		"name": "South African Rand",
		"abbr": "ZAR",
		"symbol": "R",
		"countries": ["ZA", "NA"],
		"vat": 15,
		"minDistributionFee": 9.5
	},
	{
		"name": "Australian Dollar",
		"abbr": "AUD",
		"symbol": "$",
		"countries": ["AU", "CX", "CC", "HM", "KI", "NR", "NF", "TV"],
		"vat": 0,
		"minDistributionFee": 1
	},
	{
		"name": "US Dollar",
		"abbr": "USD",
		"symbol": "$",
		"countries": ["US", "AE"],
		"vat": 0,
		"minDistributionFee": 1
	},
	{
		"name": "British Pound",
		"abbr": "GBP",
		"symbol": "£",
		"countries": ["GB", "IM", "JE", "GG", "GS", "IO"],
		"vat": 0,
		"minDistributionFee": 1
	},
	{
		"name": "Euro",
		"abbr": "EUR",
		"symbol": "€",
		"countries": ["AD", "AT", "BE", "CY", "EE", "FI", "FR", "DE", "GR", "GP", "IE", "IT", "LV", "LT", "LU", "MT", "MQ", "YT", "MC", "ME", "NL", "PT", "RE", "BL", "PM", "SM", "SK", "SI", "ES", "VA"],
		"vat": 0,
		"minDistributionFee": 1
	}
]