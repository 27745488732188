var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"overlay\"></div>\n\n<div class=\"years\">Creating Book Lists for the <strong id=\"yearDisplay\" data-toggle=\"yearDropdown\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "year"), env.opts.autoescape);
output += "</strong> school year <strong></strong></div>\n\n<div class=\"dropdown-pane\" id=\"yearDropdown\" data-dropdown data-hover=\"true\" data-hover-pane=\"true\">\n\t<h5>Select Book List year</h5>\n\n\n  ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "years");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("y", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n  \t";
var t_5;
t_5 = "";
frame.set("checked", t_5, true);
if(frame.topLevel) {
context.setVariable("checked", t_5);
}
if(frame.topLevel) {
context.addExport("checked", t_5);
}
output += "\n\n  \t";
if(t_4 == runtime.contextOrFrameLookup(context, frame, "year")) {
output += "\n  \t\t";
var t_6;
t_6 = "checked";
frame.set("checked", t_6, true);
if(frame.topLevel) {
context.setVariable("checked", t_6);
}
if(frame.topLevel) {
context.addExport("checked", t_6);
}
output += "\n  \t";
;
}
output += "\n\n  \t<input class=\"selectYear\" type=\"radio\" name=\"year\" value=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "checked"), env.opts.autoescape);
output += "> <label for=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += " </label><br>\n\n  ";
;
}
}
frame = frame.pop();
output += "\n\n</div>\n\n<div id=\"booksArea\">\n\t<h3>\n\t<div class=\"switch large\">\n\t\t<input class=\"switch-input\" id=\"productswitch\" type=\"checkbox\" checked name=\"productswitch\">\n\t\t<label class=\"switch-paddle\" for=\"productswitch\">\n\t\t\t<span class=\"show-for-sr\">Book or License</span>\n\t\t\t<span class=\"switch-active\" aria-hidden=\"true\">Books</span>\n\t\t\t<span class=\"switch-inactive\" aria-hidden=\"true\">Licenses</span>\n\t\t</label>\n\t</div>\n\t</h3>\n\t<div class=\"filters\"></div>\n\t<div class=\"pagination\"></div>\n\t<div class=\"counter\"></div>\n\t<div id=\"books\"></div>\n</div>\n";
if(runtime.contextOrFrameLookup(context, frame, "schools")) {
output += "\n<div id=\"listsArea\">\n\n\t<h3>Book Lists for <span class=\"schoolName\" data-open=\"changeSchoolModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "revealRandom"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "schoolName")), env.opts.autoescape);
output += "</span>\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "multiSchool")) {
output += "\n\t\t\t<i class=\"fal fa-edit fa-fw\" aria-hidden=\"true\" data-open=\"changeSchoolModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "revealRandom"), env.opts.autoescape);
output += "\"></i>\n\t\t";
;
}
output += "\n\t</h3>\n\t<div class=\"book-list-top\">\n\t\t<input type=\"text\" name=\"bookListSearch\" id=\"bookListSearch\" placeholder=\"Search for lists or books\">\n\t\t<div class=\"listCounter\"></div>\n\t</div>\n\n\t<div id=\"lists\"></div>\n\n\t<div id=\"container-floating\">\n\n\t\t<div data-tooltip aria-haspopup=\"true\" class=\"nd3 nds has-tip left\" data-disable-hover=\"false\" title=\"Paste list(s)\" id=\"pasteSchoolListsBtn\">\n\t  \t\t<i class=\"fal fa-clipboard\" aria-hidden=\"true\"></i>\n\t  \t</div>\n\n\t  <div data-tooltip aria-haspopup=\"true\" class=\"nd1 nds has-tip left\" data-disable-hover=\"false\" title=\"Copy all lists\" id=\"copySchoolListsBtn\">\n\t  \t<i class=\"fal fa-copy\" aria-hidden=\"true\"></i>\n\t  </div>\n\n\t  <div data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\" has-tip left\" id=\"floating-button\" data-open=\"createListModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "schoolId"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "revealRandom"), env.opts.autoescape);
output += "\" title=\"Create new book list.\">\n\t    <p class=\"plus\">+</p>\n\t    <img class=\"edit\" src=\"https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png\">\n\t  </div>\n\n\t</div>\n\n\t<div class=\"listPaging\"></div>\n\n\n</div>\n\n<div class=\"reveal\" id=\"createListModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "schoolId"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "revealRandom"), env.opts.autoescape);
output += "\" data-reveal>\n\t<h4>Create new book list</h4>\n\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t<span aria-hidden=\"true\">&times;</span>\n\t</button>\n\n  \t<div class=\"body\">\n  \t\t<form>\n\t  \t<label>Name</label>\n\t  \t<input type=\"text\" name=\"listName\" id=\"listName\" placeholder=\"List Name\" required>\n\n\t  \t<label>Description</label>\n\t  \t<input type=\"text\" name=\"listDescription\" id=\"listDescription\" placeholder=\"List Description\">\n\n\t  \t<label>Languages</label>\n\t  \t<select name=\"languages\" data-placeholder=\"Select Languages\" multiple>\n\t\t</select>\n\n\t\t<label>Grades</label>\n\t  \t<select name=\"grades\" data-placeholder=\"Select Grades\" multiple>\n\t\t</select>\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "schoolId") == 0) {
output += "\n\t\t\t<label>Store</label>\n\t\t  \t<select name=\"store\" data-placeholder=\"Select Store\">\n\t\t\t</select>\n\t\t";
;
}
output += "\n\t</form>\n\n\n\t\t<div class=\"alert callout hide validationMessageCallout\">\n\t\t\t<h5 class=\"validationMessage\"></h5>\n\t\t</div>\n\n\n\t</div>\n\n\t<div class=\"modalButtons\">\n\t\t<button class=\"createList button small success\" type=\"submit\">Create</button>\n\t\t<button class=\"button small secondary\" type=\"button\" data-close>Cancel</button>\n\t</div>\n\n</div>\n\n\n\t<div class=\"reveal\" id=\"changeSchoolModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "revealRandom"), env.opts.autoescape);
output += "\" data-reveal>\n\t  <h4>Change school</h4>\n\t  <button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t    <span aria-hidden=\"true\">&times;</span>\n\t  </button>\n\n\t  <div class=\"body\">\n\t  \t<form>\n\t\t  ";
frame = frame.push();
var t_9 = runtime.contextOrFrameLookup(context, frame, "schools");
if(t_9) {var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("school", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
output += "\n\n\t\t  \t<input class=\"schoolChoice\" type=\"radio\" name=\"school\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"school_id"), env.opts.autoescape);
output += "\" id=\"school";
output += runtime.suppressValue(runtime.memberLookup((t_10),"school_id"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_10),"fullname") == runtime.contextOrFrameLookup(context, frame, "schoolName")) {
output += "checked";
;
}
output += " data-close>\n\t\t  \t<label for=\"school";
output += runtime.suppressValue(runtime.memberLookup((t_10),"school_id"), env.opts.autoescape);
output += "\" id=\"schoolLabel";
output += runtime.suppressValue(runtime.memberLookup((t_10),"school_id"), env.opts.autoescape);
output += "\" class=\"schooLabel\" data-close>";
output += runtime.suppressValue(runtime.memberLookup((t_10),"fullname"), env.opts.autoescape);
output += "</label>\n\t\t  \t</br>\n\n\t\t  ";
;
}
}
frame = frame.pop();
output += "\n\t\t</form>\n\t  </div>\n\t</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
