var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var regions_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"nav-header\">\n\t\t<label class=\"search-box\">\n\t\t\t<div class=\"fal fa-search\"></div>\n\t\t\t<input type=\"text\" placeholder=\"Search for page or text...\" required title=\" \"/>\n\t\t\t<div class=\"input-overlay fal fa-times\" title=\"Clear field\"/>\n\t\t</label>\n\t\t<span class=\"fal fa-times hide\" title=\"Close navigation pane\">&nbsp;</span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var regions_oldRoot = regions_obj.root;
regions_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	regions_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var pages_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"title\">Pages</div>\n\t<div class=\"content\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var pages_oldRoot = pages_obj.root;
pages_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	pages_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var body_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t";
var macro_t_1 = runtime.makeMacro(
["main", "prefix"], 
[], 
function (l_main, l_prefix, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("main", l_main);
frame.set("prefix", l_prefix);
var t_2 = "";t_2 += "\n\t\t<div class=\"pdfNav ";
t_2 += runtime.suppressValue((!l_prefix?"":"level-indent"), env.opts.autoescape);
t_2 += "\">\n\t\t";
frame = frame.push();
var t_5 = l_main;
if(t_5) {var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("item", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n\t\t\t<div data-tag=\"";
t_2 += runtime.suppressValue(l_prefix + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
t_2 += "\" draggable=\"true\" class=\"item pdfNav\"><span class=\"fal fa-circle\"/> <a href=\"#\">";
t_2 += runtime.suppressValue(env.getFilter("replace").call(context, runtime.memberLookup((t_6),"title"),"&#0;",""), env.opts.autoescape);
t_2 += "</a></div>\n\n\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_6),"items")),"length") > 0) {
t_2 += "\n\t\t\t\t";
t_2 += runtime.suppressValue((lineno = 7, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "render"), "render", context, [runtime.memberLookup((t_6),"items"),l_prefix + runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") + "."])), env.opts.autoescape);
t_2 += "\n\t\t\t";
;
}
t_2 += "\n\t\t";
;
}
}
frame = frame.pop();
t_2 += "\n\t\t</div>\n\t";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("render");
context.setVariable("render", macro_t_1);
output += "\n\n\t<div class=\"title\">Table of Contents</div>\n\t<div class=\"content\">\n\t\t";
output += runtime.suppressValue((lineno = 15, colno = 9, runtime.callWrap(macro_t_1, "render", context, [runtime.contextOrFrameLookup(context, frame, "pages"),""])), env.opts.autoescape);
output += "\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var body_oldRoot = body_obj.root;
body_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	body_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var page_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\tPage ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "page"), env.opts.autoescape);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var page_oldRoot = page_obj.root;
page_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	page_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"regions": new nunjucks.Template( {obj: regions_obj, type: "code"}, env),	"pages": new nunjucks.Template( {obj: pages_obj, type: "code"}, env),	"body": new nunjucks.Template( {obj: body_obj, type: "code"}, env),	"page": new nunjucks.Template( {obj: page_obj, type: "code"}, env),};
