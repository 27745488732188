var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\"> <span class='fal fa-edit'/> Editing Metadata </div>\n<div class=\"modal-body\">\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tISBN\n\t\t\t\t<input type=\"text\" name=\"isbn\" placeholder=\"ISBN\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "isbn")), env.opts.autoescape);
output += "\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tTitle\n\t\t\t\t<input type=\"text\" name=\"title\" placeholder=\"Title\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
output += "\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tPublisher\n\t\t\t\t<select name=\"publisher\" data-placeholder=\"Publisher\">\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tLanguage\n\t\t\t\t<select name=\"language\" data-placeholder=\"Book language\">\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tSubject\n\t\t\t\t<select name=\"subject\" data-placeholder=\"Book subject\">\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tYear of study\n\t\t\t\t<select name=\"grade\" data-placeholder=\"Book year\" multiple>\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tTarget markets\n\t\t\t\t<select name=\"markets\" data-placeholder=\"Book markets\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tCurricula\n\t\t\t\t<select name=\"curriculum\" data-placeholder=\"Book curriculum\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<div class=\"row collapse\">\n\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tRegions\n\t\t\t\t\t\t<select name=\"regions\" data-placeholder=\"Book regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || (runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL"))?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<div class=\"large-11 large-offset-1\">\n\t\t\t\t\t\t<label id=\"regionExceptions\">\n\t\t\t\t\t\t\tExcept\n\t\t\t\t\t\t\t<select name=\"regionExceptions\" data-placeholder=\"Book regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allRegions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL")?"checked":""), env.opts.autoescape);
output += "/> All regions</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<div class=\"row\">\n\t\t\t\t<!--<div class=\"large-1 columns aed hide priceBlock\">\n\t\t\t\t\t<label>AED\n\t\t\t\t\t\t<input type=\"number\" name=\"aed\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aed") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aed") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aed"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>-->\n\t\t\t\t<div class=\"large-2 columns aud hide priceBlock\">\n\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aud") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aud") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aud"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns eur hide priceBlock\">\n\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"eur") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"eur") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"eur"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns gbp hide priceBlock\">\n\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"gbp") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"gbp") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"gbp"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<!--<div class=\"large-1 columns nad hide priceBlock\">\n\t\t\t\t\t<label>NAD\n\t\t\t\t\t\t<input type=\"number\" name=\"nad\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"nad") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"nad") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"nad"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>-->\n\t\t\t\t<div class=\"large-2 columns usd hide priceBlock\">\n\t\t\t\t\t<label>USD\n\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"usd") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"usd") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"usd"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns zar hide end priceBlock\">\n\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"zar") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"zar") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"zar"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tContent Rating\n\t\t\t\t<select name=\"contentRating\" data-placeholder=\"Content Rating\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tPrint ISBN\n\t\t\t\t\t\t\t<input type=\"text\" name=\"printIsbn\" placeholder=\"Print ISBN\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "printIsbn")), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<input type=\"checkbox\" name=\"printIsbnCheck\"> No Print ISBN\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tEdition\n\t\t\t\t<input type=\"text\" name=\"edition\" placeholder=\"Edition\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "edition")), env.opts.autoescape);
output += "\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tPublication date\n\t\t\t\t<input type=\"text\" name=\"pubDate\" placeholder=\"Click to choose date\" readonly/>\n\t\t\t\t<div class=\"input-overlay hide\" title=\"Clear field\"><span class=\"fal fa-times\"/></div>\n\t\t\t</label>\n\t\t</div>\n\n\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"books")),"changeTags")))) {
output += "\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tTags (optional)\n\t\t\t\t\t<select name=\"tags\" data-placeholder=\"Tags\" multiple></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t";
;
}
output += "\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tContributors (one per line)\n\t\t\t\t<textarea name=\"contributors\" placeholder=\"Contributors\" rows=\"8\">";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "contributors")?"":env.getFilter("join").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "contributors")),"\n")), env.opts.autoescape);
output += "</textarea>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>Keywords (one per line)\n\t\t\t\t<textarea name=\"keywords\" placeholder=\"Book keywords\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += " rows=\"8\">";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "keywords")?"":(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "keywords"),"\n")))), env.opts.autoescape);
output += "</textarea>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tAbstract\n\t\t\t\t<textarea name=\"abstract\" placeholder=\"Abstract\" rows=\"8\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "abstract")), env.opts.autoescape);
output += "</textarea>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n</div>\n<div class=\"modal-footer left\">\n\t<button class=\"button small save success\">Update</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
