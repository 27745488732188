var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var singleBase_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"selectra-single\">\n\t\t<span class=\"head\">\n\t\t\t<span class=\"head-image fal\"/>\n\t\t</span>\n\t\t<span class=\"elements\">\n\t\t\t<span class=\"placeholder\">";
output += runtime.suppressValue((lineno = 6, colno = 41, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "placeholder"), "placeholder", context, [])), env.opts.autoescape);
output += "</span>\n\t\t\t<span class=\"text hide\"></span>\n\t\t</span>\n\t\t<span class=\"tail\">\n\t\t\t";
if((lineno = 10, colno = 21, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "singleDeselect"), "singleDeselect", context, []))) {
output += "\n\t\t\t\t<span class=\"global-close hide fal fa-times-circle\"/>\n\t\t\t";
;
}
output += "\n\t\t\t<span class=\"fal fa-chevron-down ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "disabled")?"hide":""), env.opts.autoescape);
output += "\"/>\n\t\t</span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var singleBase_oldRoot = singleBase_obj.root;
singleBase_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	singleBase_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var multiBase_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"selectra-multi\">\n\t\t<!--<div class=\"spinner hide\"><span class=\"spinner-icon spinner-small\"/> Fetching...</div>-->\n\t\t<div class=\"placeholder hide\">";
output += runtime.suppressValue((lineno = 3, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "placeholder"), "placeholder", context, [])), env.opts.autoescape);
output += "</div>\n\t\t<div class=\"items\"></div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var multiBase_oldRoot = multiBase_obj.root;
multiBase_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	multiBase_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var multiItem_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"closer fal fa-times-circle ";
output += runtime.suppressValue(((lineno = 1, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "deselectable"), "deselectable", context, []))?"":"hide"), env.opts.autoescape);
output += "\"></div>\n\t<div class=\"text\" title=\"";
output += runtime.suppressValue((lineno = 2, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "value"), "value", context, [])), env.opts.autoescape);
output += "\">\n\t";
if((lineno = 3, colno = 10, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icons"), "icons", context, [])) && runtime.contextOrFrameLookup(context, frame, "icon")) {
output += "\n\t\t<span class=\"fal ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
output += "\"/>\n\t";
;
}
output += "\n\t";
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 6, colno = 7, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "value"), "value", context, []))), env.opts.autoescape);
output += "</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var multiItem_oldRoot = multiItem_obj.root;
multiItem_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	multiItem_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var dropdownHost_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"container\">\n\t\t<div class=\"chosen hide\"></div>\n\t\t<div class=\"search hide\">\n\t\t\t<!-- \"zax\" is not a valid input type. This ensures we do not inherit the aggressive styles of Foundation. -->\n\t\t\t<input type=\"zax\"><div class=\"search-icon fal fa-search\"/></input>\n\t\t\t<div class=\"spinner hide\"><span class=\"spinner-icon spinner-small\"/> Fetching...</div>\n\t\t\t<div class=\"clearer hide\"><span class=\"fal fa-times\" title=\"Clear search\"/></div>\n\t\t</div>\n\t\t<div class=\"max-item-limit hide\"><span class=\"fal fa-exclamation-circle\"/> There are more items, but they cannot be shown automatically. Please search instead.</div>\n\t\t<div class=\"items\">\n\t\t\t<div class=\"selections hide\">\n\t\t\t\t<div class=\"remover\">Clear selected items</div>\n\t\t\t</div>\n\t\t\t<div class=\"dynamic-add hide\"></div>\n\t\t</div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var dropdownHost_oldRoot = dropdownHost_obj.root;
dropdownHost_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	dropdownHost_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var dropdownCategory_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<span class=\"cat-name\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "key")), env.opts.autoescape);
output += "<span>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var dropdownCategory_oldRoot = dropdownCategory_obj.root;
dropdownCategory_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	dropdownCategory_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var dropdownItem_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t";
if((lineno = 1, colno = 15, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "checkboxes"), "checkboxes", context, []))) {
output += "\n\t\t<input type=\"checkbox\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "selected")?"checked":""), env.opts.autoescape);
output += "></input>\n\t";
;
}
output += "\n\n\t";
if((lineno = 5, colno = 10, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "icons"), "icons", context, []))) {
output += "\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
output += "\n\t\t\t<span class=\"fal ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
output += "\"/>\n\t\t";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "image")) {
output += "\n\t\t\t<span class=\"image\"/>\n\t\t";
;
}
else {
output += "\n\t\t\t<span class=\"fa-filler\"/>\n\t\t";
;
}
;
}
output += "\n\t";
;
}
output += "\n\n\t<span class=\"textual\">\n\t\t<div class=\"value\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, (lineno = 16, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "value"), "value", context, []))), env.opts.autoescape);
output += "</div>\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "desc")) {
output += "\n\t\t\t<div class=\"description\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "desc")), env.opts.autoescape);
output += "</div>\n\t\t";
;
}
output += "\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var dropdownItem_oldRoot = dropdownItem_obj.root;
dropdownItem_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	dropdownItem_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var expanded_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var expanded_oldRoot = expanded_obj.root;
expanded_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	expanded_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"singleBase": new nunjucks.Template( {obj: singleBase_obj, type: "code"}, env),	"multiBase": new nunjucks.Template( {obj: multiBase_obj, type: "code"}, env),	"multiItem": new nunjucks.Template( {obj: multiItem_obj, type: "code"}, env),	"dropdownHost": new nunjucks.Template( {obj: dropdownHost_obj, type: "code"}, env),	"dropdownCategory": new nunjucks.Template( {obj: dropdownCategory_obj, type: "code"}, env),	"dropdownItem": new nunjucks.Template( {obj: dropdownItem_obj, type: "code"}, env),	"expanded": new nunjucks.Template( {obj: expanded_obj, type: "code"}, env),};
