var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"banner\">\n\t<div class=\"level1\"><span>Educational Content Management</span></div>\n\t<div class=\"level2\"><span>Simplified │ <span class=\"yellow\">Efficient</span> │ Enhanced</span></div>\n\t<div class=\"level3\"><span>ITSI Admin is an online tool that assists educational institutions, publishers and parents with ordering and managing e‑books. It allows publishers and authors to upload, validate and encrypt e‑books before making them available for online purchase. E‑books can be managed, previewed and edited with ease. Educational institutions are notified of any updates to ensure that they always use the latest version of the e-book.</span></div>\n</div>\n\n";
if(!runtime.contextOrFrameLookup(context, frame, "user")) {
output += "\n<div class=\"blocks\">\n\t<div class=\"blk\">\n\t\t<div class=\"title\"><span class=\"fal fa-graduation-cap\"/> Schools</div>\n\n\t\t<p>Sign up as a school to indicate your interest in the Personalised Education Framework. Our sales team will contact you to take the process forward.</p>\n\n\t\t<button class=\"success schoolSignup button large\">Sign up as a new school</button>\n\n\t\t<button class=\"secondary small school-info button\"><span class=\"fal fa-question-circle\"/> More info</button>\n\t</div>\n\n\t<div class=\"divider\"></div>\n\n\t<div class=\"blk\">\n\t\t<div class=\"title\"><span class=\"fal fa-cubes\"/> Publishers</div>\n\n\t\t<p>Sign up as a publisher to sell your e-books and curated digital content on our online store, track your sales, and set up school discounts.</p>\n\n\t\t<button class=\"success pubSignup button large\">Sign up as a new publisher</button>\n\n\t\t<button class=\"secondary small pub-info button\"><span class=\"fal fa-question-circle\"/> More info</button>\n\t</div>\n\n\t<div class=\"divider\"></div>\n\n\t<div class=\"blk signup\">\n\t\t<div class=\"title\"><span class=\"fal fa-info-circle\"/> Already signed up?</div>\n\n\t\t\t<form>\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "oauth")),"itsi")) {
output += "\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Email or username:\n\t\t\t\t\t\t\t\t<input type=\"text\" role=\"username\" name=\"username\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Password:\n\t\t\t\t\t\t\t\t<input type=\"password\" role=\"password\" nane=\"password\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<input type=\"submit\" class=\"button\" value=\"Log In\"/>\n\t\t\t\t\t\t\t<span class=\"spinner-icon hide\"></span>\n\t\t\t\t\t\t\t<span class=\"info-text\"></span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "oauth")) {
output += "\n\t\t\t\t\t\t<div class=\"large-12 columns oauth\">\n\t\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "oauth") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "oauth")),"itsi")) {
output += "\n\t\t\t\t\t\t\t<span class=\"or\">or</span>\n\t\t\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/google\" class=\"google-btn oauth-btn\">\n\t\t\t\t\t\t\t\t<button class=\"loginBtn loginBtn--google\">\n\t\t\t\t\t\t\t\t  <span class=\"fab fa-google\"/>  Log in with Google\n\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t</a>\n\n\t\t\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/microsoft\" class=\"microsoft-btn oauth-btn\">\n\t\t\t\t\t\t\t\t<button class=\"loginBtn loginBtn--microsoft\">\n\t\t\t\t\t\t\t\t  <span class=\"fab fa-microsoft\"/> Log in with Microsoft\n\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t</a>\n\n\t\t\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/facebook\" class=\"facebook-btn oauth-btn\">\n\t\t\t\t\t\t\t\t<button class=\"loginBtn loginBtn--facebook\">\n\t\t\t\t\t\t\t\t  <span class=\"fab fa-facebook\"/> Log in with Facebook\n\t\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<a class=\"forgot-password\" href=\"#\"><span class=\"fal fa-question-circle\"/> Forgot your password?</a>\n\t\t\t\t\t\t<a class=\"password-token\" href=\"#\"><span class=\"fal fa-ticket\"/> Enter a password reset token</a>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</form>\n\t</div>\n</div>\n";
;
}
else {
output += "\n<h3 class=\"hide\" style=\"margin: 0 auto; text-align: center; margin-top: 20px; width: 100%;\">You are logged in! We don't have anything special to show you here yet, so here's a cat &#x1f431;</h3>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
