module.exports=[
	{ "name": "AQA", "value": 1, "regions": ["GB"] },
	{ "name": "CAPS", "value": 2, "regions": ["ZA"] },
	{ "name": "CIE", "value": 3, "regions": ["ALL"] },
	{ "name": "IEB", "value": 4, "regions": ["ZA"] },
	{ "name": "OCR", "value": 5, "regions": ["GB"] },
	{ "name": "Edexcel", "value": 6, "regions": ["GB"] },
	{ "name": "WJEC", "value": 7, "regions": ["GB"] },
	{ "name": "CCEA", "value": 8, "regions": ["GB"] },
	{ "name": "VCAA", "value": 9, "regions": ["AU"] },
	{ "name": "NSW (BOSTES)", "value": 10, "regions": ["AU"] },
	{ "name": "SACE", "value": 11, "regions": ["AU"] },
	{ "name": "NCEA", "value": 12, "regions": ["NZ"] },
	{ "name": "FET", "value": 13, "regions": ["ZA"] },
	{ "name": "HET/Academic", "value": 14, "regions": ["ALL"] },
	{ "name": "Corporate", "value": 15, "regions": ["ALL"] },
	{ "name": "A-level", "value": 16, "regions": ["GB"] },
	{ "name": "IB", "value": 17, "regions": ["GB"] },
	{ "name": "IGCSE", "value": 18, "regions": ["GB"] }
]