var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
if(runtime.contextOrFrameLookup(context, frame, "parsed")) {
output += "\n<div class=\"header\">\n\t<div class=\"name\">\n\t\t<div class=\"filename\">&nbsp;&nbsp;";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "filename"), env.opts.autoescape);
output += "</div>\n\t\t<div>\n\t\t\t<div class=\"blob mainBlob reviewing ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "reviewed")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-list\"/> Reviewing</div>\n\t\t\t<div class=\"blob mainBlob uploading ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "uploading")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-cloud-upload\"/> Uploading...</div>\n\t\t\t<div class=\"blob mainBlob queued ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") && !runtime.contextOrFrameLookup(context, frame, "uploading")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-clock\"/> Queued for upload</div>\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "warnings") && runtime.contextOrFrameLookup(context, frame, "warnings") > 0) {
output += "\n\t\t\t\t<div class=\"blob warnings\"><span class=\"fal fa-exclamation-triangle\"/> ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "warnings"), env.opts.autoescape);
output += " warning";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "warnings") != 1?"s":""), env.opts.autoescape);
output += "</div>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t</div>\n\n\t<div class=\"actions\">\n\t\t<label><input type=\"checkbox\" name=\"apply\"/>&nbsp;<strong>Apply this metadata to active books</strong>&nbsp;&nbsp;</label>\n\t\t<div class=\"rubutton upload ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"hide":""), env.opts.autoescape);
output += " blue\">Upload Metadata</div>\n\t\t<div class=\"rubutton dequeue ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"":"hide"), env.opts.autoescape);
output += " black\">Unqueue Metadata</div>\n\t\t<div class=\"rubutton skip red\">Skip Metadata</div>\n\t</div>\n</div>\n\n<div class=\"progress\"></div>\n\n<div class=\"large-12 columns\">\n\t<label>\n\t\tTags\n\t\t<select name=\"tags\" data-placeholder=\"Tags\" multiple class=\"parent\"></select>\n\t</label>\n\t<br />\n</div>\n\n<div class=\"metadata-grid\">\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n</div>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "parsing")) {
output += "\n\t<div class=\"centered\">\n\t\t<p><span class=\"parsing\"/> <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "file")),"name"), env.opts.autoescape);
output += "</strong></p>\n\t\t<p>This metadata file is being loaded. Please wait...</p>\n\t</div>\n";
;
}
else {
output += "\n\t<div class=\"centered\">\n\t\t<p><span class=\"fal fa-circle\"/> <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "file")),"name"), env.opts.autoescape);
output += "</strong></p>\n\t\t<p>This metadata file is queued for loading. Please wait...</p>\n\t</div>\n";
;
}
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
