var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\"> <span class='fal fa-edit'/> Editing License </div>\n<div class=\"modal-body\">\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tProduct\n\t\t\t\t<input type=\"text\" name=\"identifier\" placeholder=\"Product\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tName\n\t\t\t\t<input type=\"text\" name=\"name\" placeholder=\"Name\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tLicense ISBN (optional)\n\t\t\t\t<input type=\"text\" name=\"isbn\" placeholder=\"License ISBN\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "isbn")), env.opts.autoescape);
output += "\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>License Type\n\t\t\t\t<select name=\"licenseType\">\n\t\t\t\t\t<option value=\"1\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "license_type") == "1"?"selected":""), env.opts.autoescape);
output += ">Store only</option>\n\t\t\t\t\t<option value=\"2\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "license_type") == "2"?"selected":""), env.opts.autoescape);
output += ">e-Library only</option>\n\t\t\t\t\t<option value=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "license_type") == "0"?"selected":""), env.opts.autoescape);
output += ">Store and e-Library</option>\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\tDuration<br/>\n\t\t\t<label><input type=\"checkbox\" name=\"perpetual\"/> Make this license last forever (perpetual license)</label>\n\n\t\t\t<label><div class=\"";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "explicitEnd") || runtime.contextOrFrameLookup(context, frame, "perpetual")?"hide":""), env.opts.autoescape);
output += "\">Explicit end date: <strong>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "end_date"), env.opts.autoescape);
output += "</strong></div></label>\n\n\t\t\t<div class=\"row fixed_periods\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>License lasts for\n\t\t\t\t\t\t<select name=\"duration\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "perpetual")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t\t<option value=\"P1Y\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "duration") == "1"?"selected":""), env.opts.autoescape);
output += ">1 year</option>\n\t\t\t\t\t\t\t<option value=\"P2Y\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "duration") == "2"?"selected":""), env.opts.autoescape);
output += ">2 years</option>\n\t\t\t\t\t\t\t<option value=\"P3Y\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "duration") == "3"?"selected":""), env.opts.autoescape);
output += ">3 years</option>\n\t\t\t\t\t\t\t<option value=\"P5Y\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "duration") == "5"?"selected":""), env.opts.autoescape);
output += ">5 years</option>\n\t\t\t\t\t\t\t<option value=\"P100Y\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "duration") == "100"?"selected":""), env.opts.autoescape);
output += ">100 years</option>\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tTransfer Limit\n\t\t\t\t<input type=\"number\" name=\"transferLimit\" placeholder=\"Transfer Limit\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "transferLimit"), env.opts.autoescape);
output += "\" min=\"0\" max=\"99999999\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<div class=\"row collapse\">\n\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tRegions\n\t\t\t\t\t\t<select name=\"regions\" data-placeholder=\"License regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || (runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL"))?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<div class=\"large-11 large-offset-1\">\n\t\t\t\t\t\t<label id=\"regionExceptions\">\n\t\t\t\t\t\t\tExcept\n\t\t\t\t\t\t\t<select name=\"regionExceptions\" data-placeholder=\"License regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allRegions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL")?"checked":""), env.opts.autoescape);
output += "/> All regions</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<span class=\"price-tooltip\">(Specify at least one price. Any left empty will be automatically converted every day.)</span>\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-2 columns aud priceBlock\">\n\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aud") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aud") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"aud"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns eur priceBlock\">\n\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"eur") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"eur") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"eur"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns gbp priceBlock\">\n\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"gbp") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"gbp") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"gbp"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns usd priceBlock\">\n\t\t\t\t\t<label>USD\n\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"usd") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"usd") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"usd"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns zar end priceBlock\">\n\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"zar") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"zar") == 0?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "prices")),"zar"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns schools\">\n\t\t\t<div class=\"row collapse\">\n\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tLimit to schools\n\t\t\t\t\t\t<select name=\"schools\" multiple ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "schools") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "schools"),0))?"disabled":""), env.opts.autoescape);
output += "></select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allSchools\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "schools") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "schools"),0)?"checked":""), env.opts.autoescape);
output += "/> All schools</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns school-groups\">\n\t\t\t<div class=\"row collapse\">\n\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tLimit to school groups\n\t\t\t\t\t\t<select name=\"schoolGroups\" multiple ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "school_groups") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "school_groups"),0))?"disabled":""), env.opts.autoescape);
output += "></select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allSchoolGroups\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "school_groups") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "school_groups"),0)?"checked":""), env.opts.autoescape);
output += "/> All school groups</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\n</div>\n<div class=\"modal-footer left\">\n\t<button class=\"button small save success\">Update</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
