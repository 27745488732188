var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\">Changelog for ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "model")),"fullname"), env.opts.autoescape);
output += "</div>\n<div class=\"modal-body\">\n\t";
if(!runtime.contextOrFrameLookup(context, frame, "items")) {
output += "\n\t\t<div style=\"margin: 0 auto; width: 100%; text-align: center;\">\n\t\t\t<span class=\"spinner-icon spinner-small\"/> Loading...\n\t\t</div>\n\t";
;
}
else {
output += "\n\t\t<label class=\"search-box\">\n\t\t\t<div class=\"fal fa-search\"></div>\n\t\t\t<input type=\"text\" placeholder=\"Search...\" required name=\"searchText\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "searchText"), env.opts.autoescape);
output += "\"/>\n\t\t\t<div class=\"input-overlay fal fa-times\" title=\"Clear field\"/>\n\t\t</label>\n\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "items")),"length") == 0) {
output += "\n\t\t\t<div>There is nothing to show</div>\n\t\t";
;
}
else {
output += "\n\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "items");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("row", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t<div class=\"item\" data-full=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"datum"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<div class=\"when\">";
output += runtime.suppressValue((lineno = 18, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "fromNow"), "fromNow", context, [runtime.memberLookup((t_4),"datum")])), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t<div class=\"who\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, (runtime.memberLookup((t_4),"user") || "SYSTEM")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((!runtime.memberLookup((t_4),"impersonator")?"":(" (impersonated by " + (env.getFilter("escape").call(context, runtime.memberLookup((t_4),"impersonator"))) + ")")), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t<div class=\"title\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((t_4),"reason")), env.opts.autoescape);
output += "</div>\n\t\t\t\t\t";
if(runtime.memberLookup((t_4),"changes")) {
output += "\n\t\t\t\t\t\t<div class=\"detail\">\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"changes");
if(t_7) {var t_5;
if(runtime.isArray(t_7)) {
var t_6 = t_7.length;
for(t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5][0]
frame.set("key", t_7[t_5][0]);
var t_9 = t_7[t_5][1]
frame.set("val", t_7[t_5][1]);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t\t<strong>";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "</strong>: <code>";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dumpIfObject").call(context, runtime.memberLookup((t_9),"old"))), env.opts.autoescape);
output += "</code> ➔ <code>";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dumpIfObject").call(context, runtime.memberLookup((t_9),"new"))), env.opts.autoescape);
output += "</code><br/>\n\t\t\t\t\t\t\t";
;
}
} else {
t_5 = -1;
var t_6 = runtime.keys(t_7).length;
for(var t_10 in t_7) {
t_5++;
var t_11 = t_7[t_10];
frame.set("key", t_10);
frame.set("val", t_11);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t\t<strong>";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "</strong>: <code>";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dumpIfObject").call(context, runtime.memberLookup((t_11),"old"))), env.opts.autoescape);
output += "</code> ➔ <code>";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dumpIfObject").call(context, runtime.memberLookup((t_11),"new"))), env.opts.autoescape);
output += "</code><br/>\n\t\t\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n\t<div class=\"modal-error hide\"></div>\n</div>\n<div class=\"modal-footer\">\n\t<button class=\"button success ok\">OK</button>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
