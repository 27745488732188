var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 93, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-book'/> Create New Hardcopy Book"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing Hardcopy Book: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<input id=\"discontinued\" name=\"discontinued\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discontinued")?"checked":""), env.opts.autoescape);
output += "  ";
output += runtime.suppressValue(((lineno = 5, colno = 106, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"disabled":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"discontinued\">Discontinued</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPublisher *\n\t\t\t\t\t<select name=\"publisher\"></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tName *\n\t\t\t\t\t<input type=\"text\" name=\"name\" placeholder=\"Name\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tISBN *\n\t\t\t\t\t<input type=\"text\" name=\"identifier\" placeholder=\"ISBN\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "identifier"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\teISBN\n\t\t\t\t\t<input type=\"text\" name=\"eisbn\" placeholder=\"eISBN\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "eisbn"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tLanguage *\n\t\t\t\t\t<select name=\"language\" data-placeholder=\"Language\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tGrades *\n\t\t\t\t\t<select name=\"grades\" data-placeholder=\"Book year\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSubject *\n\t\t\t\t\t<select name=\"subject\" data-placeholder=\"Subject\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSuppier\n\t\t\t\t\t<input type=\"text\" name=\"supplier\" placeholder=\"Supplier\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "supplier_name"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<hr />\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tLead Time (days) *\n\t\t\t\t\t<input type=\"number\" name=\"leadTime\" placeholder=\"Days\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "lead_time"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<span class=\"price-tooltip\">(Specify at least one price. Any left empty will be automatically converted every day.)</span>\n\t\t\t\t<div class=\"row priceRow\">\n\t\t\t\t\t<div class=\"large-2 columns aud\">\n\t\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "aud") || runtime.contextOrFrameLookup(context, frame, "aud") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "aud")):""), env.opts.autoescape);
output += "\" max=\"100000\" min=\"0\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns eur\">\n\t\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "eur") || runtime.contextOrFrameLookup(context, frame, "eur") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "eur")):""), env.opts.autoescape);
output += "\" max=\"100000\" min=\"0\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns gbp\">\n\t\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "gbp") || runtime.contextOrFrameLookup(context, frame, "gbp") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "gbp")):""), env.opts.autoescape);
output += "\" max=\"100000\" min=\"0\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns usd\">\n\t\t\t\t\t\t<label>USD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "usd") || runtime.contextOrFrameLookup(context, frame, "usd") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "usd")):""), env.opts.autoescape);
output += "\" max=\"100000\" min=\"0\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns zar end\">\n\t\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "zar") || runtime.contextOrFrameLookup(context, frame, "zar") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "zar")):""), env.opts.autoescape);
output += "\" max=\"100000\" min=\"0\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t";
if((lineno = 112, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += " <label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
output += "\n\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "automatic")) {
output += "<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 113, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;";
;
}
output += "\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching license data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
