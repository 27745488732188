var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"><span class=\"fal fa-plus\"/> Creating New Book Choices</div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"action\">\n\t\t\t<span>1. Choose the school(s) you want to add choices for:</span>\n\t\t\t<select name=\"schools\" multiple data-placeholder=\"Schools\">\n\t\t\t</select>\n\t\t</div>\n\n\t\t<div class=\"action\">\n\t\t\t<span>2. Provide the book IDs you want to add as choices for these schools (one per line, or comma delimited):</span>\n\t\t\t<textarea name=\"books\" rows=\"10\"></textarea>\n\t\t</div>\n\n\t\t<div class=\"action years\">\n\t\t\t<span>3. Choose the years that these choices will be attached to:</span>\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t\t<label><input type=\"checkbox\" name=\"2020\" value=\"2020\"> 2020</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t\t<label><input type=\"checkbox\" name=\"2021\" value=\"2021\"> 2021</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t\t<label><input type=\"checkbox\" name=\"2022\" value=\"2022\"> 2022</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t</div>\n\n\t\t<div class=\"action\">\n\t\t\t<span>4. Review the books that have been found for the above IDs, and make any changes needed before saving:</span>\n\t\t\t<div class=\"results\">\n\t\t\t</div>\n\t\t</div>\n\n\n\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">Save</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching school data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
