var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["item"], 
[], 
function (l_item, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("item", l_item);
var t_2 = "";t_2 += "\n\t";
if(runtime.memberLookup((runtime.memberLookup((l_item),"links")),"length") > 0) {
t_2 += "\n\t\t<li class=\"";
if(runtime.memberLookup((l_item),"active") == true) {
t_2 += "active";
;
}
t_2 += "\"><a href=\"";
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "link")),"newWindow")?"":runtime.contextOrFrameLookup(context, frame, "urlRoot")), env.opts.autoescape);
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"url"), env.opts.autoescape);
t_2 += "\" data-replace=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"replace"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "link")),"newWindow")) {
t_2 += "data-new=\"true\"";
;
}
t_2 += ">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"name"), env.opts.autoescape);
t_2 += "</a>\n\t\t\t<ul class=\"menu vertical\">\n\t\t\t\t";
frame = frame.push();
var t_5 = runtime.memberLookup((l_item),"links");
if(t_5) {var t_4 = t_5.length;
for(var t_3=0; t_3 < t_5.length; t_3++) {
var t_6 = t_5[t_3];
frame.set("link", t_6);
frame.set("loop.index", t_3 + 1);
frame.set("loop.index0", t_3);
frame.set("loop.revindex", t_4 - t_3);
frame.set("loop.revindex0", t_4 - t_3 - 1);
frame.set("loop.first", t_3 === 0);
frame.set("loop.last", t_3 === t_4 - 1);
frame.set("loop.length", t_4);
t_2 += "\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_6),"links")),"length") > 0) {
t_2 += "\n\t\t\t\t\t\t";
t_2 += runtime.suppressValue((lineno = 6, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "subset"), "subset", context, [t_6])), env.opts.autoescape);
t_2 += "\n\t\t\t\t\t";
;
}
else {
t_2 += "\n\t\t\t\t\t\t";
if(runtime.memberLookup((t_6),"divider")) {
t_2 += "\n\t\t\t\t\t\t\t<li class=\"divider\"><hr/></li>\n\t\t\t\t\t\t";
;
}
else {
t_2 += "\n\t\t\t\t\t\t\t<li ";
if(runtime.memberLookup((t_6),"active") == true) {
t_2 += "class=\"active\"";
;
}
t_2 += "><a href=\"";
t_2 += runtime.suppressValue((runtime.memberLookup((t_6),"newWindow")?"":runtime.contextOrFrameLookup(context, frame, "urlRoot")), env.opts.autoescape);
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"url"), env.opts.autoescape);
t_2 += "\" data-replace=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"replace"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((t_6),"newWindow")) {
t_2 += "data-new=\"true\"";
;
}
t_2 += ">";
t_2 += runtime.suppressValue(runtime.memberLookup((t_6),"name"), env.opts.autoescape);
t_2 += "</a></li>\n\t\t\t\t\t\t";
;
}
t_2 += "\n\t\t\t\t\t";
;
}
t_2 += "\n\t\t\t\t";
;
}
}
frame = frame.pop();
t_2 += "\n\t\t\t</ul>\n\t";
;
}
else {
t_2 += "\n\t\t<li ";
if(runtime.memberLookup((l_item),"active") == true) {
t_2 += "class=\"active\"";
;
}
t_2 += "><a href=\"";
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "link")),"newWindow")?"":runtime.contextOrFrameLookup(context, frame, "urlRoot")), env.opts.autoescape);
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"url"), env.opts.autoescape);
t_2 += "\" data-replace=\"";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"replace"), env.opts.autoescape);
t_2 += "\" ";
if(runtime.memberLookup((l_item),"newWindow")) {
t_2 += "data-new=\"true\"";
;
}
t_2 += ">";
t_2 += runtime.suppressValue(runtime.memberLookup((l_item),"name"), env.opts.autoescape);
t_2 += "</a>\n\t";
;
}
t_2 += "\n\t</li>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("subset");
context.setVariable("subset", macro_t_1);
output += "\n\n";
var macro_t_7 = runtime.makeMacro(
["main"], 
[], 
function (l_main, kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
frame.set("main", l_main);
var t_8 = "";t_8 += "\n\t<ul class=\"dropdown menu\" data-dropdown-menu id=\"menu-top-el\">\n\t\t";
frame = frame.push();
var t_11 = l_main;
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("item", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
t_8 += "\n\t\t\t";
t_8 += runtime.suppressValue((lineno = 25, colno = 10, runtime.callWrap(macro_t_1, "subset", context, [t_12])), env.opts.autoescape);
t_8 += "\n\t\t";
;
}
}
frame = frame.pop();
t_8 += "\n\t</ul>\n\n\t<!--<ul class=\"right\" style=\"position: relative\" id=\"notifications\">\n\n\t</ul>-->\n\n";
;
frame = frame.pop();
return new runtime.SafeString(t_8);
});
context.addExport("render");
context.setVariable("render", macro_t_7);
output += "\n\n<div class=\"top-bar-right\">\n\t";
output += runtime.suppressValue((lineno = 36, colno = 8, runtime.callWrap(macro_t_7, "render", context, [runtime.contextOrFrameLookup(context, frame, "navigation")])), env.opts.autoescape);
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
