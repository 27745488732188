var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 94, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-user-plus'/> Create New Publisher"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing Publisher: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "fullname"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<h4>Publisher Info</h4>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Full Name of Publisher *\n\t\t\t\t\t<input type=\"text\" name=\"fullname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "fullname")), env.opts.autoescape);
output += "\" placeholder=\"Full Name of Publisher\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<input id=\"isPrivate\" name=\"isPrivate\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "private")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"isPrivate\">Private publisher</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-9 columns\">\n\t\t\t\t<input id=\"hidePerpetual\" name=\"hidePerpetual\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "hide_perpetual")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"hidePerpetual\">Hide perpetual licenses from buyers</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns hide\" id=\"reportPrivateSalesWrapper\">\n\t\t\t\t<input id=\"reportPrivateSales\" name=\"reportPrivateSales\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "report_private_sales")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"reportPrivateSales\">Generate sale reports</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<input id=\"disableIngramOverride\" name=\"disableIngramOverride\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "disable_ingram_override")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"disableIngramOverride\">Disable FTP Importing</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input id=\"replaceDiscontinuedBooks\" name=\"replaceDiscontinuedBooks\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "replace_discontinued_books")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"replaceDiscontinuedBooks\">Replace discontinued books via FTP</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\"></div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<label for=\"arAgreement1\">\n\t\t\t\t\t<input id=\"arAgreement1\" name=\"arAgreement\" ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "ar_agreement")?"checked":""), env.opts.autoescape);
output += " type=\"radio\" value=\"false\" />\n\t\t\t\t\tReseller Agreement\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<label for=\"arAgreement2\">\n\t\t\t\t\t<input id=\"arAgreement2\" name=\"arAgreement\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "ar_agreement")?"checked":""), env.opts.autoescape);
output += " type=\"radio\" value=\"true\" />\n\t\t\t\t\tAgency Agreement\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Physical Address *\n\t\t\t\t\t<input type=\"text\" name=\"physicalAddress\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "physical_address"), env.opts.autoescape);
output += "\" placeholder=\"Physical Address of Publisher\"/>\n\t\t\t\t\t<input type=\"hidden\" value='";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dump").call(context, runtime.contextOrFrameLookup(context, frame, "physical_address_data"))), env.opts.autoescape);
output += "' name=\"physicalAddressData\" />\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Postal Address - <input name=\"copyPhysical\" type=\"checkbox\"/><span> Same as Physical Address</span></label>\n\t\t\t\t<input type=\"text\" name=\"postalAddress\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "postal_address")), env.opts.autoescape);
output += "\" placeholder=\"Postal Address of Publisher\"/>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Website\n\t\t\t\t\t<input type=\"text\" name=\"website\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "website")), env.opts.autoescape);
output += "\" placeholder=\"Website of publisher\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Vat Number (if registered)\n\t\t\t\t\t<input type=\"text\" name=\"vatNumber\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "vat_number")), env.opts.autoescape);
output += "\" placeholder=\"Vat Number\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Company Registration Number\n\t\t\t\t\t<input type=\"text\" name=\"companyReg\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "company_registration")), env.opts.autoescape);
output += "\" placeholder=\"Company Registration Number of publisher\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>ITSI entity with which the Distribution Agreement is signed\n\t\t\t\t\t<select name=\"itsiEntity\">\n\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_3 = (lineno = 87, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "itsiEntities"), "itsiEntities", context, []));
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("entity", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((t_4 == runtime.contextOrFrameLookup(context, frame, "itsi_entity")?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPublisher groups\n\t\t\t\t\t<select name=\"groups\" data-placeholder=\"Publisher groups\" multiple class=\"adder\"></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Parent Publisher\n\t\t\t\t\t<input type=\"text\" name=\"parentPub\" placeholder=\"Parent Publisher\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tLimit Publisher books to regions (leave blank for no limit)\n\t\t\t\t\t<select name=\"regions\" data-placeholder=\"Publisher regions\" multiple class=\"adder\"></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"publishers")),"comments"))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t<label>Comment\n\t\t\t\t\t<textarea name=\"comment\" rows=\"6\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "comment"), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<br />\n\t\t\t\t<h4>Emails</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"majorVersionEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"majorVersionEmail") || (lineno = 126, colno = 82, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>New major version</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<br />\n\t\t\t\t<h4>Billing</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<input id=\"csPlusBilling\" name=\"csPlusBilling\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "cs_plus_billing")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for =\"csPlusBilling\">Core Source Plus billing</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-9 columns\">\n\t\t\t\t<input id=\"elibraryEnabled\" name=\"elibraryEnabled\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "elibrary_enabled")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"elibraryEnabled\">eLibrary sales on Facilitator Console</label>\n\t\t\t</div>\n\n\t\t\t<div id=\"maxCheckoutsWrapper\" class=\"large-12 columns\">\n\t\t\t\t<label>Max Checkouts per Year *\n\t\t\t\t\t<input type=\"number\" name=\"maxElibraryCheckouts\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "max_elibrary_annual_checkouts"), env.opts.autoescape);
output += "\" placeholder=\"Max Checkouts per Year\" min=\"1\" max=\"9999\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Distribution Fee Percentage *\n\t\t\t\t\t<input type=\"number\" name=\"distributionFee\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "distribution_fee_pct")), env.opts.autoescape);
output += "\" placeholder=\"Distribution Fee Percentage\" min=\"0\" max=\"100\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Contract status\n\t\t\t\t\t<select name=\"contractStatus\">\n\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_7 = (lineno = 161, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "contractStatus"), "contractStatus", context, []));
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("status", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\" ";
if(t_8 == runtime.contextOrFrameLookup(context, frame, "contract_status")) {
output += "selected=\"selected\"";
;
}
output += ">";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Target Markets\n\t\t\t\t\t<select name=\"targetMarket\">\n\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_11 = (lineno = 172, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "targetMarkets"), "targetMarkets", context, []));
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("market", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += "\" ";
if(t_12 == runtime.contextOrFrameLookup(context, frame, "target_market")) {
output += "selected=\"selected\"";
;
}
output += ">";
output += runtime.suppressValue(t_12, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Focus\n\t\t\t\t\t<select name=\"focus\">\n\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_15 = (lineno = 183, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "publisherFocus"), "publisherFocus", context, []));
if(t_15) {var t_14 = t_15.length;
for(var t_13=0; t_13 < t_15.length; t_13++) {
var t_16 = t_15[t_13];
frame.set("pubFocus", t_16);
frame.set("loop.index", t_13 + 1);
frame.set("loop.index0", t_13);
frame.set("loop.revindex", t_14 - t_13);
frame.set("loop.revindex0", t_14 - t_13 - 1);
frame.set("loop.first", t_13 === 0);
frame.set("loop.last", t_13 === t_14 - 1);
frame.set("loop.length", t_14);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += "\" ";
if(t_16 == runtime.contextOrFrameLookup(context, frame, "focus")) {
output += "selected=\"selected\"";
;
}
output += ">";
output += runtime.suppressValue(t_16, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Base Currency\n\t\t\t\t\t<select name=\"baseCurrency\">\n\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_19 = (lineno = 194, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "currencies"), "currencies", context, []));
if(t_19) {var t_18 = t_19.length;
for(var t_17=0; t_17 < t_19.length; t_17++) {
var t_20 = t_19[t_17];
frame.set("currency", t_20);
frame.set("loop.index", t_17 + 1);
frame.set("loop.index0", t_17);
frame.set("loop.revindex", t_18 - t_17);
frame.set("loop.revindex0", t_18 - t_17 - 1);
frame.set("loop.first", t_17 === 0);
frame.set("loop.last", t_17 === t_18 - 1);
frame.set("loop.length", t_18);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_20),"abbr"), env.opts.autoescape);
output += "\" ";
if(runtime.contextOrFrameLookup(context, frame, "base_currency") == runtime.memberLookup((t_20),"abbr")) {
output += "selected=\"selected\"";
;
}
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_20),"abbr"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"overrideMinDistributionFee\" ";
if(runtime.contextOrFrameLookup(context, frame, "min_distribution_fee_override")) {
output += " checked=\"checked\"";
;
}
output += " type=\"checkbox\" /><span>&nbsp;Override Default Minimum Distribution Fee</span></label>\n\t\t\t\t<div class=\"row\" id=\"distributionFeeCurrencies\">&nbsp;&nbsp;\n\t\t\t\t\t";
frame = frame.push();
var t_23 = (lineno = 204, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "currencies"), "currencies", context, []));
if(t_23) {var t_22 = t_23.length;
for(var t_21=0; t_21 < t_23.length; t_21++) {
var t_24 = t_23[t_21];
frame.set("currency", t_24);
frame.set("loop.index", t_21 + 1);
frame.set("loop.index0", t_21);
frame.set("loop.revindex", t_22 - t_21);
frame.set("loop.revindex0", t_22 - t_21 - 1);
frame.set("loop.first", t_21 === 0);
frame.set("loop.last", t_21 === t_22 - 1);
frame.set("loop.length", t_22);
output += "\n\t\t\t\t\t\t<div class=\"large-1 columns\">\n\t\t\t\t\t\t\t<label>";
output += runtime.suppressValue(runtime.memberLookup((t_24),"abbr"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"";
output += runtime.suppressValue("minDistribution" + runtime.memberLookup((t_24),"abbr"), env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "min_distribution_fee_override")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "min_distribution_fee_override")),runtime.memberLookup((t_24),"abbr")):runtime.memberLookup((t_24),"minDistributionFee")), env.opts.autoescape);
output += "\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Payment Terms\n\t\t\t\t\t<select name=\"paymentTerms\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_27 = (lineno = 217, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "paymentTerms"), "paymentTerms", context, []));
if(t_27) {var t_26 = t_27.length;
for(var t_25=0; t_25 < t_27.length; t_25++) {
var t_28 = t_27[t_25];
frame.set("term", t_28);
frame.set("loop.index", t_25 + 1);
frame.set("loop.index0", t_25);
frame.set("loop.revindex", t_26 - t_25);
frame.set("loop.revindex0", t_26 - t_25 - 1);
frame.set("loop.first", t_25 === 0);
frame.set("loop.last", t_25 === t_26 - 1);
frame.set("loop.length", t_26);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((t_28 == runtime.contextOrFrameLookup(context, frame, "payment_term")?"selected":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((lineno = 218, colno = 85, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(t_28, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Max Hardcopy Discount *\n\t\t\t\t\t<input type=\"number\" name=\"maxHardcopyDiscount\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "max_hardcopy_discount")), env.opts.autoescape);
output += "\" placeholder=\"Max hardcopy discount\" min=\"0\" max=\"100\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<br />\n\t\t\t\t<h4>Contact Info</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h5>Primary</h5>\n\t\t\t\t<label>Contact Person First Name*\n\t\t\t\t\t<input type=\"text\" name=\"primaryContactPersonFirstname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_contact_person_firstname")), env.opts.autoescape);
output += "\" placeholder=\"Primary Contact Person First Name\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Contact Person Surname*\n\t\t\t\t\t<input type=\"text\" name=\"primaryContactPersonSurname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_contact_person_surname")), env.opts.autoescape);
output += "\" placeholder=\"Primary Contact Person Surname\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Email *\n\t\t\t\t\t<input type=\"text\" name=\"primaryEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_email")), env.opts.autoescape);
output += "\" placeholder=\"Primary Email Address\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Phone Number *\n\t\t\t\t\t<input type=\"text\" name=\"primaryPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Primary Phone Number\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h5>Financial</h5>\n\t\t\t\t<label><input name=\"financialCopyPrimary\" data-group=\"financial\" type=\"checkbox\" /><span>&nbsp;Same as Primary Contact</span></label>\n\t\t\t\t<div id=\"financialContactGroup\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Contact Person\n\t\t\t\t\t\t\t<input type=\"text\" name=\"financialContactPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_contact_person")), env.opts.autoescape);
output += "\" placeholder=\"Financial Contact Person\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Email\n\t\t\t\t\t\t\t<input type=\"text\" name=\"financialEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_email")), env.opts.autoescape);
output += "\" placeholder=\"Financial Email Address\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Phone Number\n\t\t\t\t\t\t\t<input type=\"text\" name=\"financialPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Financial Phone Number\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h5>Technical</h5>\n\t\t\t\t<label><input name=\"technicalCopyPrimary\" data-group=\"technical\" type=\"checkbox\" /><span>&nbsp;Same as Primary Contact</span></label>\n\t\t\t\t<div id=\"technicalContactGroup\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Contact Person\n\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalContactPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_contact_person")), env.opts.autoescape);
output += "\" placeholder=\"Technical Contact Person\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Email\n\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_email")), env.opts.autoescape);
output += "\" placeholder=\"Technical Email Address\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Phone Number\n\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Technical Phone Number\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t</div>\n\t</div>\n\n\t<div class=\"modal-footer left\">\n\t\t";
if((lineno = 312, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += " <label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
output += "\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 313, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching publisher data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
