var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 91, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-user-plus'/> Create New School"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing School: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "fullname"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h4>School Settings</h4>\n\t\t\t</div>\n\n\t\t\t";
if(!(lineno = 8, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += "\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Identifier\n\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "guid")), env.opts.autoescape);
output += "\" readonly/>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"disabled\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "disabled")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tIs this school disabled?</label>\n\t\t\t</div>\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "debugMode")) {
output += "\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"debug\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "debugMode") && (runtime.contextOrFrameLookup(context, frame, "debug") || (lineno = 23, colno = 74, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, [])))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tThis school is a fake school (for testing environment only)</label>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"ownSslCert\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "own_ssl_cert")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tOwn SSL certificate?</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"anywhereOverride\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "anywhere_override")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tSync purchased books to Anywhere (For online schools with no server) <span class=\"schoolHasTokens hide\"><i class=\"fal fa-exclamation-triangle fa-fw\"></i> This school has tokens, changing this will void the tokens.</span></label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"resigned\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "resigned")?"checked disabled":""), env.opts.autoescape);
output += " type=\"checkbox\" ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"resign")))?"disabled":""), env.opts.autoescape);
output += "/>\n\t\t\t\tThis school server is permanently offline</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"resign_date\" ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "resigned") || !(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"resign")))?"disabled":""), env.opts.autoescape);
output += " type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "resign_date")), env.opts.autoescape);
output += "\" placeholder=\"Permanently offline Date\" /></label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"requires_dependant_password\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "requires_dependant_password") || (lineno = 48, colno = 103, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\">\n\t\t\t\tRequires dependant password</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"choose_tranches\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "choose_tranches")?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"trancheBuyToggle")))?"disabled":""), env.opts.autoescape);
output += " type=\"checkbox\">\n\t\t\t\tAllow bulk tranche choosing (choices will be billed instantly)</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"fc\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "fc")?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editFCToggle")))?"disabled":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tThis school synchronises to Facilitator Console (IF YOU ARE NOT DEVOPS DO NOT TICK THIS BOX EVER! YOU WILL BREAK EVERYTHING! DO NOT TICK THIS BOX!).</label>\n\t\t\t</div>\n\n\t\t\t<div id=\"fcSettingsWrapper\" class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"fcProductListEnabled\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "fc_product_list_enabled")?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editFCToggle")))?"disabled":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tAllow product list purchases</label>\n\n\t\t\t\t<label><input name=\"fcElibraryEnabled\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "fc_elibrary_enabled")?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editFCToggle")))?"disabled":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tAllow eLibrary purchases</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h4>School Info</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Full name of School *\n\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "fullname")), env.opts.autoescape);
output += "\" name=\"fullname\" placeholder=\"Full name of school\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>EMIS number *\n\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "identifier")), env.opts.autoescape);
output += "\" name=\"emis\" placeholder=\"EMIS number\"/>\n\t\t\t\t\t<div class=\"input-overlay emis-spinner hide\" title=\"Checking existence...\"><span class=\"spinner-icon spinner-small\"/></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSchool region *\n\t\t\t\t\t<select name=\"region\"></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Physical address *\n\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "physical_address")), env.opts.autoescape);
output += "\" name=\"physicalAddress\" placeholder=\"Physical address of school\"/>\n\t\t\t\t\t<input type=\"hidden\" value='";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dump").call(context, runtime.contextOrFrameLookup(context, frame, "physical_address_data"))), env.opts.autoescape);
output += "' name=\"physicalAddressData\" />\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Postal address - <input name=\"copyPhysical\" type=\"checkbox\"/><span> Same as Physical Address</span></label>\n\t\t\t\t<input type=\"text\" name=\"postalAddress\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "postal_address"))), env.opts.autoescape);
output += "\" placeholder=\"Postal address of school\"/>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Website\n\t\t\t\t\t<input type=\"text\" name=\"website\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "website")), env.opts.autoescape);
output += "\" placeholder=\"Website of school\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>DNS *\n\t\t\t\t\t<input type=\"text\" id=\"schoolDns\" name=\"dns\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "dns")), env.opts.autoescape);
output += "\" placeholder=\"Server DNS\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>DNS Override (applied to schools.json)\n\t\t\t\t\t<input type=\"text\" id=\"dnsOverride\" name=\"dnsOverride\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "dns_override")), env.opts.autoescape);
output += "\" placeholder=\"Server DNS Override\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPrivate publishers linked to this school\n\t\t\t\t\t<select name=\"publishers\" data-placeholder=\"Private publishers for the school\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tOrganisational groups\n\t\t\t\t\t<select name=\"groups\" data-placeholder=\"Organisational groups\" multiple class=\"adder\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tOrganisational Types\n\t\t\t\t\t<select name=\"orgTypes\" data-placeholder=\"Organisational types\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tOrganisational Categories\n\t\t\t\t\t<select name=\"orgCategories\" data-placeholder=\"Organisational categories\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"comments"))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t<label>Comment\n\t\t\t\t\t<textarea name=\"comment\" rows=\"6\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "comment"), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Enabled Auth Providers\n\t\t\t\t\t<select name=\"oauth\" multiple ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editAuth")))?"disabled":""), env.opts.autoescape);
output += "/>\n\t\t\t\t</label>\n\t\t\t\t<div id=\"custom-oauth-container\" class=\"large-12 columns\">\n\t\t\t\t\t";
frame = frame.push();
var t_3 = (lineno = 167, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "oauths"), "oauths", context, []));
if(t_3) {var t_1;
if(runtime.isArray(t_3)) {
var t_2 = t_3.length;
for(t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1][0]
frame.set("key", t_3[t_1][0]);
var t_5 = t_3[t_1][1]
frame.set("val", t_3[t_1][1]);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"oauth")),t_4)),"enabled") && runtime.memberLookup((t_5),"editable") == true) {
output += "\n\t\t\t\t\t\t\t<div id='";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "-wrapper' class='large-12 columns'>\n\t\t\t\t\t\t\t\t<h6>";
output += runtime.suppressValue(env.getFilter("capitalize").call(context, (t_4 + " details")), env.opts.autoescape);
output += "</h6>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_8 = runtime.memberLookup((t_5),"fields");
if(t_8) {var t_6;
if(runtime.isArray(t_8)) {
var t_7 = t_8.length;
for(t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6][0]
frame.set("fieldKey", t_8[t_6][0]);
var t_10 = t_8[t_6][1]
frame.set("fieldVal", t_8[t_6][1]);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t\t\t\t<label> ";
output += runtime.suppressValue(runtime.memberLookup((t_10),"fieldName"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"text\" name=\"";
output += runtime.suppressValue(t_9, env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"oauth")),t_4)),t_9), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((t_10),"fieldName"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editAuth")))?"disabled":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
;
}
} else {
t_6 = -1;
var t_7 = runtime.keys(t_8).length;
for(var t_11 in t_8) {
t_6++;
var t_12 = t_8[t_11];
frame.set("fieldKey", t_11);
frame.set("fieldVal", t_12);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t\t\t\t<label> ";
output += runtime.suppressValue(runtime.memberLookup((t_12),"fieldName"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"text\" name=\"";
output += runtime.suppressValue(t_11, env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"oauth")),t_4)),t_11), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((t_12),"fieldName"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editAuth")))?"disabled":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t";
;
}
} else {
t_1 = -1;
var t_2 = runtime.keys(t_3).length;
for(var t_13 in t_3) {
t_1++;
var t_14 = t_3[t_13];
frame.set("key", t_13);
frame.set("val", t_14);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"oauth")),t_13)),"enabled") && runtime.memberLookup((t_14),"editable") == true) {
output += "\n\t\t\t\t\t\t\t<div id='";
output += runtime.suppressValue(t_13, env.opts.autoescape);
output += "-wrapper' class='large-12 columns'>\n\t\t\t\t\t\t\t\t<h6>";
output += runtime.suppressValue(env.getFilter("capitalize").call(context, (t_13 + " details")), env.opts.autoescape);
output += "</h6>\n\t\t\t\t\t\t\t\t";
frame = frame.push();
var t_17 = runtime.memberLookup((t_14),"fields");
if(t_17) {var t_15;
if(runtime.isArray(t_17)) {
var t_16 = t_17.length;
for(t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15][0]
frame.set("fieldKey", t_17[t_15][0]);
var t_19 = t_17[t_15][1]
frame.set("fieldVal", t_17[t_15][1]);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t\t\t\t<label> ";
output += runtime.suppressValue(runtime.memberLookup((t_19),"fieldName"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"text\" name=\"";
output += runtime.suppressValue(t_18, env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"oauth")),t_13)),t_18), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((t_19),"fieldName"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editAuth")))?"disabled":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
;
}
} else {
t_15 = -1;
var t_16 = runtime.keys(t_17).length;
for(var t_20 in t_17) {
t_15++;
var t_21 = t_17[t_20];
frame.set("fieldKey", t_20);
frame.set("fieldVal", t_21);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
output += "\n\t\t\t\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t\t\t\t<label> ";
output += runtime.suppressValue(runtime.memberLookup((t_21),"fieldName"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t\t\t\t\t\t<input type=\"text\" name=\"";
output += runtime.suppressValue(t_20, env.opts.autoescape);
output += "\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"oauth")),t_13)),t_20), env.opts.autoescape);
output += "\" placeholder=\"";
output += runtime.suppressValue(runtime.memberLookup((t_21),"fieldName"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"schools")),"editAuth")))?"disabled":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<br/>\n\t\t\t\t<h4>Emails</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label><input name=\"chosenPriceChangeEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"chosenPriceChangeEmail") || (lineno = 190, colno = 99, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tChosen book price changed</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label><input name=\"schoolChoiceAddedEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"schoolChoiceAddedEmail") || (lineno = 195, colno = 99, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tSchool choice added</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label><input name=\"schoolChoiceConfirmedEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"schoolChoiceConfirmedEmail") || (lineno = 200, colno = 107, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tSchool choice confirmed</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label><input name=\"schoolChoiceRemovedEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"schoolChoiceRemovedEmail") || (lineno = 205, colno = 103, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\tSchool choice removed</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<br />\n\t\t\t\t<h4>Billing Info</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"excludeBilling\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "exclude_billing")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\"> Exclude from billing system</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label><input name=\"isonline\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "buy_online")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\"> Buy-online school</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "buy_online")?"":"hide"), env.opts.autoescape);
output += "\" id=\"bookTokenForwardingDNSArea\">\n\t\t\t\t<label>Book Token Forwarding DNS List <i style=\"font-size:smaller;\">(Comma seperated values)</i>\n\t\t\t\t\t<textarea rows=\"2\" name=\"bookTokenForwardingDNS\" placeholder=\"Book Token Forwarding DNS List Details\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "book_token_forwarding_dns")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Billing name\n\t\t\t\t\t<input type=\"text\" name=\"billingName\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "billing_name")), env.opts.autoescape);
output += "\" placeholder=\"Billing name\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Account number\n\t\t\t\t\t<input type=\"text\" name=\"accNumber\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "account_number")), env.opts.autoescape);
output += "\" placeholder=\"Account number\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Vat number (if registered)\n\t\t\t\t\t<input type=\"text\" name=\"vatNumber\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "vat_number")), env.opts.autoescape);
output += "\" placeholder=\"Vat number\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Company registration number\n\t\t\t\t\t<input type=\"text\" name=\"companyReg\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "company_registration")), env.opts.autoescape);
output += "\" placeholder=\"Company registration number of school\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Company details\n\t\t\t\t\t<textarea rows=\"5\" name=\"companyDetails\" placeholder=\"Company Details\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "company_details")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<br />\n\t\t\t\t<h4>Contact Info</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h6>Primary</h6>\n\t\t\t\t<label>Contact person first name*\n\t\t\t\t\t<input type=\"text\" name=\"primaryContactPersonFirstname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_contact_person_firstname")), env.opts.autoescape);
output += "\" placeholder=\"Primary contact person first name\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Contact person surname*\n\t\t\t\t\t<input type=\"text\" name=\"primaryContactPersonSurname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_contact_person_surname")), env.opts.autoescape);
output += "\" placeholder=\"Primary contact person surname\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Email *\n\t\t\t\t\t<input type=\"text\" name=\"primaryEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_email")), env.opts.autoescape);
output += "\" placeholder=\"Primary email address\"/>\n\t\t\t\t\t<div class=\"input-overlay email-spinner hide\" title=\"Checking existence...\"><span class=\"spinner-icon spinner-small\"/></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Phone number *\n\t\t\t\t\t<input type=\"text\" name=\"primaryPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Primary phone number\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h6>Financial</h6>\n\t\t\t\t<label><input name=\"financialCopyPrimary\" data-group=\"financial\" type=\"checkbox\" /><span>&nbsp;Same as Primary Contact</span></label>\n\t\t\t\t<div id=\"financialContactGroup\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Contact person\n\t\t\t\t\t\t\t<input type=\"text\" name=\"financialContactPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_contact_person")), env.opts.autoescape);
output += "\" placeholder=\"Financial contact person\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Email\n\t\t\t\t\t\t\t<input type=\"text\" name=\"financialEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_email")), env.opts.autoescape);
output += "\" placeholder=\"Financial email address\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Phone number\n\t\t\t\t\t\t\t<input type=\"text\" name=\"financialPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Financial phone number\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h6>Technical</h6>\n\t\t\t\t<label><input name=\"technicalCopyPrimary\" data-group=\"technical\" type=\"checkbox\" /><span>&nbsp;Same as Primary Contact</span></label>\n\t\t\t\t<div id=\"technicalContactGroup\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Contact person\n\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalContactPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_contact_person")), env.opts.autoescape);
output += "\" placeholder=\"Technical contact person\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Email\n\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_email")), env.opts.autoescape);
output += "\" placeholder=\"Technical email address\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Phone number\n\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Technical phone number\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h4>Support</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<input name=\"activeSchool\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "active_school")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\">\n\t\t\t\t\t<label>Active School?</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPackage Type\n\t\t\t\t\t<select name=\"orgPackages\" data-placeholder=\"Organisational categories\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSchool Grades on System\n\t\t\t\t\t<select name=\"orgGrades\" data-placeholder=\"Organisational grades\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Team Manager\n\t\t\t\t\t<input type=\"text\" name=\"teamManager\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "team_manager")), env.opts.autoescape);
output += "\" placeholder=\"Team Manager\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Support Person\n\t\t\t\t\t<input type=\"text\" name=\"supportPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "support_person")), env.opts.autoescape);
output += "\" placeholder=\"Support Person\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Sales Person\n\t\t\t\t\t<select name=\"salesPerson\" data-placeholder=\"Sales person\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Contract Start Date\n\t\t\t\t\t<input type=\"text\" name=\"contractStartDate\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "contract_start_date")), env.opts.autoescape);
output += "\" placeholder=\"Contract Start Date\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Contract End Date\n\t\t\t\t\t<input type=\"text\" name=\"contractEndDate\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "contract_end_date")), env.opts.autoescape);
output += "\" placeholder=\"Contract End Date\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Tags\n\t\t\t\t\t<input type=\"text\" name=\"tags\" value=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "tags")),", "), env.opts.autoescape);
output += "\" placeholder=\"Tags\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tRatings\n\t\t\t\t\t<select name=\"ratings\" data-placeholder=\"Ratings\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tAdditional Platforms\n\t\t\t\t\t<select name=\"additionalPlatforms\" data-placeholder=\"Additional Platforms\" multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tComments\n\t\t\t\t\t<input type=\"text\" name=\"comments\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "comments")), env.opts.autoescape);
output += "\" placeholder=\"Comments\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t";
if((lineno = 424, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += " <label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
output += "\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 425, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching school data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
