var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var initial_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<div class=\"spinner text-center\"><span class=\"spinner-icon spinner-small\"></span></div>\n\t\t<div class=\"text text-center\">&nbsp;Fetching school details...</div>\n\t</div>\n\n\t<div class=\"main\">\n\t\t<div class=\"overlay\">\n\t\t\t<div><br><br><br><br><span class=\"spinner-icon spinner-small\"/> Loading products...</div>\n\t\t</div>\n\t\t<div class=\"schools\"></div>\n\t\t<div class=\"books\">\n\t\t</div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var initial_oldRoot = initial_obj.root;
initial_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	initial_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var header_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"actions\">\n\t\t";
if(env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"api")),"schools")),"bookList")),"get"))) {
output += "<div class=\"downloadList\"><span class=\"fal fa-cloud-download-alt\" />&nbsp;Download chosen e&#x2011;book list</div>";
;
}
output += "\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var header_oldRoot = header_obj.root;
header_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	header_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var books_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"year-overlay\"></div>\n\n\t<div class=\"years\">Choosing e&#x2011;books for the <strong>2015</strong> school year</div>\n\n\t<div class=\"filters\">\n\t\t<div class=\"filterContainer\"></div>\n\t</div>\n\n\t<div class=\"info-bar\">\n\t\t<div class=\"counter\"></div>\n\n\t\t<select name=\"sort\">\n\t\t\t<option value=\"-\">(unsorted)</option>\n\t\t\t<option value=\"name+\">Sort by name (A to Z)</option>\n\t\t\t<option value=\"name-\">Sort by name (Z to A)</option>\n\t\t\t<option value=\"price+\">Sort by price (low to high)</option>\n\t\t\t<option value=\"price-\">Sort by price (high to low)</option>\n\t\t</select>\n\t</div>\n\t<div class=\"paging-container\">\n\t\t<div class=\"paging\">\n\t\t</div>\n\t</div>\n\t<div class=\"booklist\"></div>\n\n\t<div class=\"paging-container\">\n\t\t<div class=\"paging\">\n\t\t</div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var books_oldRoot = books_obj.root;
books_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	books_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var schools_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"school-counter\"></div>\n\t<label>\n\t\t<input type=\"text\" name=\"schoolSearch\" placeholder=\"Filter by school name\">\n\t\t<div class=\"input-overlay hide\" title=\"Clear filter\"><span class=\"fal fa-times\"/></div>\n\t</label>\n\t<div class=\"buttons\">\n\t\t<button class=\"select-all tiny secondary\"><span class=\"fal fa-check-square\"/> Select all</button>\n\t\t<button class=\"deselect-all tiny secondary\"><span class=\"fal fa-square\"/> Deselect all</button>\n\t</div>\n\t<div class=\"container\">\n\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var schools_oldRoot = schools_obj.root;
schools_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	schools_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var schoolItem_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<span class=\"fal fa-check ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "ignore")?"":"hide"), env.opts.autoescape);
output += "\"></span> <span class=\"text\" title=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "fullname"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "fullname"), env.opts.autoescape);
output += "</span>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var schoolItem_oldRoot = schoolItem_obj.root;
schoolItem_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	schoolItem_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var schoolModifiers_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t";
if(runtime.contextOrFrameLookup(context, frame, "addd") && runtime.contextOrFrameLookup(context, frame, "addBoolean")) {
output += "\n\t<div class=\"school-add\">\n\t\t<span class=\"fal fa-plus\"></span> Choose (";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "addd"), env.opts.autoescape);
output += ")\n\t</div>\n\t";
;
}
output += "\n\n\t";
if(runtime.contextOrFrameLookup(context, frame, "remove") && env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"api")),"schools")),"_")),"books")),"confirm")),"put"))) {
output += "\n\t<div class=\"school-remove\">\n\t\t<span class=\"fal fa-minus\"></span> Remove (";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "remove"), env.opts.autoescape);
output += ")\n\t</div>\n\t";
;
}
output += "\n\n\t";
if(runtime.contextOrFrameLookup(context, frame, "hasBoughtLicenses") && !runtime.contextOrFrameLookup(context, frame, "online_school")) {
output += "\n\t\t<div class=\"license-history\">Review Purchased Licenses</div>\n\t";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var schoolModifiers_oldRoot = schoolModifiers_obj.root;
schoolModifiers_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	schoolModifiers_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var initialYearChoose_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div>Please select the school year you wish to choose e-books for.</div>\n\t<div>You will be able to change this afterwards.</div>\n\t<div>\n\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "years");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("year", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t<span class=\"year ";
output += runtime.suppressValue(((t_4 == runtime.contextOrFrameLookup(context, frame, "currentYear"))?"now":""), env.opts.autoescape);
output += "\" data-year=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "</span>\n\t";
;
}
}
frame = frame.pop();
output += "\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var initialYearChoose_oldRoot = initialYearChoose_obj.root;
initialYearChoose_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	initialYearChoose_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var licenseReview_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"><span class=\"fal fa-license\"/> Purchased Licenses</div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"ops-bar\">\n\t\t\t<div class=\"filters\"></div>\n\t\t\t<div class=\"counter\"></div>\n\t\t</div>\n\n\t\t<div class=\"grid\"></div>\n\n\t\t<div class=\"paging\"></div>\n\t</div>\n\t<div class=\"modal-footer\"><input type=\"button\" class=\"button success\" value=\"Close\"/></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var licenseReview_oldRoot = licenseReview_obj.root;
licenseReview_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	licenseReview_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"initial": new nunjucks.Template( {obj: initial_obj, type: "code"}, env),	"header": new nunjucks.Template( {obj: header_obj, type: "code"}, env),	"books": new nunjucks.Template( {obj: books_obj, type: "code"}, env),	"schools": new nunjucks.Template( {obj: schools_obj, type: "code"}, env),	"schoolItem": new nunjucks.Template( {obj: schoolItem_obj, type: "code"}, env),	"schoolModifiers": new nunjucks.Template( {obj: schoolModifiers_obj, type: "code"}, env),	"initialYearChoose": new nunjucks.Template( {obj: initialYearChoose_obj, type: "code"}, env),	"licenseReview": new nunjucks.Template( {obj: licenseReview_obj, type: "code"}, env),};
