var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\"><span class=\"fal fa-flag\"/>&nbsp;Reports</h4>\n\t</div>\n\n\t<div class=\"host\">\n\t\t<div class=\"sidebar\">\n\t\t\t<div class=\"group\">\n\t\t\t\t<span>Schools</span>\n\t\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"reports")),"schools")),"groupCodes")))) {
output += "<div class=\"item group-codes\" data-report=\"group-codes\">Group Code Summary</div>";
;
}
output += "\n\t\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"reports")),"schools")),"publisherSummary")))) {
output += "<div class=\"item school-publisher-summary\" data-report=\"school-publisher-summary\">Publisher Summary</div>";
;
}
output += "\n\t\t\t</div>\n\t\t\t<div class=\"group\">\n\t\t\t\t<span>Books</span>\n\t\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"reports")),"books")),"bookTransfers")))) {
output += "<div class=\"item book-transfers\" data-report=\"book-transfers\">Book Transfers</div>";
;
}
output += "\n\t\t\t</div>\n\t\t\t<div class=\"group\">\n\t\t\t\t<span>Publishers</span>\n\t\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"reports")),"publishers")),"bookStats")))) {
output += "<div class=\"item publisher-book-stats\" data-report=\"publisher-book-stats\">Book Stats</div>";
;
}
output += "\n\t\t\t</div>\n\t\t\t<div class=\"group\">\n\t\t\t\t<span>FTP sources</span>\n\t\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"reports")),"ftpSources")),"publisherStats")))) {
output += "<div class=\"item ftpsources-publisher-stats\" data-report=\"ftpsources-publisher-stats\">Publisher Stats</div>";
;
}
output += "\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"main\"><h3>Choose a report</h3></div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var groupCodes_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\">Group Code Summary Report</h4>\n\n\t\t<div class=\"actions\">\n\t\t\t<div class=\"downloadList\"><span class=\"fal fa-cloud-download-alt\"/> Downloads</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var groupCodes_oldRoot = groupCodes_obj.root;
groupCodes_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	groupCodes_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var schoolPublisherSummary_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\">School Publisher Summary Report</h4>\n\n\t\t<div class=\"actions\">\n\t\t\t<div class=\"downloadList\"><span class=\"fal fa-cloud-download-alt\"/> Downloads</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var schoolPublisherSummary_oldRoot = schoolPublisherSummary_obj.root;
schoolPublisherSummary_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	schoolPublisherSummary_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var bookTransfers_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\">Book Transfers Report</h4>\n\n\t\t<div class=\"actions\">\n\t\t\t<div class=\"downloadList\"><span class=\"fal fa-cloud-download-alt\"/> Downloads</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var bookTransfers_oldRoot = bookTransfers_obj.root;
bookTransfers_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	bookTransfers_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var publisherBooks_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\">Publisher Books Report</h4>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var publisherBooks_oldRoot = publisherBooks_obj.root;
publisherBooks_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	publisherBooks_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var ftpsourcesPublisherStats_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\">FTP Sources Publisher Stats</h4>\n\n\t\t<div class=\"actions\">\n\t\t\t<div class=\"downloadList\"><span class=\"fal fa-cloud-download-alt\"/> Downloads</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var ftpsourcesPublisherStats_oldRoot = ftpsourcesPublisherStats_obj.root;
ftpsourcesPublisherStats_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	ftpsourcesPublisherStats_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"main": new nunjucks.Template( {obj: main_obj, type: "code"}, env),	"groupCodes": new nunjucks.Template( {obj: groupCodes_obj, type: "code"}, env),	"schoolPublisherSummary": new nunjucks.Template( {obj: schoolPublisherSummary_obj, type: "code"}, env),	"bookTransfers": new nunjucks.Template( {obj: bookTransfers_obj, type: "code"}, env),	"publisherBooks": new nunjucks.Template( {obj: publisherBooks_obj, type: "code"}, env),	"ftpsourcesPublisherStats": new nunjucks.Template( {obj: ftpsourcesPublisherStats_obj, type: "code"}, env),};
