var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"left\">\n\n\t<div class=\"header\">\n\t\t<h4 class=\"title\"><span class=\"fal fa-users fa-fw\"/> Users</h4>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n\n</div>\n\n<div class=\"right\">\n\t<div class=\"header\">\n\t\t<h4 class=\"title\"><span class=\"fal fa-money-bill-alt fa-fw\"/> Credit</h4>\n\t\t<div class=\"actions\">\n\t\t\t<div class=\"add-transaction hide\" data-open=\"addTransactionModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\"><span class=\"fal fa-plus fa-fw\" /> Add Transaction</div>\n\t\t</div>\n\t</div>\n\n\t<div id=\"total\"></div>\n\n\t<div id=\"transactions\"></div>\n</div>\n\n\n<div class=\"reveal large\" id=\"addTransactionModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" data-reveal>\n\t<h4>Add New Transaction</h4>\n\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t<span aria-hidden=\"true\">&times;</span>\n\t</button>\n\n  \t<div class=\"body\">\n  \t\t<label>User</label>\n  \t\t<div id=\"username\" class=\"blueText\" style=\"margin-bottom:10px;\"></div>\n\n  \t\t<ul class=\"tabs\" data-tabs id=\"example-tabs";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n\t\t\t<li class=\"tabs-title is-active\"><a href=\"#panel1";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" aria-selected=\"true\">Refund Purchase</a></li>\n\t\t\t<li class=\"tabs-title\"><a data-tabs-target=\"panel2";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" href=\"#panel2";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">Other Store Credit</a></li>\n\t\t</ul>\n\n\t\t<div class=\"tabs-content\" data-tabs-content=\"example-tabs";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n  \t\t\t<div class=\"tabs-panel is-active\" id=\"panel1";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n\n  \t\t\t\t<div class=\"purchases\"></div>\n\n  \t\t\t\t<div class=\"modalButtons buttonCenter\">\n\t\t\t\t\t<button class=\"createPurchaseTransaction button small success\" type=\"submit\">Create</button>\n\t\t\t\t\t<button class=\"button small secondary\" type=\"button\" data-close>Cancel</button>\n\t\t\t\t</div>\n\n\t\t\t</div>\n  \t\t\t<div class=\"tabs-panel\" id=\"panel2";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\">\n\n\t\t\t\t<form action=\"#\" id=\"addWalletTransactionForm\">\n\t\t  \t\t\t<label>Currency</label>\n\t\t\t\t  \t<select name=\"cur\" style=\"background-image:none;\" data-placeholder=\"Select Type\" id=\"newTransactionCurrency\">\n\t\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "currencies");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("currency", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"abbr"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += " (";
output += runtime.suppressValue(runtime.memberLookup((t_4),"symbol"), env.opts.autoescape);
output += ")</option>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\n\t\t\t\t  \t<label>Amount</label>\n\t\t\t\t  \t<input type=\"number\" name=\"transactionAmount\" id=\"newTransactionAmount\" placeholder=\"Transaction Amount\" required min=\"-15000.00\" max=\"150000.00\" step=\"0.01\">\n\n\t\t\t\t  \t<label>Transaction Type</label>\n\t\t\t\t  \t<select name=\"types\" style=\"background-image:none;\" data-placeholder=\"Select Type\" id=\"newTransactionType\">\n\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "types");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("type", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\n\t\t\t\t\t<label>Details</label>\n\t\t\t\t  \t<input type=\"text\" name=\"transactionADetails\" id=\"newTransactionDetails\" placeholder=\"Transaction Details\" required>\n\n\n\t\t\t\t  \t<div class=\"modalButtons buttonCenter\">\n\t\t\t\t\t\t<input class=\"createTransaction button small success\" type=\"submit\" value=\"Create\">\n\t\t\t\t\t\t<button class=\"button small secondary\" type=\"button\" data-close>Cancel</button>\n\t\t\t\t\t</div>\n\t\t\t\t</form>\n\n\n\n\t\t</div>\n\t</div>\n\n\t</div>\n\n\n\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
