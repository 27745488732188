var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"school-choose-item-block\">\n\t<div class=\"school-choose-coverimg\" style=\"background-image: url('";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/api/1.0/services/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "service_id"), env.opts.autoescape);
output += "/cover?thumb=1');\"></div>\n\n\t<div class=\"school-choose-overlay\">\n\t</div>\n\n\t<div class=\"school-choose-itemdata\">\n\t\t<div class=\"item-title\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("trim").call(context, runtime.contextOrFrameLookup(context, frame, "name"))), env.opts.autoescape);
output += "</div>\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "online_school") == false) {
output += "\n\t\t\t<div class=\"item-license\">License: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "license_name")), env.opts.autoescape);
output += " ";
if(!runtime.contextOrFrameLookup(context, frame, "perpetual")) {
output += " (duration: <strong>";
output += runtime.suppressValue(env.getFilter("durationFormat").call(context, runtime.contextOrFrameLookup(context, frame, "duration")), env.opts.autoescape);
output += "</strong>)";
;
}
output += "</div>\n\t\t";
;
}
output += "\n\t\t<div class=\"item-publisher\">Publisher: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "fullname")), env.opts.autoescape);
output += "</div>\n\n\t\t<div class=\"item-subject\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "subject"), env.opts.autoescape);
output += "</div>\n\t\t<div class=\"item-grade\">";
output += runtime.suppressValue(env.getFilter("gradeMap").call(context, runtime.contextOrFrameLookup(context, frame, "study_year"),true), env.opts.autoescape);
output += "</div>\n\n\t\t<div class=\"item-usage\"></div>\n\t</div>\n\n\t<div class=\"school-choose-pricing\">\n\t\t";
if((lineno = 20, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "price"), "price", context, [])) == 0) {
output += "\n\t\t\t<div class=\"item-price right\">Free</div>\n\t\t";
;
}
else {
output += "\n\t\t\t<div class=\"item-price right\">";
output += runtime.suppressValue(runtime.memberLookup(((lineno = 23, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "currency"), "currency", context, []))),"symbol"), env.opts.autoescape);
output += "&nbsp;";
output += runtime.suppressValue(env.getFilter("toFixed").call(context, (lineno = 23, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "price"), "price", context, [])),2), env.opts.autoescape);
output += "</div>\n\t\t";
;
}
output += "\n\t\t<div class=\"type-service fas fa-globe-americas\" title=\"This is a service\"/>\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "perpetual") && !runtime.contextOrFrameLookup(context, frame, "active")) {
output += "\n\t\t\t<div class=\"badge red\">Old version</div>\n\t\t";
;
}
else {
if(!runtime.contextOrFrameLookup(context, frame, "perpetual") && !runtime.contextOrFrameLookup(context, frame, "license_active")) {
output += "\n\t\t\t<div class=\"badge red\">Old license</div>\n\t\t";
;
}
;
}
output += "\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "private")) {
output += "<div class=\"badge\">Private book</div>";
;
}
output += "\n\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "perpetual")) {
output += "<div class=\"badge\">Limited license</div>";
;
}
output += "\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
