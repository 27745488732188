var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 99, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-book'/> Create New Hardcopy Book Quote"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing Hardcopy Book Quote: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "reference"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-8 columns quote-details\">\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>Reference:</strong>\n\t\t\t\t\t&nbsp;";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "reference"), env.opts.autoescape);
output += "\n\t\t\t\t</h6>\n\t\t\t\t<h6>\n\t\t\t\t\t<div class=\"status\">\n\t\t\t\t\t\t<strong>Status:</strong>\n\t\t\t\t\t\t&nbsp;<select name=\"status\"></select>\n\t\t\t\t\t</div>\n\t\t\t\t</h6>\n\t\t\t\t<br />\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>Delivery (Excl):</strong>\n\t\t\t\t\t&nbsp;R&nbsp;<span name=\"shippingCostExcl\">0</span>\n\t\t\t\t</h6>\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>Order (Excl):</strong>\n\t\t\t\t\t&nbsp;R&nbsp;<span name=\"quoteTotalExcl\">0</span>\n\t\t\t\t</h6>\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>VAT @ 15%:</strong>\n\t\t\t\t\t&nbsp;R&nbsp;<span name=\"taxTotal\">0</span>\n\t\t\t\t</h6>\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>Global Discount:</strong>\n\t\t\t\t\t&nbsp;<span name=\"globalDiscount\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "global_discount"), env.opts.autoescape);
output += "</span>%\n\t\t\t\t\t&nbsp;&nbsp;";
if(runtime.contextOrFrameLookup(context, frame, "status") == 0) {
output += "<label name=\"applyGlobalDiscount\"> - Apply global discount</label>";
;
}
output += "\n\t\t\t\t</h6>\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>Total Discount:</strong>\n\t\t\t\t\t&nbsp;R&nbsp;<span name=\"discountTotal\">0</span>\n\t\t\t\t</h6>\n\t\t\t\t<h6>\n\t\t\t\t\t<strong>Total:</strong>\n\t\t\t\t\t&nbsp;R&nbsp;<span name=\"quoteTotal\">";
output += runtime.suppressValue(env.getFilter("currency").call(context, runtime.contextOrFrameLookup(context, frame, "total")), env.opts.autoescape);
output += "</span>\n\t\t\t\t</h6>\n\t\t\t\t<br />\n\t\t\t\t<hr />\n\t\t\t</div>\n\t\t\t<div class=\"large-4 columns client-info\">\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "status") == 0) {
output += "<label name=\"editDetails\"><span class=\"fal fa-pencil\" /></label>";
;
}
output += "\n\t\t\t\t<h4>Details<h4>\n\t\t\t\t<table>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td><strong>Account ID:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientGuid\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_guid"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t\t<td><strong>Pastel Name:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientPastelName\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_pastel_name"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td><strong>Client:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientName\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_name"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t\t<td><strong>Contact:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientContact\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_contact_person"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td><strong>Email:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientEmail\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_contact_email"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t\t<td><strong>Tel:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientTel\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_contact_number"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td><strong>Reg Nr:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientRegistrationNr\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_vat_nr"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t\t<td><strong>Vat Nr:</strong></td>\n\t\t\t\t\t\t<td><span name=\"clientVatNr\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_vat_nr"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td><strong>Account Manager:</strong></td>\n\t\t\t\t\t\t<td><span name=\"accountManager\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "account_manager"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td><strong>Delivery Address:</strong></td>\n\t\t\t\t\t\t<td colspan=\"3\"><span name=\"clientStreet\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_street"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_city"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_province"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_postal_code"), env.opts.autoescape);
output += "</span></td>\n\t\t\t\t\t</tr>\n\t\t\t\t</table>\n\t\t\t</div>\n\t\t\t<div class=\"large-12 columns add-hardcopy-book\">\n\t\t\t\t<select name=\"hardcopyBook\"></select>\n\t\t\t</div>\n\t\t\t<div class=\"large-12 columns products\">\n\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "hardcopy_quote_id")) {
output += "\n\t\t\t\t<div class=\"header\">\n\t\t\t\t\t<div class=\"actions\">\n\t\t\t\t\t\t<div class=\"download\"><span class=\"fal fa-cloud-download\" />&nbsp;Downloads</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\t\t\t\t<div class=\"ops-bar\">\n\t\t\t\t\t<div class=\"filters\"></div>\n\t\t\t\t\t<div class=\"counter\"></div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"grid\"></div>\n\n\t\t\t\t<div class=\"paging\"></div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 105, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
