var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"listGroup\">\n\t<h4>\n\n\t\t<span class=\"listName\"><i data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\"fal fa-edit fa-fw has-tip top\" aria-hidden=\"true\" data-open=\"bookListInfoModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "book_list_id"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" title=\"Edit List\"></i>\n\t\t<spam data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\"has-tip top\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "</spam> </span>\n\t\t<span class=\"groupButtons\">\n\t\t\t<i data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\"fal fa-search-plus fa-fw has-tip top\" id=\"maximizeBtn\" aria-hidden=\"true\" title=\"Maximize list\"></i>\n\t\t\t<i data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\"fal fa-search-minus fa-fw hide has-tip top\" id=\"minimizeBtn\" aria-hidden=\"true\" title=\"Minimize list\"></i>\n\t\t\t<i data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\"fal fa-copy fa-fw copyListBtn has-tip top\" aria-hidden=\"true\" id=\"copyListBtn\" title=\"Copy list\"></i>\n\t\t\t<i data-tooltip aria-haspopup=\"true\" data-disable-hover=\"false\" class=\"fal fa-trash-alt fa-fw deleteBtn has-tip top\" aria-hidden=\"true\" title=\"Delete list\" data-open=\"deleteBookListModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "book_list_id"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\"></i>\n\t\t</span>\n\t</h4>\n\t<div class=\"listProducts\" id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "book_list_id"), env.opts.autoescape);
output += "\">\n\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "products");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("book", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t<div class=\"viewBook\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product_id"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" draggable=\"true\" data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t<div class=\"bookImg bookTooltip\" style=\"background-image:url(";
if(runtime.memberLookup((t_4),"product_type") == 1) {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/api/1.0/ebooks/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product_id"), env.opts.autoescape);
output += "/cover?thumb=true";
;
}
else {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/api/1.0/services/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"service_id"), env.opts.autoescape);
output += "/cover?thumb=true";
;
}
output += "), url('../images/no_cover.png');\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("replace").call(context, runtime.memberLookup((t_4),"name"),"&amp;","and")), env.opts.autoescape);
output += "\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product_id"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product_id"), env.opts.autoescape);
output += "\"></div>\n\n\t\t\t\t";
if(!runtime.memberLookup((t_4),"active")) {
output += "\n\t\t\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\tOld Version\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
if(runtime.memberLookup((t_4),"discontinued")) {
output += "\n\t\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\tDiscontinued\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t";
if(runtime.memberLookup((t_4),"license_id") > 0) {
output += "\n\t\t\t\t<div class=\"license-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_4),"formatted_duration"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
output += "\n\n\t\t\t\t<div class=\"title\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("replace").call(context, runtime.memberLookup((t_4),"name"),"&amp;","and")), env.opts.autoescape);
output += "</div>\n\n\t\t\t</div>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t</div>\n</div>\n\n";
if(runtime.contextOrFrameLookup(context, frame, "products")) {
output += "\n<div class=\"reveal\" id=\"bookListInfoModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "book_list_id"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" data-reveal>\n\t<h4><i class=\"fal fa-info-circle fa-fw\" aria-hidden=\"true\"></i> Book List Information</h4>\n\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t<span aria-hidden=\"true\">&times;</span>\n\t</button>\n\n\n\t\t<div class=\"body\">\n\n\t\t\t<label>Name</label>\n\t\t\t<input type=\"text\" name=\"listName\" id=\"listName\" placeholder=\"List Name\" required value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\">\n\n\t\t  \t<label>Description</label>\n\t\t  \t<input type=\"text\" name=\"listDescription\" id=\"listDescription\" placeholder=\"List Description\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "description")), env.opts.autoescape);
output += "\">\n\n\t\t  \t<label>Languages</label>\n\t\t  \t<select name=\"languages\" data-placeholder=\"Select Lanuages\" multiple>\n\t\t\t</select>\n\n\t\t  \t<label>Grades</label>\n\t\t  \t<select name=\"grades\" data-placeholder=\"Select Grades\" multiple>\n\t\t\t</select>\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "school_id") == 0) {
output += "\n\t\t\t\t<label>Store</label>\n\t\t\t  \t<select name=\"store\" data-placeholder=\"Select Store\">\n\t\t\t\t</select>\n\t\t\t";
;
}
output += "\n\n\t\t</div>\n\n\t\t<div class=\"modalButtons\">\n\t\t\t<button class=\"updateListBtn button small success\" type=\"submit\" data-close>Update</button>\n\t\t\t<button class=\"button small secondary\" type=\"button\" data-close>Cancel</button>\n\t\t</div>\n\n\n</div>\n\n\n<div class=\"reveal\" id=\"deleteBookListModal";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "book_list_id"), env.opts.autoescape);
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "random"), env.opts.autoescape);
output += "\" data-reveal>\n\t<h4><i class=\"fal fa-trash-alt fa-fw\" aria-hidden=\"true\"></i> Book List Information</h4>\n\t<button class=\"close-button\" data-close aria-label=\"Close modal\" type=\"button\">\n\t\t<span aria-hidden=\"true\">&times;</span>\n\t</button>\n\n\t\t<div class=\"body\">\n\t\t\tAre you sure you want to delete book list: ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\n\t\t</div>\n\n\t\t<div class=\"modalButtons\">\n\t\t\t<button class=\"deleteListBtn button small alert\" type=\"button\" data-close>Delete</button>\n\t\t\t<button class=\"button small secondary\" type=\"button\" data-close>Cancel</button>\n\t\t</div>\n\n\n</div>\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
