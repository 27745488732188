var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> <span class='fal fa-plus'/> ";
output += runtime.suppressValue(((lineno = 1, colno = 79, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create New"):("Update")), env.opts.autoescape);
output += " Collection </div>\n\t<div class=\"modal-body\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "inlineMessage")) {
output += "\n\t\t<p class=\"inline\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "inlineMessage")), env.opts.autoescape);
output += "</p>\n\t\t";
;
}
output += "\n\n\t\t<div class=\"row\">\n\t\t\t<br />\n\t\t\t<h4 style=\"width:100%\">Details ";
if(!(lineno = 9, colno = 46, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += "<button class=\"button small copyLink\" style=\"float:right;\"><i class=\"fal fa-link fa-fw\" aria-hidden=\"true\"></i> Copy Link</button>";
;
}
output += "</h4>\n\t\t\t<div class=\"large-12 columns\">\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tName *\n\t\t\t\t\t\t<input type=\"text\" name=\"name\" placeholder=\"Name\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tDescription\n\t\t\t\t\t\t<input type=\"text\" name=\"description\" placeholder=\"Description\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "description"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tStart Date\n\t\t\t\t\t\t<input type=\"date\" name=\"startDate\" placeholder=\"Start Date\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "start_date"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tEnd Date (leave blank for perpetual)\n\t\t\t\t\t\t<input type=\"date\" name=\"endDate\" placeholder=\"End Date\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "end_date"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<input name=\"public\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "public") || (lineno = 41, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t\t<label>Show in Store Collections Filter</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tTags\n\t\t\t\t\t\t<select name=\"tags\" data-placeholder=\"Tags\" multiple class=\"parent\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tCustom Stores\n\t\t\t\t\t\t<select name=\"custom_stores\" data-placeholder=\"Custom Stores\" multiple class=\"parent\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tBanner Image <br>\n\t\t\t\t\t\t<i style=\"font-size: 0.8rem;\">Only the last image uploaded will be used.</i>\n\t\t\t\t\t\t<div class=\"dropzone\" id=\"imageUpload\" style=\"margin-top: 5px;min-height:125px;\"></div>\n\t\t\t\t\t\t<br>\n\t\t\t\t\t\t<img src=\"../api/1.0/landingPages/image?fileName=";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "image"), env.opts.autoescape);
output += "\" id=\"landingPageImage\" name=\"image\" class=\"";
if(!runtime.contextOrFrameLookup(context, frame, "image")) {
output += "hide";
;
}
output += "\">\n\t\t\t\t</label>\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 77, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"small skip secondary button\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching collection data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
