var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\"> <span class='fal fa-download'/> Download Sales Report Batch</div>\n<div class=\"modal-body\">\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>Format</label>\n\t    \t<input type=\"checkbox\" name=\"pdf\" id=\"pdf\" checked><label for=\"pdf\">PDF</label>\n\t\t\t<input type=\"checkbox\" name=\"csv\" id=\"csv\" checked><label for=\"csv\">CSV</label>\n\t\t\t<label>\n\t\t\t\tMonth\n\t\t\t\t<select name=\"months\" data-placeholder=\"Select month...\">\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t\t<label>\n\t\t\t\tYear\n\t\t\t\t<select name=\"years\" data-placeholder=\"Select year...\">\n\t\t\t\t</select>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n</div>\n<div class=\"modal-footer left\">\n\t<button class=\"button download success\">Download</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<button class=\"button small cancel secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
