var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"row\">\n\n\t<div class=\"large-12 columns\">\n\t\t<h3><span class=\"fal fa-user\"/> Account Details for <span>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "firstname")), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "surname")), env.opts.autoescape);
output += "</name></h3>\n\t</div>\n\n\t<div class=\"large-12 columns\">\n\t\t<ul class=\"tabs\" data-tabs id=\"account-nav\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "isSchoolAdmin") || runtime.contextOrFrameLookup(context, frame, "isPublisherAdmin")) {
output += "\n\t\t\t\t<li class=\"tabs-title is-active\" role=\"presentation\"><a aria-controls=\"userDetails\">User Details</a></li>\n\t\t\t";
;
}
output += "\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "isSchoolAdmin")) {
output += "\n\t\t\t\t<li class=\"tabs-title\" role=\"presentation\"><a aria-controls=\"schoolDetails\">School Details</a></li>\n\t\t\t";
;
}
output += "\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "isPublisherAdmin")) {
output += "\n\t\t\t\t<li class=\"tabs-title\" role=\"presentation\"><a aria-controls=\"publisherDetails\" class=\"publisherDetails\">Publisher Details</a></li>\n\t\t\t";
;
}
output += "\n\t\t</ul>\n\t</div>\n\n\t<div class=\"large-12 columns\">\n\t\t<div class=\"tabs-content\" data-tabs-content=\"account-nav\">\n\t\t\t<section aria-hidden=\"false\" id=\"userDetails\">\n\n\t\t\t</section>\n\t\t\t<section aria-hidden=\"true\" id=\"schoolDetails\">\n\n\t\t\t</section>\n\t\t\t<section aria-hidden=\"true\" id=\"publisherDetails\">\n\n\t\t\t</section>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
