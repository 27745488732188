module.exports=[
	{
		"value": "00",
		"description": "Unrated",
		"notes": ""
	},
	{
		"value": "01",
		"description": "Any adult audience",
		"notes": "The publisher states that the product is suitable for any adult audience."
	},
	{
		"value": "02",
		"description": "Content warning",
		"notes": "The publisher warns the content may offend parts of the adult audience (for any reason)."
	},
	{
		"value": "03",
		"description": "Content warning (sex)",
		"notes": "The publisher warns the product includes content of an explicit sexual nature."
	},
	{
		"value": "04",
		"description": "Content warning (violence)",
		"notes": "The publisher warns the product includes content of a violent nature."
	},
	{
		"value": "05",
		"description": "Content warning (drug-taking)",
		"notes": "The publisher warns the product includes content involving misuse of drugs."
	},
	{
		"value": "06",
		"description": "Content warning (language)",
		"notes": "The publisher warns the product includes extreme / offensive / explicit language."
	},
	{
		"value": "07",
		"description": "Content warning (intolerance)",
		"notes": "The publisher warns the product includes content involving intolerance of particular groups (eg religious, ethnic, racial, social)."
	}
]