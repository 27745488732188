var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var mpub_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<iframe id=\"book-display\" name=\"book-display\" frameborder=\"0\">\n\t</iframe>\n\n\t<div class=\"attachment-bin attachment-bin-hidden\">\n\t\t<span class=\"fal fa-trash-alt\"></span>\n\t</div>\n\n\t<div id=\"book-overlay\">\n\t\t<div id=\"ie-pip\" style=\"z-index: 9999; width: 32px; height: 32px; position: absolute; pointer-events: none; opacity: 0.75\" class=\"hide\"></div>\n\t\t<div class=\"notifications\">\n\t\t\t<div class=\"red groups hide\">\n\t\t\t\t<span>\n\t\t\t\t\t<span class=\"fal fa-exclamation-circle\"/> Not all global groups are currently selected for attachments (click here if you need to change group allocations)\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"infoBlock\">\n\t\t\t<span class=\"spinner-icon spinner-red\">&nbsp;</span>\n\t\t\t<span class=\"warn\">Please note that books with lots of images may be slow to load over remote or mobile connections.</span>\n\t\t</div>\n\n\t\t<div class=\"paging-button up hide\">\n\t\t\t<span class=\"fal fa-chevron-up\"></span>\n\t\t</div>\n\n\t\t<div class=\"paging-button down hide\">\n\t\t\t<span class=\"fal fa-chevron-down\"></span>\n\t\t</div>\n\n\t\t<div id=\"attachment-container\"></div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var mpub_oldRoot = mpub_obj.root;
mpub_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	mpub_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var mpdf_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div id=\"canvas-display\">\n\t\t<canvas></canvas>\n\t</div>\n\n\t<div class=\"attachment-bin attachment-bin-hidden\">\n\t\t<span class=\"fal fa-trash-alt\"></span>\n\t</div>\n\n\t<div id=\"book-overlay\">\n\t\t<div class=\"notifications\">\n\t\t\t<div class=\"red groups hide\">\n\t\t\t\t<span>\n\t\t\t\t\t<span class=\"fal fa-exclamation-circle\"/> Not all global groups are currently selected for attachments (click here if you need to to change group allocations)\n\t\t\t\t</span>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"infoBlock\">\n\t\t\t<span class=\"spinner-icon spinner-red\">&nbsp;</span>\n\t\t\t<span class=\"warn\">Please note that large PDFs may be slow to load over remote or mobile connections.</span>\n\t\t</div>\n\n\t\t<div class=\"highlight-bar hide\"></div>\n\n\t\t<div class=\"paging-button up hide\">\n\t\t\t<span class=\"fal fa-chevron-up\"></span>\n\t\t</div>\n\n\t\t<div class=\"paging-button down hide\">\n\t\t\t<span class=\"fal fa-chevron-down\"></span>\n\t\t</div>\n\n\t\t<div id=\"attachment-container\"></div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var mpdf_oldRoot = mpdf_obj.root;
mpdf_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	mpdf_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"mpub": new nunjucks.Template( {obj: mpub_obj, type: "code"}, env),	"mpdf": new nunjucks.Template( {obj: mpdf_obj, type: "code"}, env),};
