var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"createAdvert\">\n\t<div class=\"dropzone\" id=\"imageUpload\"></div>\n\t<div class=\"or\">- or -</div>\n\t<div class=\"landingPage\">\n\t\t<h4>Create From Landing Page</h4>\n\t\t<select name=\"landingPages\" data-placeholder=\"Landing Pages\" class=\"parent\"></select>\n\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"api")),"landingPages")),"post")))) {
output += "\n\t\t\t<div class=\"add-landingPage\"><span class=\"fal fa-plus\" />&nbsp;Add Landing Page</div>\n\t\t";
;
}
output += "\n\t</div>\n</div>\n\n\n<table class=\"table table-striped\">\n\t<thead>\n\t\t<tr>\n\t\t\t<td></td>\n\t\t\t<td>Image</td>\n\t\t\t<td>Start Date</td>\n\t\t\t<td>End Date</td>\n\t\t\t<td>Link</td>\n\t\t\t<td>New Tab</td>\n\t\t\t<td>Regions</td>\n\t\t\t<td>Mobile</td>\n\t\t\t<td>Actions</td>\n\t\t</tr>\n\t</thead>\n\n\t<tbody>\n\t\t";
frame = frame.push();
var t_3 = env.getFilter("sort").call(context, runtime.contextOrFrameLookup(context, frame, "images"),false,false,"sort_number");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("image", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t<tr valign=\"middle\" class=\"advert-row\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t<td><span data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\" class=\"fal fa-arrows-alt drag-advert-icon\" draggable=\"true\"></span></td>\n\t\t\t\t<td><img src=\"../api/1.0/customStores/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "storeId"), env.opts.autoescape);
output += "/image?fileName=advertImages/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"image"), env.opts.autoescape);
output += "\" class=\"advertTableImage\" data-toggle=\"image-dropdown";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\"></td>\n\t\t\t\t<td>\n\t\t\t\t\t<input type=\"date\" name=\"startDate\" value=\"";
output += runtime.suppressValue(env.getFilter("truncate").call(context, runtime.memberLookup((t_4),"start_date"),10,true,""), env.opts.autoescape);
output += "\" id=\"startDate_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t</td>\n\t\t\t\t<td>\n\t\t\t\t\t<input type=\"date\" name=\"endDate\" value=\"";
output += runtime.suppressValue(env.getFilter("truncate").call(context, runtime.memberLookup((t_4),"end_date"),10,true,""), env.opts.autoescape);
output += "\" id=\"endDate_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t</td>\n\t\t\t\t<td><input type=\"url\" name=\"link\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"link_url"), env.opts.autoescape);
output += "\" id=\"link_url_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\"></td>\n\t\t\t\t<td><input type=\"checkbox\" name=\"newTab\" ";
if(runtime.memberLookup((t_4),"new_tab")) {
output += "checked=\"checked\"";
;
}
output += " id=\"newTab_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\" class=\"newTabCheck\"></td>\n\t\t\t\t<td>\n\t\t\t\t\t<select id=\"region_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"selectedRegions");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("region", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_8),"productRegions")),0), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_8),"selected")) {
output += "selected";
;
}
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t<!--<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_8),"selected")) {
output += "selected";
;
}
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</option>-->\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</td>\n\t\t\t\t<td style=\"text-align:center;\">\n\t\t\t\t\t<input type=\"checkbox\" name=\"mobile_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\" ";
if(runtime.memberLookup((t_4),"mobile")) {
output += "checked=\"checked\"";
;
}
output += " id=\"mobile_";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\" class=\"mobileCheck\">\n\t\t\t\t</td>\n\t\t\t\t<td>\n\t\t\t\t\t<div class=\"buttons\">\n\t\t\t\t\t\t<button type=\"button\" class=\"button small success saveBtn\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\" title=\"Save Update\"><i class=\"fal fa-save\"></i></button>\n\n\t\t\t\t\t\t<button type=\"button\" class=\"button small alert deleteBtn\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"advertisement_item_id"), env.opts.autoescape);
output += "\" title=\"Delete\"><i class=\"fal fa-times-circle\"></i></button>\n\t\t\t\t\t</div>\n\n\t\t\t\t</td>\n\t\t\t</tr>\n\t\t";
;
}
}
frame = frame.pop();
output += "\n\t</tbody>\n</table>\n\n\n";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "images");
if(t_11) {var t_10 = t_11.length;
for(var t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9];
frame.set("image", t_12);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n<div class=\"dropdown-pane\" id=\"image-dropdown";
output += runtime.suppressValue(runtime.memberLookup((t_12),"advertisement_item_id"), env.opts.autoescape);
output += "\" data-dropdown data-auto-focus=\"true\" data-hover=\"true\" data-hover-pane=\"true\">\n  <img src=\"../api/1.0/customStores/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "storeId"), env.opts.autoescape);
output += "/image?fileName=advertImages/";
output += runtime.suppressValue(runtime.memberLookup((t_12),"image"), env.opts.autoescape);
output += "\" class=\"advertDropdownImage\">\n</div>\n";
;
}
}
frame = frame.pop();
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
