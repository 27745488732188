var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var grid_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\"><span class=\"fal fa-money-bill-alt\"/>&nbsp;Ingram Sale Reports</h4>\n\t\t<div class=\"actions\">\n\t\t\t<div class=\"generateNew\"><span class=\"fal fa-plus\" />&nbsp;Generate New Report</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var grid_oldRoot = grid_obj.root;
grid_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	grid_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var generateNewReport_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div id=\"generateNewReport\">\n\t\t<h5>Generate Ingram Sale Report</h5>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tBilling period:\n\t\t\t\t\t<div class=\"dateRange\"><input type=\"text\" id=\"dateFrom\" /> <span>to</span> <input type=\"text\" id=\"dateTo\" /></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var generateNewReport_oldRoot = generateNewReport_obj.root;
generateNewReport_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	generateNewReport_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"grid": new nunjucks.Template( {obj: grid_obj, type: "code"}, env),	"generateNewReport": new nunjucks.Template( {obj: generateNewReport_obj, type: "code"}, env),};
