var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var item_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n";
if(runtime.contextOrFrameLookup(context, frame, "heading")) {
output += "\n\t<div class=\"simple-menu-heading\">\n\t\t<div class=\"name\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "text"), env.opts.autoescape);
output += "</div>\n\t</div>\n";
;
}
else {
output += "\n\t<div class=\"simple-menu-element\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "set")) {
output += " <div class=\"check ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "selected")?"fas":"fal"), env.opts.autoescape);
output += " fa-circle\"></div> ";
;
}
output += "\n\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
output += " <div class=\"icon fal ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
output += "\"></div> ";
;
}
output += "\n\n\t\t<div class=\"name\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "text"), env.opts.autoescape);
output += "</div>\n\t</div>\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var item_oldRoot = item_obj.root;
item_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	item_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"item": new nunjucks.Template( {obj: item_obj, type: "code"}, env),};
