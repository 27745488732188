var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"empty\">\n\t<div class=\"title\">E&#x2011;books</div>\n\n\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "items")),"length") > 0) {
output += "\n\t\t<p>Choose an e&#x2011;book on the left to make changes to it, or view its status.</p>\n\t";
;
}
else {
output += "\n\t\t<p>No e&#x2011;books queued up currently.</p>\n\n\t\t<p>You can begin the upload process by dragging and dropping files anywhere into this area, or by clicking the \"Add Files\" button in the top corner.</p>\n\t";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
