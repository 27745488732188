var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var grid_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"header\">\n\t\t<h4 class=\"title left\"><span class=\"fal fa-money-bill-alt\"/>&nbsp;School Book Orders</h4>\n\t\t<div class=\"actions\">\n\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"billing")),"invoices")),"create")))) {
output += "\n\t\t\t\t<div class=\"downloadList\"><span class=\"fal fa-cloud-download-alt\" />&nbsp;Downloads</div>\n\t\t\t";
;
}
output += "\n\t\t\t<div class=\"actions\"><span class=\"fal fa-cog\" />&nbsp;Actions</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"ops-bar\">\n\t\t<div class=\"filters\"></div>\n\t\t<div class=\"counter\"></div>\n\t</div>\n\n\t<div class=\"grid\"></div>\n\n\t<div class=\"paging\"></div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var grid_oldRoot = grid_obj.root;
grid_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	grid_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var generateInvoice_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"><span class='fal fa-tag'/> Generate new order(s)</div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<b>\n\t\t\t\t\tThis will generate orders for schools which include all books and licenses not invocied to date.<br />\n\t\t\t\t\tThe order can be uploaded to Order to Cash for billing purposes.<br /><br />\n\t\t\t\t</b>\n\t\t\t</div>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label id=\"org-wrapper\">\n\t\t\t\t\tOrganisations *&nbsp;&nbsp; <span for=\"allSchools\"><input id=\"allSchools\" name=\"allSchools\" type=\"checkbox\" /> Select all</span>\n\t\t\t\t\t</label>\n\t\t\t\t<label>\n\t\t\t\t\t<select multiple name=\"schools\" data-placeholder=\"Select organisation(s)...\">\n\t\t\t\t\t</select>\n\t\t\t\t\t<select multiple name=\"groups\" data-placeholder=\"Select organisation(s)...\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tGroup Year *\n\t\t\t\t\t<select name=\"groupYears\" data-placeholder=\"Choose one...\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">Create </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var generateInvoice_oldRoot = generateInvoice_obj.root;
generateInvoice_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	generateInvoice_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var generateEstimation_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"><span class='fal fa-tag'/> Download upcoming invoice estimation</div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>This upcoming order estimation will contain all timed and perpetual licenses for the following school(s)...</label><br />\n\t\t\t\t<b class=\"schoolList\"></b>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button download success\">Download </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small cancel secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var generateEstimation_oldRoot = generateEstimation_obj.root;
generateEstimation_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	generateEstimation_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var bulkUpload_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"><span class='fal fa-upload'/> Bulk order upload</div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<b>\n\t\t\t\t\tThis will upload the most recently created order for all selected school(s) to Order to Cash.<br />\n\t\t\t\t\tOrders cannot be deleted once they have been uploaded.<br /><br />\n\t\t\t\t</b>\n\t\t\t</div>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label id=\"org-wrapper\">\n\t\t\t\t\tOrganisations *&nbsp;&nbsp; <span for=\"allSchools\"><input id=\"allSchools\" checked name=\"allSchools\" type=\"checkbox\" /> Select all</span>\n\t\t\t\t\t</label>\n\t\t\t\t<label>\n\t\t\t\t\t<select multiple name=\"schools\" data-placeholder=\"Select organisation(s)...\">\n\t\t\t\t\t</select>\n\t\t\t\t\t<select multiple name=\"groups\" data-placeholder=\"Select organisation(s)...\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<hr />\n\t\t\t\t<table id=\"bulk-order-table\">\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<th>School</th>\n\t\t\t\t\t\t<th>Reference</th>\n\t\t\t\t\t\t<th>Created</th>\n\t\t\t\t\t\t<th>Total</th>\n\t\t\t\t\t\t<th>Upload</th>\n\t\t\t\t\t</tr>\n\t\t\t\t\t<tbody id=\"orders-content\">\n\n\t\t\t\t\t</tbody>\n\t\t\t\t</table>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button upload success\">Bulk upload </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small cancel secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/>&nbsp;&nbsp;<span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var bulkUpload_oldRoot = bulkUpload_obj.root;
bulkUpload_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	bulkUpload_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"grid": new nunjucks.Template( {obj: grid_obj, type: "code"}, env),	"generateInvoice": new nunjucks.Template( {obj: generateInvoice_obj, type: "code"}, env),	"generateEstimation": new nunjucks.Template( {obj: generateEstimation_obj, type: "code"}, env),	"bulkUpload": new nunjucks.Template( {obj: bulkUpload_obj, type: "code"}, env),};
