module.exports={
	"*": {
		"name": "Default Role",
		"permissions": [
			"api.alchemies._.users._.post",
			"api.ebooks._.cover.get",
			"api.ebooks._.preview.get",
			"api.features.config.get",
			"api.features.jsonLists.get",
			"api.keys.*",
			"api.login.*",
			"api.news.latest.get",
			"api.payments.books.confirm.post",
			"api.payments.books.confirm.put",
			"api.payments.books.tokens.force.put",
			"api.payments.books.tokens.transfer.put",
			"api.payments.books.tokens.delete",
			"api.payments.delete",
			"api.products._.versions.*",
			"api.products.lists.getSchoolBookPriceList.get",
			"api.services.service.cover.get",
			"api.users.resetPassword.token.put",
			"api.users.exists.*",
			"api.users.get",
			"api.users.post",
			"api.users.put",
			"api.users._.resetPassword.*",
			"api.users._.unpersonate.get",
			"api.stats.get",
			"api.schools.post",
			"api.schools._.exists.get",
			"api.schools.adminSchools.get",
			"api.schools._.logo.get",
			"api.publishers._.logo.get",
			"api.publishers.post",
			"api.publishers.adminPublishers.get",
			"api.customStores._.image.get",
			"api.landingPages.image.get",

			"acacia.data.*",
			"acacia.users.user.grants.*",

			"help"
		]
	},

	"admin": {
		"name": "Superuser",
		"exclusivity": ["main"],
		"permissions": [
			"api.alchemies.unlocks.get",
			"api.billing.*",
			"api.bookLists.*",
			"api.customStores.*",
			"api.ebooks.*",
			"api.features.*",
			"api.ftpSources.*",
			"api.hardcopyBooks.*",
			"api.licenses.*",
			"api.logs.get",
			"api.mpdfs.post",
			"api.mpubs.*",
			"api.payments.books.tokens.invalid.get",
			"api.payments.books.tokens.put",
			"api.payments.books.tokens.bulkUpgrade.post",
			"api.payments.revokePurchaseItem.put",
			"api.payments.revokePurchase.put",
			"api.pdfs.post",
			"api.products.*",
			"api.publishers.*",
			"api.reports.*",
			"api.services.*",
			"api.schools.*",
			"api.schools.bookList.get",
			"api.stats.*",
			"api.users.*",
			"api.vouchers.get",
			"api.vouchers.post",
			"api.vouchers.delete",
			"api.vouchers.list.get",
			"api.wallet.*",
			"api.tags.*",
			"api.landingPages.*",

			"acacia.books.*",
			"acacia.customStores.*",
			"acacia.epubs.*",
			"acacia.licenses.*",
			"acacia.logs.*",
			"acacia.pdf.*",
			"acacia.publishers.*",
			"acacia.schools.*",
			"acacia.users.*",

			"books.changePrice",
			"books.discontinue",
			"books.recrypt",
			"books.remath",
			"books.signoff",
			"books.deleteAll",
			"books.recontinue",
			"books.forceMajor",
			"books.downloadDecrypted",
			"books.changeTags",
			"books.disableFtpImports",
			"billing.invoices.*",
			"!!billing.invoices.estimate",
			"billing.discounts",
			"billing.unlocks.manage",
			"billing.publisherSales",
			"billing.customStores",
			"billing.publisherSalesData",
			"billing.csPlusSales",
			"books.changePublisher",
			"empathylogin",
			"grants.all.*",
			"impersonate",
			"manuals.publisher",
			"manuals.school",
			"manage.customStores",
			"manage.vouchers",
			"manage.users",
			"manage.schools",
			"manage.publishers",
			"manage.advertisements",
			"manage.tags",
			"manage.landingPages",
			"payments.books.force",
			"payments.tokens.force",
			"payments.tokens.revoke",
			"payments.tokens.transfer",
			"payments.tokens.deletePending",
			"publishers.access.all",
			"publishers.comments",
			"publishers.getChosenBooks",
			"publishers.getBoughtBooks",
			"publishers.manage",
			"publishers.sales",
			"reports.*",
			"schools.*",
			"support.epubs.*",
			"tokens.upgrade",
			"tranches.revoke",
			"users.*",
			"publishers.ftpsources",

			"!help"
		]
	},

	"itsiSenior": {
		"name": "ITSI Senior",
		"permissions": [
			"+users.add.itsiSuper",
			"books.recrypt"
		]
	},

	"itsiPublishing": {
		"name": "ITSI Publishing",
		"permissions": [
			"api.customStores.list.get",
			"api.landingPages.get",
			"api.licenses.get",
			"api.publishers.groups.*",
			"api.billing.discounts.*",

			"billing.discounts",
			"books.changePublisher"
		]
	},

	"itsiSuper": {
		"name": "ITSI Manager",
		"exclusivity": ["main"],
		"permissions": [
			"api.ebooks.*",
			"api.epubs.post",
			"api.customStores.*",
			"api.logs.get",
			"api.licenses.get",
			"api.licenses.put",
			"api.licenses.post",
			"api.licenses.delete",
			"api.licenses.bulk.*",
			"api.licenses.license.changelog.get",
			"api.licenses.download.get",
			"api.licenses.downloadBulkUpdate.get",
			"api.mpubs.post",
			"api.mpdfs.post",
			"api.payments.books.tokens.invalid.get",
			"api.payments.books.tokens.post",
			"api.payments.books.tokens.put",
			"api.payments.books.tokens.bulkUpgrade.post",
			"api.pdfs.post",
			"api.products._.changelog.get",
			"api.products._.discontinue.put",
			"api.products._.recontinue.put",
			"api.products.encryption.get",
			"api.products.history.get",
			"api.products.lists.history.get",
			"api.publishers._.changelog.get",
			"api.publishers.get",
			"api.publishers.chosenBooks.get",
			"api.publishers.boughtBooks.get",
			"api.publishers.delete",
			"api.publishers.download.get",
			"api.publishers.post",
			"api.publishers.put",
			"api.publishers._.keys.has.get",
			"api.publishers._.metadata.get",
			"api.publishers._.updateApproval.post",
			"api.reports.*",
			"api.schools._.changelog.get",
			"api.schools._.downloadchoices.get",
			"api.schools.bookList.get",
			"api.schools.bulk.books.put",
			"api.schools.choices.get",
			"api.schools.choosable.get",
			"api.schools.choices.manage.get",
			"api.schools.choices.manage.post",
			"api.schools.choices.manage.delete",
			"api.schools.choices.manage.nonPending.get",
			"api.schools.choices.manage.bulkActions.deleteSchoolChoices.get",
			"api.schools.download.get",
			"api.schools.books.get",
			"api.schools.get",
			"api.schools.post",
			"api.schools.put",
			"api.schools._.updateApproval.post",
			"api.schools.granted.get",
			"api.schools.groups.*",
			"api.schools.requests.get",
			"api.schools.userHasPurchases.get",
			"api.school.schoolTokensTotal.*",
			"api.services.*",
			"api.users.*",
			"api.users._.impersonate.get",
			"api.vouchers.get",
			"api.vouchers.post",
			"api.vouchers.delete",
			"api.vouchers.list.get",
			"api.bookLists.*",
			"api.wallet.*",
			"api.payments.revokePurchaseItem.put",
			"api.payments.revokePurchase.put",
			"api.ftpSources.*",
			"api.tags.*",
			"api.landingPages.*",

			"acacia.customStores.*",
			"acacia.data.*",
			"acacia.epubs.create._.get",
			"acacia.epubs.files.*",
			"acacia.epubs.parseXml.post",
			"acacia.epubs.toc.*",
			"acacia.epubs.verifyPemPassword.post",
			"acacia.logs.*",
			"acacia.licenses.*",
			"acacia.publishers.*",
			"acacia.schools.*",
			"acacia.pdf._.get",

			"books.discontinue",
			"books.recontinue",
			"books.signoff",
			"books.unsignoff",
			"books.downloadDecrypted",
			"books.changeTags",
			"books.disableFtpImports",
			"empathylogin",
			"grants.all.*",
			"impersonate",
			"manage.users",
			"manage.schools",
			"manage.publishers",
			"manage.advertisements",
			"manage.tags",
			"manage.landingPages",
			"manage.customStores",
			"manage.vouchers",
			"manuals.publisher",
			"manuals.school",
			"publishers.access.all",
			"publishers.comments",
			"publishers.getChosenBooks",
			"publishers.getBoughtBooks",
			"publishers.ftpsources",
			"reports.*",
			"schools.books.private",
			"schools.comments",
			"schools.get",
			"schools.manageChoices",
			"schools.bookTokenForwardingDNS",
			"schools.trancheBuyToggle",
			"tokens.upgrade",
			"tranches.revoke",
			"users.get.*",
			"users.add.*",
			"!!users.add.admin",
			"!users.add.itsiSuper",
			"users.delete.*",
			"!!users.delete.admin",
			"!!users.delete.itsiSuper",
			"users.update.*",
			"!!users.update.admin",
			"!!users.passwordChangeOverride",

			"!help"
		]
	},

	"support": {
		"name": "ITSI Support",
		"exclusivity": ["main"],
		"permissions": [
			"api.ebooks.get",
			"api.ebooks._.get",
			"api.ebooks._.download.get",
			"api.ebooks.lists.csv.get",
			"api.ebooks.lists.prices.get",
			"api.logs.get",
			"api.products._.changelog.get",
			"api.publishers._.changelog.get",
			"api.publishers.get",
			"api.publishers.put",
			"api.publishers.boughtBooks.get",
			"api.publishers.chosenBooks.get",
			"api.publishers.download.get",
			"api.publishers._.metadata.get",
			"api.schools._.changelog.get",
			"api.schools.get",
			"api.schools.put",
			"api.schools.choosable.get",
			"api.schools.download.get",
			"api.schools._.downloadchoices.get",
			"api.schools.userHasPurchases.get",
			"api.school.schoolTokensTotal.*",
			"api.users.get",
			"api.users.lists.users.get",
			"api.users._.impersonate.get",

			"acacia.data.*",
			"acacia.epubs.*",
			"acacia.licenses.*",
			"acacia.pdf.*",

			"books.all",
			"empathylogin",
			"grants.all.publishers",
			"impersonate",
			"manage.users",
			"manage.schools",
			"manage.publishers",
			"manuals.publisher",
			"manuals.school",
			"publishers.comments",
			"publishers.getChosenBooks",
			"publishers.getBoughtBooks",
			"schools.books.private",
			"schools.comments",
			"schools.get",
			"schools.trancheBuyToggle",
			"users.get.*",
			"users.passwordChangeOverride",
			"users.all",
			"users.grantOverride",

			"!help"
		]
	},

	"helpdesk": {
		"name": "ITSI Helpdesk",
		"exclusivity": ["main"],
		"permissions": [
			"api.bookLists.*",
			"api.customStores.*",
			"api.ebooks.get",
			"api.ebooks._.get",
			"api.ebooks._.download.get",
			"api.ebooks.lists.csv.get",
			"api.ebooks.lists.prices.get",
			"api.landingPages.*",
			"api.licenses.*",
			"api.logs.*",
			"api.payments.books.tokens.invalid.get",
			"api.payments.books.tokens.bulkUpgrade.post",
			"api.payments.books.tokens.put",
			"api.reports.*",
			"api.schools.*",
			"!api.schools._.books.confirm.put",
			"api.schools.put",
			"!api.schools.post",
			"!api.schools.delete",
			"api.tags.*",
			"api.users._.impersonate.get",
			"api.users.lists.*",
			"api.vouchers.*",

			"books.all",
			"empathylogin",
			"grants.all.*",
			"impersonate",
			"manage.users",
			"manage.schools",
			"manage.customStores",
			"manage.advertisements",
			"manage.tags",
			"manage.landingPages",
			"manage.vouchers",
			"reports.view",
			"reports.schools.*",
			"reports.books.*",
			"reports.publishers.*",
			"reports.ftpSources.*",
			"schools.manageChoices",
			"tokens.upgrade",
			"users.get.*",
			"users.passwordChangeOverride",
			"users.update.*",
			"!users.update.admin",
			"!users.update.itsiSuper",
			"!users.update.support",
			"!users.update.helpdesk",

			"!help"
		]
	},

	"marketing": {
		"name": "Marketing",
		"permissions": [
			"api.customStores.*",
			"api.landingPages.*",
			"api.tags.*",

			"acacia.customStores.*",

			"empathylogin",
			"manage.advertisements",
			"manage.customStores",
			"manage.tags",
			"manage.landingPages"
		]
	},

	"financeDepartment": {
		"name": "Helpdesk Finance",
		"exclusivity": ["billing"],
		"permissions": [
			"api.billing.unlocks.get",
			"api.billing.unlocks.list.csv.get",
			"api.billing.unlocks.uploadManualExport.post",
			"api.reports.billing.allUnlockSync.get",
			"api.schools.download.get",
			"api.users.lists.*",

			"empathylogin",
			"billing.unlocks.manage",
			"grants.all.*",
			"users.get.*"
		]
	},

	"billingFinance": {
		"name": "Billing Finance",
		"exclusivity": ["billing"],
		"permissions": [
			"api.billing.*",
			"api.customStores.list.get",
			"api.licenses.get",
			"api.schools.download.get",
			"api.reports.publishers.sales.get",
			"api.reports.publishers.sales.export.get",
			"api.reports.billing.allUnlockSync.get",
			"api.users.lists.*",

			"empathylogin",
			"billing.*",
			"publishers.sales",
			"grants.all.*",
			"users.get.*"
		]
	},

	"itsi": {
		"name": "ITSI Viewer",
		"exclusivity": ["main"],
		"permissions": [
			"api.ebooks.get",
			"api.logs.get",
			"api.products._.changelog.get",
			"api.publishers.boughtBooks.get",
			"api.publishers.chosenBooks.get",
			"api.schools.choosable.get",
			"api.schools.get",
			"api.users.get",
			"api.users.lists.*",

			"empathylogin",
			"grants.all.*",
			"manage.users",
			"manuals.publisher",
			"manuals.school",
			"publishers.getChosenBooks",
			"publishers.getBoughtBooks",
			"schools.books.private",
			"schools.get",
			"users.get.*",
			"!users.add.*"
		]
	},

	"itsiApprover": {
		"name": "ITSI Approver",
		"permissions": [
			"api.schools.get",
			"api.schools._.updateApproval.post",
			"api.publishers.get",
			"api.publishers._.updateApproval.post",

			"empathylogin",
			"grants.all.*",
			"manage.publishers",
			"manage.schools"
		]
	},

	"user": {
		"name": "Registered User",
		"permissions": [

		]
	},

	"publisher": {
		"name": "Publisher User",
		"exclusivity": ["creditProvider8", "forceActivator8", "confirmer8", "revoker8", "priceChange8"],
		"hasGrants": true,
		"permissions": [
			"api.ebooks._.exists.get",
			"api.ebooks._.preview.get",
			"api.ebooks._.validationResult.get",
			"api.ebooks._.get",
			"api.ebooks.get",
			"api.ebooks.lists.*",
			"api.ebooks.metadata.get",
			"api.ebooks.metadata.put",
			"api.ebooks.post",
			"api.ebooks.put",
			"api.licenses.license.changelog.get",
			"api.licenses.download.get",
			"api.products._.changelog.get",
			"api.products._.retryEncryption.put",
			"api.products.encryption.put",
			"api.products.encryption.get",
			"api.products.history.get",
			"api.products.lists.history.get",
			"api.publishers.boughtBooks.get",
			"api.publishers.chosenBooks.get",
			"api.publishers._.keys.has.get",
			"api.publishers._.metadata.get",
			"api.services.get",

			"acacia.epubs.*",
			"acacia.licenses.*",
			"acacia.pdf.*",

			"empathylogin",
			"grants.main.publishers",
			"manuals.publisher",
			"support.epubs.*"
		]
	},

	"publisherAdmin": {
		"name": "Publisher Admin",
		"exclusivity": ["creditProvider7", "forceActivator7", "confirmer7", "revoker7", "priceChange7"],
		"hasGrants": true,
		"permissions": [
			"api.ebooks._.download.get",
			"api.ebooks._.exists.get",
			"api.ebooks._.preview.get",
			"api.ebooks._.validationResult.get",
			"api.ebooks._.get",
			"api.ebooks.get",
			"api.ebooks.lists.*",
			"api.ebooks.metadata.get",
			"api.ebooks.metadata.put",
			"api.ebooks.post",
			"api.ebooks.put",
			"api.ebooks.signoff.bulk.get",
			"api.ebooks.delete",
			"api.ebooks.updatePreviewPages.put",
			"api.licenses.get",
			"api.licenses.put",
			"api.licenses.post",
			"api.licenses.delete",
			"api.licenses.license.changelog.get",
			"api.licenses.download.get",
			"api.licenses.bulk.*",
			"api.products._.changelog.get",
			"api.products._.delete",
			"api.products._.discontinue.put",
			"api.products._.recontinue.put",
			"api.products._.retryEncryption.put",
			"api.products._.signoff.put",
			"api.products.encryption.put",
			"api.products.encryption.get",
			"api.products.history.get",
			"api.products.lists.history.get",
			"api.products.updatePrice.put",
			"api.publishers._.get",
			"api.publishers.put",
			"api.publishers.account.*",
			"api.publishers.chosenBooks.get",
			"api.publishers.boughtBooks.get",
			"api.publishers._.keys.has.get",
			"api.publishers._.logo.post",
			"api.publishers._.metadata.get",
			"api.users.*",
			"api.services.get",
			"api.services.post",
			"api.services.put",
			"api.services.delete",
			"api.tags.*",
			"api.landingPages.*",

			"acacia.epubs.*",
			"acacia.licenses.*",
			"acacia.pdf.*",

			"books.changePrice",
			"books.discontinue",
			"books.recontinue",
			"books.signoff",
			"empathylogin",
			"grants.main.publisherAdmins",
			"manage.users",
			"manuals.publisher",
			"support.epubs.*",
			"users.add.publisher",
			"users.add.publisherAdmin",
			"users.delete.publisher",
			"users.delete.publisherAdmin",
			"users.get.publisher",
			"users.get.publisherAdmin",
			"users.update.publisher",
			"users.update.publisherAdmin"
		]
	},

	"publisherGroup": {
		"name": "Publisher Group User",
		"exclusivity": ["creditProvider6", "forceActivator6", "confirmer6", "revoker6", "priceChange6"],
		"hasGrants": true,
		"permissions": [
			"api.ebooks._.exists.get",
			"api.ebooks._.preview.get",
			"api.ebooks._.validationResult.get",
			"api.ebooks._.get",
			"api.ebooks.get",
			"api.ebooks.lists.*",
			"api.ebooks.metadata.get",
			"api.ebooks.metadata.put",
			"api.ebooks.post",
			"api.ebooks.put",
			"api.licenses.license.changelog.get",
			"api.licenses.download.get",
			"api.products._.changelog.get",
			"api.products._.retryEncryption.put",
			"api.products.encryption.put",
			"api.products.encryption.get",
			"api.products.history.get",
			"api.products.lists.history.get",
			"api.publishers.boughtBooks.get",
			"api.publishers.chosenBooks.get",
			"api.publishers._.keys.has.get",
			"api.publishers._.metadata.get",
			"api.services.get",

			"acacia.epubs.*",
			"acacia.pdf.*",
			"acacia.licenses.*",

			"empathylogin",
			"grants.main.publisherGroups",
			"manuals.publisher",
			"support.epubs.*"
		]
	},

	"publisherGroupAdmin": {
		"name": "Publisher Group Admin",
		"exclusivity": ["creditProvider5", "forceActivator5", "confirmer5", "revoker5", "priceChange5"],
		"hasGrants": true,
		"permissions": [
			"api.ebooks._.download.get",
			"api.ebooks._.exists.get",
			"api.ebooks._.preview.get",
			"api.ebooks._.validationResult.get",
			"api.ebooks._.get",
			"api.ebooks.get",
			"api.ebooks.lists.*",
			"api.licenses.put",
			"api.licenses.post",
			"api.licenses.delete",
			"api.licenses.license.changelog.get",
			"api.licenses.download.get",
			"api.licenses.bulk.*",
			"api.ebooks.metadata.get",
			"api.ebooks.metadata.put",
			"api.ebooks.post",
			"api.ebooks.put",
			"api.ebooks.signoff.bulk.get",
			"api.ebooks.delete",
			"api.products._.changelog.get",
			"api.products._.delete",
			"api.products._.discontinue.put",
			"api.products._.recontinue.put",
			"api.products._.retryEncryption.put",
			"api.products._.signoff.put",
			"api.products.encryption.put",
			"api.products.encryption.get",
			"api.products.history.get",
			"api.products.lists.history.get",
			"api.products.updatePrice.put",
			"api.publishers._.get",
			"api.publishers.put",
			"api.publishers.chosenBooks.get",
			"api.publishers.boughtBooks.get",
			"api.publishers._.keys.has.get",
			"api.publishers._.logo.post",
			"api.publishers._.metadata.get",
			"api.reports.publishers.sales.get",
			"api.reports.publishers.sales.export.get",
			"api.users.*",
			"api.services.get",
			"api.services.post",
			"api.services.put",
			"api.services.delete",
			"api.tags.*",
			"api.landingPages.*",

			"acacia.epubs.*",
			"acacia.licenses.*",
			"acacia.pdf.*",

			"books.changePrice",
			"books.discontinue",
			"books.recontinue",
			"books.signoff",
			"empathylogin",
			"grants.main.publisherGroupAdmins",
			"manage.users",
			"manuals.publisher",
			"support.epubs.*",
			"users.add.publisher",
			"users.add.publisherAdmin",
			"users.add.publisherGroup",
			"users.add.publisherGroupAdmin",
			"users.delete.publisher",
			"users.delete.publisherAdmin",
			"users.delete.publisherGroup",
			"users.delete.publisherGroupAdmin",
			"users.get.publisher",
			"users.get.publisherAdmin",
			"users.get.publisherGroup",
			"users.get.publisherGroupAdmin",
			"users.update.publisher",
			"users.update.publisherAdmin",
			"users.update.publisherGroup",
			"users.update.publisherGroupAdmin"
		]
	},

	"school": {
		"name": "School User",
		"exclusivity": ["creditProvider4", "forceActivator4", "confirmer4", "revoker4", "priceChange4"],
		"hasGrants": true,
		"permissions": [
			"api.ebooks.downloadable.get",
			"api.schools.bulk.books.put",
			"!api.schools.bookList.get",
			"api.schools.choosable.get",
			"api.schools.choices.get",
			"api.schools.get",

			"acacia.licenses.*",

			"empathylogin",
			"grants.main.schools",
			"manuals.school"
		]
	},

	"schoolAdmin": {
		"name": "School Admin",
		"exclusivity": ["creditProvider3", "forceActivator3", "confirmer3", "revoker3", "priceChange3"],
		"hasGrants": true,
		"permissions": [
			"api.billing.invoices.get",
			"api.billing.invoices.download.estimate.get",
			"api.billing.invoices.download.invoice.uid.get",
			"api.billing.invoices.o2c.downloadInvoice.uid.get",
			"api.ebooks._.get",
			"api.ebooks.downloadable.get",
			"api.schools.account.get",
			"api.schools.account._.put",
			"!api.schools.bookList.get",
			"api.schools.bulk.books.put",
			"api.schools._.books.confirm.put",
			"api.schools._.books.confirmLicense.put",
			"api.schools._.books.reject.put",
			"api.schools.choosable.get",
			"api.schools.choices.get",
			"api.schools.choices.manage.get",
			"api.schools.choices.manage.nonPending.get",
			"api.schools._.get",
			"!!api.schools.groups.get",
			"api.schools.requests.get",
			"api.schools.put",
			"api.schools._.logo.post",
			"api.schools.granted.get",
			"api.users.*",
			"api.bookLists.*",
			"api.publishers.*",
			"!!api.publishers.groups.get",

			"acacia.licenses.*",
			"acacia.licenses.license.chosenLicenses.get",

			"billing.invoices.manage",
			"billing.invoices.estimate",
			"empathylogin",
			"grants.main.schoolAdmins",
			"manage.users",
			"manuals.school",
			"users.add.school",
			"users.add.schoolAdmin",
			"users.get.school",
			"users.get.schoolAdmin",
			"users.delete.school",
			"users.delete.schoolAdmin",
			"users.update.school",
			"users.update.schoolAdmin"
		]
	},

	"schoolGroup": {
		"name": "School Group User",
		"exclusivity": ["creditProvider2", "forceActivator2", "confirmer2", "revoker2", "priceChange2"],
		"hasGrants": true,
		"permissions": [
			"!api.schools.bookList.get",
			"api.schools.bulk.books.put",
			"api.schools.choices.get",
			"api.schools.choosable.get",
			"api.schools._.get",

			"empathylogin",
			"grants.main.schoolGroups",
			"manuals.school"
		]
	},

	"schoolGroupAdmin": {
		"name": "School Group Admin",
		"exclusivity": ["creditProvider", "forceActivator", "confirmer", "revoker", "priceChange"],
		"hasGrants": true,
		"permissions": [
			"api.ebooks._.get",
			"!api.schools.bookList.get",
			"api.schools.bulk.books.put",
			"api.schools._.books.confirm.put",
			"api.schools._.books.reject.put",
			"api.schools.choosable.get",
			"api.schools._.get",
			"api.schools.choices.get",
			"api.schools.choices.manage.get",
			"api.schools.choices.manage.nonPending.get",
			"api.schools.requests.get",
			"api.schools.put",
			"api.schools._.logo.post",
			"api.schools.granted.get",
			"api.users.*",
			"api.bookLists.*",

			"empathylogin",
			"grants.main.schoolGroupAdmins",
			"manage.users",
			"manuals.school",
			"users.add.school",
			"users.add.schoolAdmin",
			"users.add.schoolGroup",
			"users.add.schoolGroupAdmin",
			"users.delete.school",
			"users.delete.schoolAdmin",
			"users.delete.schoolGroup",
			"users.delete.schoolGroupAdmin",
			"users.get.school",
			"users.get.schoolAdmin",
			"users.get.schoolGroup",
			"users.get.schoolGroupAdmin",
			"users.update.school",
			"users.update.schoolAdmin",
			"users.update.schoolGroup",
			"users.update.schoolGroupAdmin"
		]
	},

	"hybridApi": {
		"name": "Hybrid API Account",
		"permissions": [
			"api.ebooks.get",
			"api.schools._.chosenBooks.get",

			"empathylogin"
		]
	},

	"syncApi": {
		"name": "Book Sync API",
		"permissions": [
			"api.schools.books.get"
		]
	},

	"internalApi": {
		"name": "Internal API",
		"permissions": [
			"api.schools.get",
			"api.schools.academicYears.get",
			"api.analytics.unlocks.get",

			"empathylogin"
		]
	},

	"creditProvider": {
		"name": "Credit provider",
		"exclusivity": ["creditProvider", "creditProvider2", "creditProvider3", "creditProvider4", "creditProvider5", "creditProvider6", "creditProvider7", "creditProvider8"],
		"permissions": [
			"api.wallet.*",
			"api.payments.revokePurchaseItem.put",
			"api.payments.revokePurchase.put",
			"users.*",

			"tokens.upgrade",
			"users.all"
		]
	},

	"optimiApi": {
		"name": "Optimi API",
		"permissions": [
			"api.reports.billing.onlinePurchases.get",

			"empathylogin",
			"reports.billing.onlinePurchases"
		]
	},

	"forceActivator": {
		"name": "Force Activator",
		"exclusivity": ["forceActivator", "forceActivator2", "forceActivator3", "forceActivator4", "forceActivator5", "forceActivator6", "forceActivator7", "forceActivator8"],
		"permissions": [

		]
	},

	"confirmer": {
		"name": "Payment Confirmer",
		"exclusivity": ["confirmer", "confirmer2", "confirmer3", "confirmer4", "confirmer5", "confirmer6", "confirmer7", "confirmer8"],
		"permissions": [

		]
	},

	"revoker": {
		"name": "Token Revoker",
		"exclusivity": ["revoker", "revoker2", "revoker3", "revoker4", "revoker5", "revoker6", "revoker7", "revoker8"],
		"permissions": [

		]
	},

	"ebookManager": {
		"name": "Ebook Manager",
		"exclusivity": ["priceChange", "priceChange2", "priceChange3", "priceChange4", "priceChange5", "priceChange6", "priceChange7", "priceChange8"],
		"permissions": [
			"api.ebooks.*",
			"api.products.updatePrice.put",
			"api.products.product.*",

			"acacia.books.*",

			"books.changePrice",
			"books.downloadDecrypted"
		]
	},

	"hardcopyBookManager": {
		"name": "Hardcopy Book Manager",
		"exclusivity": [],
		"permissions": [
			"api.hardcopyBooks.bulk.*",
			"api.hardcopyBooks.get",
			"api.hardcopyBooks.post",
			"api.hardcopyBooks.put",
			"api.hardcopyBooks.del",
			"api.hardcopyBooks.hardcopyBook.*",
			"api.hardcopyBooks.download.*",

			"empathylogin"
		]
	},

	"hardcopyBookQuotes": {
		"name": "Hardcopy Book Quoter",
		"exclusivity": [],
		"permissions": [
			"api.hardcopyBooks.quotes.*",

			"empathylogin"
		]
	}
}