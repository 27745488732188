module.exports={
	"books": {
		"added": "",
		"updated": "",
		"deleted": "",
		"encryption": {
			"queued": "",
			"started": "",
			"success": "",
			"failure": ""
		},
		"math": {
			"started": "",
			"success": "",
			"failure": ""
		},
		"priceChange": "",
		"remote": {
			"failure": ""
		}
	},

	"users": {
		"deleted": ""
	},

	"schools": {
		"approved": "",
		"added": "",
		"choices": {
			"added": "",
			"confirmed": "",
			"removed": ""
		}
	},

	"publishers": {
		"approved": "",
		"added": "",
		"ftpsourceerror": ""
	},

	"vouchers": {
		"send": ""
	}
}