var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 89, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-user-plus'/> Create New User"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing User: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "userName"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "inlineMessage")) {
output += "\n\t\t\t<p class=\"inline\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "inlineMessage")), env.opts.autoescape);
output += "</p>\n\t\t";
;
}
output += "\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"enabled\" ";
output += runtime.suppressValue(((lineno = 10, colno = 62, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, [])) || runtime.contextOrFrameLookup(context, frame, "enabled")?"checked":""), env.opts.autoescape);
output += ">\n\t\t\t\t\tAccount Approved\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"disabled\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "disabled")?"checked":""), env.opts.autoescape);
output += ">\n\t\t\t\t\tAccount Disabled\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tAccount Types<br/>\n\t\t\t\t\t";
if(!runtime.memberLookup(((lineno = 25, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "rolesList"), "rolesList", context, []))),"length")) {
output += "\n\t\t\t\t\t\t<!-- No permission to add any roles; just show the current roles -->\n\t\t\t\t\t\t<select name=\"roles\" disabled>\n\t\t\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "roles");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("role", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "\" selected>";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</select>\n\t\t\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t\t<select name=\"roles\" data-placeholder=\"Account types\" multiple><option value=\"\"></option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_7 = (lineno = 34, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "rolesList"), "rolesList", context, []));
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("role", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_8),"id"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue(((env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "roles"),runtime.memberLookup((t_8),"id")))?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</select>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t";
frame = frame.push();
var t_11 = runtime.contextOrFrameLookup(context, frame, "grantList");
if(t_11) {var t_9;
if(runtime.isArray(t_11)) {
var t_10 = t_11.length;
for(t_9=0; t_9 < t_11.length; t_9++) {
var t_12 = t_11[t_9][0]
frame.set("key", t_11[t_9][0]);
var t_13 = t_11[t_9][1]
frame.set("grant", t_11[t_9][1]);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += "-all ";
output += runtime.suppressValue(((lineno = 43, colno = 74, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasFullGrantRole"), "hasFullGrantRole", context, [t_12]))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\t<div class=\"textual\">";
output += runtime.suppressValue(runtime.memberLookup((t_13),"name"), env.opts.autoescape);
output += ": <strong>Full Access</strong></div>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t";
if((lineno = 49, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isGrantPermitted"), "isGrantPermitted", context, [t_12]))) {
output += "\n\t\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((lineno = 50, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasGrantRole"), "hasGrantRole", context, [t_12])) && !(lineno = 50, colno = 97, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasFullGrantRole"), "hasFullGrantRole", context, [t_12]))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<span class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += "-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_13),"name"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t<select name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += "\" multiple class=\"select-grant\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
else {
if((lineno = 56, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasGrantRole"), "hasGrantRole", context, [t_12]))) {
output += "\n\t\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<span class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += "-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_13),"name"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t<select name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_13),"grant"), env.opts.autoescape);
output += "\" multiple class=\"select-grant\" disabled/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
;
}
output += "\n\t\t\t";
;
}
} else {
t_9 = -1;
var t_10 = runtime.keys(t_11).length;
for(var t_14 in t_11) {
t_9++;
var t_15 = t_11[t_14];
frame.set("key", t_14);
frame.set("grant", t_15);
frame.set("loop.index", t_9 + 1);
frame.set("loop.index0", t_9);
frame.set("loop.revindex", t_10 - t_9);
frame.set("loop.revindex0", t_10 - t_9 - 1);
frame.set("loop.first", t_9 === 0);
frame.set("loop.last", t_9 === t_10 - 1);
frame.set("loop.length", t_10);
output += "\n\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += "-all ";
output += runtime.suppressValue(((lineno = 43, colno = 74, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasFullGrantRole"), "hasFullGrantRole", context, [t_14]))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\t<div class=\"textual\">";
output += runtime.suppressValue(runtime.memberLookup((t_15),"name"), env.opts.autoescape);
output += ": <strong>Full Access</strong></div>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t";
if((lineno = 49, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isGrantPermitted"), "isGrantPermitted", context, [t_14]))) {
output += "\n\t\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(((lineno = 50, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasGrantRole"), "hasGrantRole", context, [t_14])) && !(lineno = 50, colno = 97, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasFullGrantRole"), "hasFullGrantRole", context, [t_14]))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<span class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += "-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_15),"name"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t<select name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += "\" multiple class=\"select-grant\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
else {
if((lineno = 56, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "hasGrantRole"), "hasGrantRole", context, [t_14]))) {
output += "\n\t\t\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<span class=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += "-label\">";
output += runtime.suppressValue(runtime.memberLookup((t_15),"name"), env.opts.autoescape);
output += "</span>\n\t\t\t\t\t\t\t<select name=\"";
output += runtime.suppressValue(runtime.memberLookup((t_15),"grant"), env.opts.autoescape);
output += "\" multiple class=\"select-grant\" disabled/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t";
;
}
;
}
output += "\n\t\t\t";
;
}
}
}
frame = frame.pop();
output += "\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tUsername\n\t\t\t\t\t<div class=\"relative\">\n\t\t\t\t\t\t<input type=\"text\" name=\"username\" placeholder=\"Username\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "username"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((!(lineno = 70, colno = 105, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t\t<div class=\"input-overlay username-spinner hide\" title=\"Checking existence...\"><span class=\"spinner-icon spinner-small\"/></div>\n\t\t\t\t\t</div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tFirst Name\n\t\t\t\t\t<input type=\"text\" name=\"firstname\" placeholder=\"First name\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "firstname")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSurname\n\t\t\t\t\t<input type=\"text\" name=\"surname\" placeholder=\"Surname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "surname")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tEmail\n\t\t\t\t\t<input type=\"text\" name=\"email\" placeholder=\"Email address\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "email")), env.opts.autoescape);
output += "\">\n\t\t\t\t\t<div class=\"input-overlay email-spinner hide\" title=\"Checking existence...\"><span class=\"spinner-icon spinner-small\"/></div>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSchool\n\t\t\t\t\t<select name=\"school\" data-placeholder=\"School\" class=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "schoolDisabled"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t\t<option value=\"\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_18 = runtime.contextOrFrameLookup(context, frame, "schoolList");
if(t_18) {var t_17 = t_18.length;
for(var t_16=0; t_16 < t_18.length; t_16++) {
var t_19 = t_18[t_16];
frame.set("s", t_19);
frame.set("loop.index", t_16 + 1);
frame.set("loop.index0", t_16);
frame.set("loop.revindex", t_17 - t_16);
frame.set("loop.revindex0", t_17 - t_16 - 1);
frame.set("loop.first", t_16 === 0);
frame.set("loop.last", t_16 === t_17 - 1);
frame.set("loop.length", t_17);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_19),"school_id"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((t_19),"school_id") == runtime.contextOrFrameLookup(context, frame, "school")?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_19),"fullname"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tVat Number\n\t\t\t\t\t<input type=\"text\" name=\"vatNumber\" placeholder=\"Vat number\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "vat_number")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tTimezone\n\t\t\t\t\t<select name=\"timezone\" data-placeholder=\"Timezone\">\n\t\t\t\t\t\t<option value=\"\">\n\t\t\t\t\t\t";
frame = frame.push();
var t_22 = runtime.contextOrFrameLookup(context, frame, "timezoneList");
if(t_22) {var t_21 = t_22.length;
for(var t_20=0; t_20 < t_22.length; t_20++) {
var t_23 = t_22[t_20];
frame.set("zone", t_23);
frame.set("loop.index", t_20 + 1);
frame.set("loop.index0", t_20);
frame.set("loop.revindex", t_21 - t_20);
frame.set("loop.revindex0", t_21 - t_20 - 1);
frame.set("loop.first", t_20 === 0);
frame.set("loop.last", t_20 === t_21 - 1);
frame.set("loop.length", t_21);
output += "\n\t\t\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_23),"name"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((t_23),"name") == runtime.contextOrFrameLookup(context, frame, "timezone")?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_23),"name"), env.opts.autoescape);
output += " (";
output += runtime.suppressValue(runtime.memberLookup((t_23),"offset"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((t_23),"location"), env.opts.autoescape);
output += ")</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"forcePasswordChange\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "force_password_change")?"checked":""), env.opts.autoescape);
output += ">\n\t\t\t\t\tForce this user to change their password the next time they log in\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t";
if(!(lineno = 136, colno = 16, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += "\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"newPassword\">\n\t\t\t\t\tSet New Password\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((!(lineno = 145, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"hide newPass":""), env.opts.autoescape);
output += "\">\n\t\t\t\t<label>\n\t\t\t\t\tPassword\n\t\t\t\t\t<input type=\"password\" name=\"password\" placeholder=\"Password\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((!(lineno = 152, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?"hide newPass":""), env.opts.autoescape);
output += "\">\n\t\t\t\t<label>\n\t\t\t\t\tConfirm Password\n\t\t\t\t\t<input type=\"password\" name=\"password2\" placeholder=\"Confirm password\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h4>Store Details</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tContact Number\n\t\t\t\t\t<input type=\"text\" readonly value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "contact_number")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tInstitution Type\n\t\t\t\t\t<input type=\"text\" readonly value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "institution_type")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tInstitution Name\n\t\t\t\t\t<input type=\"text\" readonly value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "institution_name")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tInstitution Level\n\t\t\t\t\t<input type=\"text\" readonly value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "institution_level")), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<input type=\"checkbox\" readonly disabled ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "offers")?"checked":""), env.opts.autoescape);
output += "> Opted in to receiving offers and newsletters?\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h4>Emails</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"encryptionFailEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"encryptionFailEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>Encryption failure</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"remoteSyncFailEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"remoteSyncFailEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>Remote book sync failure</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"chosenPriceChangeEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"chosenPriceChangeEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>Book price changed</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"schoolChoiceAddedEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"schoolChoiceAddedEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>School choice added</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"schoolChoiceConfirmedEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"schoolChoiceConfirmedEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>School choice confirmed</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"schoolChoiceRemovedEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"schoolChoiceRemovedEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>School choice removed</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"majorVersionEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"majorVersionEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>New major book version</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-4 columns\">\n\t\t\t\t<input name=\"minorPrivateVersionEmail\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "flags")),"minorPrivateVersionEmail")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>New minor private book version</label>\n\t\t\t</div>\n\t\t</div>\n\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t";
if((lineno = 242, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += " <label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
output += "\n\t\t<button class=\"save success button\">";
output += runtime.suppressValue(((lineno = 243, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"small skip secondary button\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching user data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
