var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> <span class='fal fa-plus'/> Create New Custom Store </div>\n\t<div class=\"modal-body\">\n\t\t";
if(runtime.contextOrFrameLookup(context, frame, "inlineMessage")) {
output += "\n\t\t<p class=\"inline\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "inlineMessage")), env.opts.autoescape);
output += "</p>\n\t\t";
;
}
output += "\n\n\t\t<div class=\"row\">\n\t\t\t<br />\n\t\t\t<h4>Details</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\t<input name=\"disableDependents\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "disable_dependents")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\">\n\t\t\t\t\t\t&nbsp;Disable Dependent Purchasing\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tName\n\t\t\t\t\t\t<input type=\"text\" name=\"name\" placeholder=\"Name\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tDomain (without .it.si)\n\t\t\t\t\t\t<input type=\"text\" name=\"domain\" placeholder=\"Domain name\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "id")?"disabled":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "domain"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tTitle\n\t\t\t\t\t\t<input type=\"text\" name=\"title\" placeholder=\"Website title\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tContact Person\n\t\t\t\t\t\t<input type=\"text\" name=\"contact\" placeholder=\"Contact Person\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "contact_person"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tContact Email\n\t\t\t\t\t\t<input type=\"text\" name=\"email\" placeholder=\"Contact Email\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "contact_email"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tBookseller's Percentage of Sale\n\t\t\t\t\t\t<input type=\"text\" name=\"salePerc\" placeholder=\"Percentage of Sale\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sale_percentage"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tAnnual Maintenance Fee\n\t\t\t\t\t\t<input type=\"text\" name=\"maintenanceFee\" placeholder=\"Annual Maintenance Fee\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "maintenance_fee"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tDirect Marketing Policy URL\n\t\t\t\t\t\t<input type=\"text\" name=\"marketingPolicy\" placeholder=\"Direct Marketing Policy URL\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "marketing_policy_url"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12\"><hr /></div>\n\n\t\t\t<h4>Social Media</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tFacebook\n\t\t\t\t\t\t<input type=\"text\" name=\"facebook\" placeholder=\"Facebook URL\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "facebook_url"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tLinkedIn\n\t\t\t\t\t\t<input type=\"text\" name=\"linkedin\" placeholder=\"LinkedIn URL\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "linkedin_url"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tTwitter\n\t\t\t\t\t\t<input type=\"text\" name=\"twitter\" placeholder=\"Twitter URL\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "twitter_url"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tYouTube\n\t\t\t\t\t\t<input type=\"text\" name=\"youtube\" placeholder=\"YouTube URL\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "youtube_url"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tInstagram\n\t\t\t\t\t\t<input type=\"text\" name=\"instagram\" placeholder=\"Instagram URL\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "instagram_url"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12\"><hr /></div>\n\n\t\t\t<h4>Limit Books</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tPublishers (leave blank for all)\n\t\t\t\t\t\t<select multiple name=\"publishers\" data-placeholder=\"Select publishers(s)...\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<input name=\"excludePublishers\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "exclude_publishers")?"checked":""), env.opts.autoescape);
output += " id=\"excludePublishers\" type=\"checkbox\">\n\t\t\t\t\t<label for=\"excludePublishers\">Exclude the above publishers instead of including them</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tSchools (leave blank for all)\n\t\t\t\t\t\t<select multiple name=\"schools\" data-placeholder=\"Select schools(s)...\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<input name=\"excludeSchools\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "exclude_schools")?"checked":""), env.opts.autoescape);
output += " id=\"excludeSchools\" type=\"checkbox\">\n\t\t\t\t\t<label for=\"excludeSchools\">Exclude the above schools instead of including them</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tSchool groups (leave blank for all)\n\t\t\t\t\t\t<select name=\"schoolGroups\" data-placeholder=\"School groups\" multiple class=\"adder\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tExclude Content Ratings\n\t\t\t\t\t\t<select name=\"excludeContentRatings\" data-placeholder=\"Content Ratings\" multiple class=\"adder\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tLanguages (leave blank for all)\n\t\t\t\t\t\t<select name=\"languages\" data-placeholder=\"Languages\" multiple class=\"adder\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t\t<br />\n\t\t\t\t</div>\n\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12\"><hr /></div>\n\n\t\t\t<h4>Authentication</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tEnable Auth Providers\n\t\t\t\t\t\t<select multiple name=\"auths\" data-placeholder=\"Select Auth Provider(s)...\">\n\t\t\t\t\t\t\t<option value=\"\"></option>\n\t\t\t\t\t\t\t";
frame = frame.push();
var t_3 = (lineno = 172, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "authTypeList"), "authTypeList", context, []));
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("authType", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "auth_types") && (lineno = 173, colno = 63, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "auth_types")),"indexOf"), "auth_types[\"indexOf\"]", context, [runtime.memberLookup((t_4),"id")])) > -1?"selected":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"id"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\t<input name=\"requireContactNumber\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "require_contact_number")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\">\n\t\t\t\t\t\t&nbsp;Contact Number Required\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12\"><hr /></div>\n\n\t\t\t<h4>Navigation Menu</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tDisable Navigation Menu Items\n\t\t\t\t\t\t<select multiple name=\"navs\" data-placeholder=\"Navigation Menu Items\"></select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<h4>Google Analytics</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tGoogle Analytics Tracking ID\n\t\t\t\t\t\t<input type=\"text\" name=\"gaMeasurementId\" placeholder=\"Google Analytics Tracking ID\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ga_measurement_id"), env.opts.autoescape);
output += "\">\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12\"><hr /></div>\n\n\t\t\t<h4>Contact Us</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<textarea name=\"contact_us_html\" placeholder=\"Contact Us HTML\" readonly rows=\"8\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "contact_us_html")), env.opts.autoescape);
output += " </textarea>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12\"><hr /></div>\n\n\t\t\t<h4>Appearance</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tPrimary Text Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"primaryTextColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "primary_text_colour")?runtime.contextOrFrameLookup(context, frame, "primary_text_colour"):"2f67b1"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tPrimary Button Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"buttonColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "button_colour")?runtime.contextOrFrameLookup(context, frame, "button_colour"):"2f67b1"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tPrimary Button Text Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"buttonTextColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "button_text_colour")?runtime.contextOrFrameLookup(context, frame, "button_text_colour"):"FFF"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tSecondary Button Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"secondaryButtonColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "secondary_button_colour")?runtime.contextOrFrameLookup(context, frame, "secondary_button_colour"):"d91315"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tSecondary Button Text Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"secondaryButtonTextColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "secondary_button_text_colour")?runtime.contextOrFrameLookup(context, frame, "secondary_button_text_colour"):"FFF"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tHeader Navigation Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"headerColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "header_colour")?runtime.contextOrFrameLookup(context, frame, "header_colour"):"FFF"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tFooter Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"footerColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "footer_colour")?runtime.contextOrFrameLookup(context, frame, "footer_colour"):"233e90"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tFooter Text Colour\n\t\t\t\t\t</label>\n\t\t\t\t\t<input id=\"footerTextColor\" type=\"text\" class=\"colorpicker\" value=\"#";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "footer_text_colour")?runtime.contextOrFrameLookup(context, frame, "footer_text_colour"):"FFF"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<br />\n\t\t\t\t\t<label>Header Image</label>\n\t\t\t\t\t<input type=\"file\" accept=\"image/*\" name=\"headerImageFile\" style=\"display: none\" />\n\t\t\t\t\t<button id=\"headerImage\" class=\"small button\">Choose File</button>\n\n\t\t\t\t\t<div id=\"header-preview\" class=\"previews\">\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "header_image")) {
output += "\n\t\t\t\t\t\t\t<img height=\"100px\" src=\"";
output += runtime.suppressValue((lineno = 286, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "urlRoot"), "urlRoot", context, [])) + "/api/1.0/customStores/" + runtime.contextOrFrameLookup(context, frame, "id") + "/image?fileName=" + runtime.contextOrFrameLookup(context, frame, "header_image"), env.opts.autoescape);
output += "\" id=\"logo\" />\n\t\t\t\t\t\t\t<button type='button' class='removeUploadedHeader remove'>X</button>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns hide\">\n\t\t\t\t\t<label>Banner Images</label>\n\t\t\t\t\t<input type=\"file\" accept=\"image/*\" name=\"bannerFiles\" multiple style=\"display: none\" />\n\t\t\t\t\t<button id=\"uploadBanners\" class=\"small button\">Choose Files</button>\n\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<ul id=\"banners-preview\" class=\"previews\">\n\t\t\t\t\t  \t\t";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "banner_images");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("banner", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t  \t\t\t<li>\n\t\t\t\t\t\t  \t\t\t<img height=\"100px\" src=\"";
output += runtime.suppressValue((lineno = 301, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "urlRoot"), "urlRoot", context, [])) + "/api/1.0/customStores/" + runtime.contextOrFrameLookup(context, frame, "id") + "/image?fileName=" + t_8, env.opts.autoescape);
output += "\" id=\"logo\" />\n\t\t\t\t\t\t  \t\t\t<button type='button' data-filename='";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "' class='removeUploadedBanner remove'>X</button>\n\t\t\t\t\t  \t\t\t</li>\n\t\t\t\t\t  \t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t\t</ul>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<br />\n\t\t\t\t\t<label>Site Icon</label>\n\t\t\t\t\t<input type=\"file\" accept=\"image/*\" name=\"iconFile\" style=\"display: none\" />\n\t\t\t\t\t<button id=\"iconBtn\" class=\"small button\">Choose File</button>\n\n\t\t\t\t\t<div id=\"icon-preview\" class=\"previews\">\n\t\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "icon")) {
output += "\n\t\t\t\t\t\t\t<img height=\"100px\" src=\"";
output += runtime.suppressValue((lineno = 317, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "urlRoot"), "urlRoot", context, [])) + "/api/1.0/customStores/" + runtime.contextOrFrameLookup(context, frame, "id") + "/image?fileName=" + runtime.contextOrFrameLookup(context, frame, "icon"), env.opts.autoescape);
output += "\" id=\"logo\" />\n\t\t\t\t\t\t\t<button type='button' class='removeUploadedIcon remove'>X</button>\n\t\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 327, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"small skip secondary button\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching custom store data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
