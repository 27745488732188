var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"row hide\" id=\"incompletedPublishers\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<h5>Please complete the publisher details for the following publishers:</h5>\n\t\t\t<ul></ul>\n\t\t</div>\n\t</div>\n\t<div class=\"row\" id=\"publisherListWrapper\">\n\t\t<div class=\"large-4 large-offset-4 columns\">\n\t\t\t<label>\n\t\t\t\tPublishers\n\t\t\t\t<select name=\"publisherList\"></select>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\" id=\"publisher\"></div>\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var publisher_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"row\">\n\t\t<div class=\"large-4 columns publisherLogoUpload\">\n\t\t\t<div class=\"block\">\n\t\t\t\t<p><strong>Logo</strong></p>\n\t\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "logoUrl"), env.opts.autoescape);
output += "\" id=\"logo\" />\n\t\t\t\t<input type=\"file\" accept=\"image/png, image/bmp, image/jpeg, image/gif\" name=\"logo\" style=\"display: none\" />\n\t\t\t\t<button class=\"small button uploadImage\">Upload New Logo</button>\n\t\t\t</div>\n\t\t\t<span class=\"spinner-icon spinner-small hide spinner-upload-working\"/> <span class=\"upload-details-message hide\">Uploading image...</span>\n\t\t</div>\n\n\t\t<div class=\"large-8 columns\" id=\"publisherDetails\">\n\t\t\t<h4>Publisher Info</h4>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Full Name of Publisher *\n\t\t\t\t\t\t\t<input type=\"text\" name=\"fullname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "fullname")), env.opts.autoescape);
output += "\" placeholder=\"Full Name of Publisher\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Physical Address *\n\t\t\t\t\t\t\t<input type=\"text\" name=\"physicalAddress\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "physical_address"), env.opts.autoescape);
output += "\" placeholder=\"Physical Address of Publisher\"/>\n\t\t\t\t\t\t\t<input type=\"hidden\" value='";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("dump").call(context, runtime.contextOrFrameLookup(context, frame, "physical_address_data"))), env.opts.autoescape);
output += "' name=\"physicalAddressData\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Postal Address - <input name=\"copyPhysical\" type=\"checkbox\"/><span> Same as Physical Address</span></label>\n\t\t\t\t\t\t<input type=\"text\" name=\"postalAddress\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "postal_address")), env.opts.autoescape);
output += "\" placeholder=\"Postal Address of Publisher\"/>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Website\n\t\t\t\t\t\t\t<input type=\"text\" name=\"website\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "website")), env.opts.autoescape);
output += "\" placeholder=\"Website of Publisher\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Vat Number (if registered)\n\t\t\t\t\t\t\t<input type=\"text\" name=\"vatNumber\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "vat_number")), env.opts.autoescape);
output += "\" placeholder=\"Vat Number\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Company Registration Number\n\t\t\t\t\t\t\t<input type=\"text\" name=\"companyReg\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "company_registration")), env.opts.autoescape);
output += "\" placeholder=\"Company Registration Number of Publisher\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label><input name=\"isPrivate\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "private")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" /><span>&nbsp;Private publisher?</span></label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\n\t\t\t<br />\n\t\t\t<h4>Contact Info</h4>\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h5>Primary</h5>\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Contact Person First Name*\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"primaryContactPersonFirstname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_contact_person_firstname")), env.opts.autoescape);
output += "\" placeholder=\"Primary Contact Person First Name\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Contact Person Surname*\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"primaryContactPersonSurname\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_contact_person_surname")), env.opts.autoescape);
output += "\" placeholder=\"Primary Contact Person Surname\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Email *\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"primaryEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_email")), env.opts.autoescape);
output += "\" placeholder=\"Primary Email Address\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Phone Number *\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"primaryPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "primary_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Primary Phone Number\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<h5>Financial</h5>\n\t\t\t\t<label><input name=\"financialCopyPrimary\" data-group=\"financial\" type=\"checkbox\" /><span>&nbsp;Same as Primary Contact</span></label>\n\t\t\t\t<div id=\"financialContactGroup\" class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Contact Person\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"financialContactPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_contact_person")), env.opts.autoescape);
output += "\" placeholder=\"Financial Contact Person\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Email\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"financialEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_email")), env.opts.autoescape);
output += "\" placeholder=\"Financial Email Address\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Phone Number\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"financialPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "financial_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Financial Phone Number\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<h5>Technical</h5>\n\t\t\t\t<label><input name=\"technicalCopyPrimary\" data-group=\"technical\" type=\"checkbox\" /><span>&nbsp;Same as Primary Contact</span></label>\n\t\t\t\t<div id=\"technicalContactGroup\" class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Contact Person\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalContactPerson\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_contact_person")), env.opts.autoescape);
output += "\" placeholder=\"Technical Contact Person\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Email\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalEmail\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_email")), env.opts.autoescape);
output += "\" placeholder=\"Technical Email Address\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"row\">\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>Phone Number\n\t\t\t\t\t\t\t\t<input type=\"text\" name=\"technicalPhone\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "technical_phone_number")), env.opts.autoescape);
output += "\" placeholder=\"Technical Phone Number\"/>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"large-12 center columns\">\n\t\t\t<!--<button class=\"button publisherKey\">Download Publisher Key</button>-->\n\t\t\t<button class=\"saveDetail button success\">Update Details</button>\n\t\t\t<span class=\"spinner-icon spinner-small hide spinner-details-working\"/> <span class=\"details-message hide\"></span>\n\t\t</div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var publisher_oldRoot = publisher_obj.root;
publisher_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	publisher_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"main": new nunjucks.Template( {obj: main_obj, type: "code"}, env),	"publisher": new nunjucks.Template( {obj: publisher_obj, type: "code"}, env),};
