var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"work-bar\">\n\t<div class=\"loading ";
output += runtime.suppressValue(((lineno = 1, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "index"), "index", context, [])) == runtime.contextOrFrameLookup(context, frame, "null") || !(lineno = 1, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "items"), "items", context, []))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t<span class=\"spinner-icon spinner-small\"/>&nbsp;&nbsp;Loading books for signoff...\n\t</div>\n\n\t<div class=\" loading ";
output += runtime.suppressValue(((lineno = 5, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "index"), "index", context, [])) != runtime.contextOrFrameLookup(context, frame, "null") && runtime.memberLookup(((lineno = 5, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "items"), "items", context, []))),"length") == 0?"":"hide"), env.opts.autoescape);
output += "\">\n\t\tThere are no books awaiting signoff\n\t</div>\n\n\t<div class=\"loaded ";
output += runtime.suppressValue(((lineno = 9, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "index"), "index", context, [])) == runtime.contextOrFrameLookup(context, frame, "null") || runtime.memberLookup(((lineno = 9, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "items"), "items", context, []))),"length") == 0?"hide":""), env.opts.autoescape);
output += "\">\n\t\t<div>\n\t\t\t<div class=\"counter\">Showing book <strong>";
output += runtime.suppressValue((lineno = 11, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "index"), "index", context, [])), env.opts.autoescape);
output += "</strong> of <strong>";
output += runtime.suppressValue(runtime.memberLookup(((lineno = 11, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "items"), "items", context, []))),"length"), env.opts.autoescape);
output += "</strong></div>\n\t\t</div>\n\n\t\t<div>\n\t\t\t<button class=\"button success signoff small\"><span class=\"fal fa-thumbs-up\"/> Sign Off</button>\n\t\t\t";
if((lineno = 16, colno = 12, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "index"), "index", context, [])) < runtime.memberLookup(((lineno = 16, colno = 22, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "items"), "items", context, []))),"length")) {
output += "\n\t\t\t\t<button class=\"button alert skip small\"><span class=\"fal fa-forward\"/> Skip Book</button>\n\t\t\t";
;
}
output += "\n\t\t\t<button class=\"button secondary cancel small\"><span class=\"fal fa-ban\"/> Cancel Bulk Signoff</button>\n\t\t</div>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
