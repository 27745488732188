var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<table id=\"voucherList\">\n\t<tr>\n\t\t<th>Code</th>\n\t\t<th>Uses</th>\n\t\t<th>Used By</th>\n\t\t<th>Email</th>\n\t\t<th>Date Used</th>\n\t\t<th>Discount</th>\n\t\t<th>Purchase Reference #</th>\n\t</tr>\n\n\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "vouchers");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("voucher", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t<tr>\n\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_4),"code"), env.opts.autoescape);
output += "</td>\n\t\t\t<td>";
output += runtime.suppressValue((runtime.memberLookup((runtime.memberLookup((t_4),"uses")),0) != runtime.contextOrFrameLookup(context, frame, "null")?runtime.memberLookup((runtime.memberLookup((t_4),"uses")),"length"):"0"), env.opts.autoescape);
output += "</td>\n\n\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((t_4),"uses")),0) != runtime.contextOrFrameLookup(context, frame, "null")) {
output += "\n\t\t\t\t";
frame = frame.push();
var t_7 = runtime.memberLookup((t_4),"uses");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("use", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
output += "\n\t\t\t\t\t";
if((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"first") == false)) {
output += "\n\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td></td>\n\t\t\t\t\t\t<td></td>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"firstname") + " " + runtime.memberLookup((t_8),"surname"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"email"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"used_date"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"discount_amount"), env.opts.autoescape);
output += "</td>\n\t\t\t\t\t\t<td>";
output += runtime.suppressValue(runtime.memberLookup((t_8),"reference"), env.opts.autoescape);
output += "</td>\t\t\n\t\t\t\t\t</tr>\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t";
;
}
else {
output += "\n\t\t\t\t\t<td></td>\n\t\t\t\t\t<td></td>\n\t\t\t\t\t<td></td>\n\t\t\t\t\t<td></td>\n\t\t\t\t\t<td></td>\n\t\t\t\t</tr>\n\t\t\t";
;
}
output += "\n\t";
;
}
}
frame = frame.pop();
output += "\n</table>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
