var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"login-block\">\n\t<form>\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h5>Please enter your login details below. Note that passwords are case sensitive.</h5>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Username or email:\n\t\t\t\t\t<input type=\"text\" role=\"username\" name=\"username\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Password\n\t\t\t\t\t<input type=\"password\" role=\"password\" name=\"password\" autocomplete=\"on\"/>\n\t\t\t\t</label>\n\t\t\t\t<a class=\"forgot-password\" href=\"#\"><span class=\"fal fa-question-circle\"/> Forgot your password?</a>\n\t\t\t\t<a class=\"password-token\" href=\"#\"><span class=\"fal fa-ticket\"/> I want to enter a password reset token</a>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<input type=\"submit\" value=\"Log In\" class=\"button\"/> <span class=\"spinner-icon hide\"></span> <span class=\"info-text\"></span>\n\t\t\t</div>\n\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "oauth")) {
output += "\n\t\t\t\t<div class=\"large-12 columns oauth\">\n\t\t\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "oauth") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "oauth")),"itsi")) {
output += "\n\t\t\t\t\t<span class=\"or\">or</span>\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "oauth")),"google")),"enabled")) {
output += "\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/google\" class=\"google-btn oauth-btn\">\n\t\t\t\t\t\t<button class=\"loginBtn loginBtn--google\">\n\t\t\t\t\t\t  <span class=\"fab fa-google\"/>  Log in with Google\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</a>\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "oauth")),"microsoft")),"enabled")) {
output += "\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/microsoft\" class=\"microsoft-btn oauth-btn\">\n\t\t\t\t\t\t<button class=\"loginBtn loginBtn--microsoft\">\n\t\t\t\t\t\t  <span class=\"fab fa-microsoft\"/> Log in with Microsoft\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</a>\n\t\t\t\t\t";
;
}
output += "\n\n\t\t\t\t\t";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "oauth")),"facebook")),"enabled")) {
output += "\n\t\t\t\t\t<a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/auth/facebook\" class=\"facebook-btn oauth-btn\">\n\t\t\t\t\t\t<button class=\"loginBtn loginBtn--facebook\">\n\t\t\t\t\t\t  <span class=\"fab fa-facebook\"/> Log in with Facebook\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</a>\n\t\t\t\t\t";
;
}
output += "\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\t</form>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
