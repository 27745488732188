var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 88, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-cloud'/> Create New Service"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing Service: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-9\">\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<h4>Service Info</h4>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>Service / Subscription title\n\t\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\" name=\"title\" placeholder=\"Title of service\"/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>Description\n\t\t\t\t\t\t<textarea rows=\"5\" name=\"description\" placeholder=\"Description of service\" readonly>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "description")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\t<input type=\"checkbox\" name=\"singleBuy\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "single_buy")?"checked":""), env.opts.autoescape);
output += " id=\"singleBuy\"/> Only allow customers to buy this service once?\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tPublisher\n\t\t\t\t\t\t<select name=\"publisher\" data-placeholder=\"Publisher\">\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tLanguage\n\t\t\t\t\t\t<select name=\"language\" data-placeholder=\"Service language\">\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tYear of study\n\t\t\t\t\t\t<select name=\"grade\" data-placeholder=\"Book year\" multiple ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tSubject\n\t\t\t\t\t\t<select name=\"subject\" data-placeholder=\"Subject\">\n\t\t\t\t\t\t</select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tPreview Link\n\t\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "preview_link")), env.opts.autoescape);
output += "\" name=\"previewLink\" placeholder=\"Preview Link of service\" autocomplete=\"off\" readonly onfocus=\"if (this.hasAttribute('readonly')) {\n\t\t\t\t\t\t    this.removeAttribute('readonly');\n\t\t\t\t\t\t    // fix for mobile safari to show virtual keyboard\n\t\t\t\t\t\t    this.blur();    this.focus();  }\"/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tTags (optional)\n\t\t\t\t\t\t<select name=\"tags\" data-placeholder=\"Tags\" multiple></select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"row collapse\">\n\t\t\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t\tRegions\n\t\t\t\t\t\t\t\t<select name=\"regions\" data-placeholder=\"Service regions\" ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL"))?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allRegions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "regions") && env.getFilter("contains").call(context, runtime.contextOrFrameLookup(context, frame, "regions"),"ALL")?"checked":""), env.opts.autoescape);
output += "/> All regions</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"row priceRow\">\n\t\t\t\t\t\t<div class=\"large-2 columns aud hide priceBlock\">\n\t\t\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "aud") || runtime.contextOrFrameLookup(context, frame, "aud") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "aud")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-2 columns eur hide priceBlock\">\n\t\t\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "eur") || runtime.contextOrFrameLookup(context, frame, "eur") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "eur")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-2 columns gbp hide priceBlock\">\n\t\t\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "gbp") || runtime.contextOrFrameLookup(context, frame, "gbp") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "gbp")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-2 columns usd hide priceBlock\">\n\t\t\t\t\t\t\t<label>USD\n\t\t\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "usd") || runtime.contextOrFrameLookup(context, frame, "usd") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "usd")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t<div class=\"large-2 columns zar end hide priceBlock\">\n\t\t\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "zar") || runtime.contextOrFrameLookup(context, frame, "zar") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "zar")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" />\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t    <label>Delivery Type</label>\n\t\t\t    \t<input type=\"radio\" name=\"deliveryType\" value=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "delivery_type") == 0?"checked":""), env.opts.autoescape);
output += " id=\"httprequest\" required><label for=\"httprequest\">API Call</label>\n\t\t\t    \t<input type=\"radio\" name=\"deliveryType\" value=\"1\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "delivery_type") == 1?"checked":""), env.opts.autoescape);
output += " id=\"emailToken\"><label for=\"emailToken\">Email Token</label>\n\t\t\t  \t</div>\n\n\t\t\t  \t<div id=\"request-container\" class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>HTTP Verb\n\t\t\t\t\t\t\t<select name=\"httpVerb\">\n\t\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "http_request_verb") == "GET"?"selected":""), env.opts.autoescape);
output += " value=\"GET\">GET</option>\n\t\t\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "http_request_verb") == "POST"?"selected":""), env.opts.autoescape);
output += " value=\"POST\">POST</option>\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<input type=\"checkbox\" name=\"requireSSL\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "require_ssl")?"checked":""), env.opts.autoescape);
output += " id=\"requireSSL\"/> Require SSL\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Url\n\t\t\t\t\t\t\t<input type=\"text\" name=\"url\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "displayUrl")), env.opts.autoescape);
output += "\" placeholder=\"Example: www.google.com?param1=test&param2=test2\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Parameter Mapping\n\t\t\t\t\t\t\t<textarea name=\"mappings\" placeholder=\"email=foo\" rows=\"6\">";
output += runtime.suppressValue(env.getFilter("dumpMappings").call(context, runtime.contextOrFrameLookup(context, frame, "mappings")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Username\n\t\t\t\t\t\t\t<input type=\"text\" name=\"username\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "username")), env.opts.autoescape);
output += "\" placeholder=\"Username\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t  \t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Password\n\t\t\t\t\t\t\t<input type=\"text\" name=\"password\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "password")), env.opts.autoescape);
output += "\" placeholder=\"Password\"/>\n\t\t\t\t\t\t</label>\n\t\t\t\t  \t</div>\n\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Expected Response (JSON object)\n\t\t\t\t\t\t\t<textarea name=\"response\" rows=\"6\">";
output += runtime.suppressValue(env.getFilter("dumpIfObject").call(context, runtime.contextOrFrameLookup(context, frame, "response")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t\t<div id=\"token\" class=\"large-12 columns\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>Email Content\n\t\t\t\t\t\t\t<textarea name=\"emailContent\" rows=\"10\" placeholder=\"Email Content\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "email_content")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t\t<div class=\"large-3\">\n\t\t\t\t<div class=\"service-cover-image-chooser\">\n\t\t\t\t\t<label>Click below to choose or change cover image</label><br/>\n\t\t\t\t\t<div class=\"service-cover-image\"></div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 192, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching service data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
