var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var console_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-host\">\n\t\t<div class=\"modal-element modal-background hide\"></div>\n\t\t<div class=\"modal-element modal-container hide\"></div>\n\t</div>\n\n\t<div class=\"tooltip-host\"></div>\n\n\t<div id=\"sidebar-region\">\n\n\t</div>\n\n\t<div id=\"main-region\">\n\n\t\t<div id=\"header-region\"></div>\n\n\t\t<div id=\"body-container\">\n\t\t\t<div id=\"body-container-left-margin\"></div>\n\n\t\t\t<div id=\"content-region\">\n\t\t\t\t<img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/final-011b-hat.gif\">\n\t\t\t\t<div>Just a moment, we're setting things up for you...</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var console_oldRoot = console_obj.root;
console_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	console_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var error_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<p>Something went wrong while loading the console:</p>\n\t<p><strong>Error: ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "error")),"detail") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "error")),"message"), env.opts.autoescape);
output += "</strong></p>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var error_oldRoot = error_obj.root;
error_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	error_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"console": new nunjucks.Template( {obj: console_obj, type: "code"}, env),	"error": new nunjucks.Template( {obj: error_obj, type: "code"}, env),};
