var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 95, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-user-plus'/> Create New FTP Source"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing FTP Source: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "provider"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<h4>FTP Source Info</h4>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Provider *\n\t\t\t\t\t<input type=\"text\" name=\"provider\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "provider")), env.opts.autoescape);
output += "\" placeholder=\"Name of Provider\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<input name=\"active\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "active")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label>Active</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Host *\n\t\t\t\t\t<input type=\"text\" name=\"host\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "host")), env.opts.autoescape);
output += "\" placeholder=\"Host\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Username *\n\t\t\t\t\t<input type=\"text\" name=\"ftpUsername\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "username")), env.opts.autoescape);
output += "\" placeholder=\"Username\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Password *\n\t\t\t\t\t<input type=\"text\" name=\"ftpPassword\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "password")), env.opts.autoescape);
output += "\" placeholder=\"Password\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Error reports to publisher\n\t\t\t\t\t<input type=\"text\" name=\"error_publisher_email\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "error_publisher_email")), env.opts.autoescape);
output += "\" placeholder=\"Publisher Email\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Error reports to ITSI\n\t\t\t\t\t<input type=\"text\" name=\"error_itsi_email\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "error_itsi_email")), env.opts.autoescape);
output += "\" placeholder=\"ITSI Email\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t</div>\n\t</div>\n\n\t<div class=\"modal-footer left\">\n\t\t";
if((lineno = 51, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += " <label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
output += "\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 52, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching FTP Source data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
