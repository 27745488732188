var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";t_2 += "\n\t";
if(runtime.contextOrFrameLookup(context, frame, "addAnother")) {
t_2 += "<label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
t_2 += "\n\t<button class=\"button ok ";
t_2 += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "warning")?"success":"alert"), env.opts.autoescape);
t_2 += " ";
t_2 += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "hideButtons")),"length") == 0 || ((lineno = 2, colno = 120, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "hideButtons")),"indexOf"), "hideButtons[\"indexOf\"]", context, ["ok"])) == -1)?"":"hide"), env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "customOK")?"OK":runtime.contextOrFrameLookup(context, frame, "customOK")), env.opts.autoescape);
t_2 += "</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t";
if(!runtime.contextOrFrameLookup(context, frame, "hideButtons") || (lineno = 3, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "hideButtons")),"indexOf"), "hideButtons[\"indexOf\"]", context, ["cancel"])) == -1) {
t_2 += "<button class=\"button cancel secondary\">";
t_2 += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "customCancel")?"Cancel":runtime.contextOrFrameLookup(context, frame, "customCancel")), env.opts.autoescape);
t_2 += "</button>";
;
}
t_2 += "\n\t&nbsp;&nbsp;&nbsp;&nbsp;<span class=\"spinner-icon hide main\"/> <span class=\"sub-message hide\"></span>\n";
;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
context.addExport("mainButtons");
context.setVariable("mainButtons", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
frame = frame.push();
kwargs = kwargs || {};
if (kwargs.hasOwnProperty("caller")) {
frame.set("caller", kwargs.caller); }
var t_4 = "";t_4 += "\n\t";
frame = frame.push();
var t_7 = runtime.contextOrFrameLookup(context, frame, "customButtons");
if(t_7) {var t_6 = t_7.length;
for(var t_5=0; t_5 < t_7.length; t_5++) {
var t_8 = t_7[t_5];
frame.set("button", t_8);
frame.set("loop.index", t_5 + 1);
frame.set("loop.index0", t_5);
frame.set("loop.revindex", t_6 - t_5);
frame.set("loop.revindex0", t_6 - t_5 - 1);
frame.set("loop.first", t_5 === 0);
frame.set("loop.last", t_5 === t_6 - 1);
frame.set("loop.length", t_6);
t_4 += "\n\t\t<button class=\"button ";
t_4 += runtime.suppressValue((!runtime.memberLookup((t_8),"type")?"secondary":runtime.memberLookup((t_8),"type")), env.opts.autoescape);
t_4 += " ";
t_4 += runtime.suppressValue(runtime.memberLookup((t_8),"id"), env.opts.autoescape);
t_4 += "\" data-id=\"";
t_4 += runtime.suppressValue(runtime.memberLookup((t_8),"id"), env.opts.autoescape);
t_4 += "\">";
t_4 += runtime.suppressValue(runtime.memberLookup((t_8),"name"), env.opts.autoescape);
t_4 += "</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t";
;
}
}
frame = frame.pop();
t_4 += "\n";
;
frame = frame.pop();
return new runtime.SafeString(t_4);
});
context.addExport("custButtons");
context.setVariable("custButtons", macro_t_3);
output += "\n\n<div class=\"modal-title ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "warning")?"":"modal-warning"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
output += "</div>\n<div class=\"modal-body\">\n\t";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "body"), env.opts.autoescape);
output += "\n\t";
if(runtime.contextOrFrameLookup(context, frame, "prompt")) {
output += "\n\t\t<input type=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "promptType"), env.opts.autoescape);
output += "\" class=\"modal-prompt\" />\n\t";
;
}
output += "\n\t<div class=\"modal-error hide\"></div>\n</div>\n<div class=\"modal-footer\">\n\t";
if(runtime.contextOrFrameLookup(context, frame, "hasBody")) {
output += "\n\t\t";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "customButtons")),"length")) {
output += "\n\t\t\t<div class=\"modal-footer-left\"> ";
output += runtime.suppressValue((lineno = 24, colno = 47, runtime.callWrap(macro_t_3, "custButtons", context, [])), env.opts.autoescape);
output += "</div>\n\t\t\t<div class=\"modal-footer-right\">";
output += runtime.suppressValue((lineno = 25, colno = 47, runtime.callWrap(macro_t_1, "mainButtons", context, [])), env.opts.autoescape);
output += "</div>\n\t\t";
;
}
else {
output += "\n\t\t\t<div/>\n\t\t\t<div class=\"modal-footer-main\"> ";
output += runtime.suppressValue((lineno = 28, colno = 47, runtime.callWrap(macro_t_1, "mainButtons", context, [])), env.opts.autoescape);
output += "</div>\n\t\t\t<div/>\n\t\t";
;
}
output += "\n\t";
;
}
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
