var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"books\">\n\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "items");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("book", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t<div class=\"viewBook\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" draggable=\"true\" data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product"), env.opts.autoescape);
output += "\">\n\t\t\t<div class=\"bookImg bookTooltip\" style=\"background-image:url(";
if(runtime.memberLookup((t_4),"product_type") == 1) {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/api/1.0/ebooks/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product"), env.opts.autoescape);
output += "/cover?thumb=true";
;
}
else {
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/api/1.0/services/";
output += runtime.suppressValue(runtime.memberLookup((t_4),"service_id"), env.opts.autoescape);
output += "/cover?thumb=true";
;
}
output += "), url('../images/no_cover.png');\" title=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("replace").call(context, runtime.memberLookup((t_4),"name"),"&amp;","and")), env.opts.autoescape);
output += "\" data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product"), env.opts.autoescape);
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" data-license=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"license_id"), env.opts.autoescape);
output += "\" data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"product"), env.opts.autoescape);
output += "\"></div>\n\t\t\t";
if(!runtime.memberLookup((t_4),"active") && runtime.memberLookup((t_4),"product_type") == 1) {
output += "\n\t\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\tOld Version\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if(runtime.memberLookup((t_4),"discontinued")) {
output += "\n\t\t\t\t<div class=\"discontinued-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\tDiscontinued\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t";
if(runtime.memberLookup((t_4),"license_id") > 0) {
output += "\n\t\t\t\t<div class=\"license-ribbon\">\n\t\t\t\t\t\t<div class=\"txt\">\n\t\t\t\t\t\t\t";
output += runtime.suppressValue(runtime.memberLookup((t_4),"formatted_duration"), env.opts.autoescape);
output += "\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\n\t\t\t<div class=\"title\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, env.getFilter("replace").call(context, runtime.memberLookup((t_4),"name"),"&amp;","and")), env.opts.autoescape);
output += "</div>\n\t</div>\n\t";
;
}
}
frame = frame.pop();
output += "\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
