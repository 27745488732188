var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
if(runtime.contextOrFrameLookup(context, frame, "parsed")) {
output += "\n<div class=\"header\">\n\t<div class=\"name\">\n\t\t<div class=\"filename\">&nbsp;&nbsp;";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "filename"), env.opts.autoescape);
output += "</div>\n\t\t<div>\n\t\t\t<div class=\"blob mainBlob reviewing ";
output += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "reviewed")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-list\"/> Reviewing</div>\n\t\t\t<div class=\"blob mainBlob uploading ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "uploading")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-cloud-upload\"/> Uploading...</div>\n\t\t\t<div class=\"blob mainBlob queued ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") && !runtime.contextOrFrameLookup(context, frame, "uploading")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-clock\"/> Queued for upload</div>\n\n\t\t\t<div class=\"blob state exists ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"exists")?"":"hide"), env.opts.autoescape);
output += "\"><span class=\"fal fa-edit\"/> Updating Existing Book</div>\n\t\t\t<div class=\"blob state new ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"exists")?"hide":""), env.opts.autoescape);
output += "\"><span class=\"fal fa-star\"/> Adding New Book</div>\n\t\t</div>\n\t</div>\n\n\t<div class=\"actions\">\n\t\t<div class=\"rubutton upload ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"hide":""), env.opts.autoescape);
output += " blue\">Upload Book</div>\n\t\t<div class=\"rubutton dequeue ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") && !runtime.contextOrFrameLookup(context, frame, "uploading")?"":"hide"), env.opts.autoescape);
output += " black\">Unqueue Book</div>\n\t\t<div class=\"rubutton skip red\">Skip Book</div>\n\t</div>\n</div>\n\n<div class=\"old-metadata ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"_old")?"":"hide"), env.opts.autoescape);
output += "\">The metadata that was used to populate this book is more than 6 months old. It may be invalid.</div>\n\n\n<div class=\"progress\"></div>\n\n<div class=\"book-editor\">\n\t<div class=\"book-metadata\">\n\t\t<div class=\"row changelog ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"exists") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"approved")?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>You are updating an activated book. Please provide a short description of what has changed.\n\t\t\t\t\t<textarea name=\"changelog\" placeholder=\"Changelog\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += " rows=\"4\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "changelog")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Default book name</label>\n\t\t\t\t<div class=\"pre-title\"><strong>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"originalTitle")), env.opts.autoescape);
output += "</strong></div><br/>\n\t\t\t\t<label>\n\t\t\t\t\tIf you don't want to use the value above as the book's name, type another name into the box below.\n\t\t\t\t\t<input type=\"text\" name=\"title\" placeholder=\"Alternate book name\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"title")), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPublisher\n\t\t\t\t\t<select name=\"publisher\" data-placeholder=\"Publisher\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"prePublisher")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\teISBN\n\t\t\t\t\t<input type=\"text\" name=\"isbn\" placeholder=\"ISBN number\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"isbn")), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tYear of study\n\t\t\t\t\t<select name=\"grade\" data-placeholder=\"Book year\" multiple ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tLanguage\n\t\t\t\t\t<select name=\"language\" data-placeholder=\"Book language\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tBook subject\n\t\t\t\t\t<select name=\"subject\" data-placeholder=\"Book subject\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tTarget markets\n\t\t\t\t\t<select name=\"markets\" data-placeholder=\"Book markets\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<div class=\"row collapse\">\n\t\t\t\t\t<div class=\"large-10 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tRegions\n\t\t\t\t\t\t\t<select name=\"regions\" data-placeholder=\"Book regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || (runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"regions") && env.getFilter("contains").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"regions"),"ALL"))?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t\t<div class=\"large-11 large-offset-1\">\n\t\t\t\t\t\t\t<label id=\"regionExceptions\">\n\t\t\t\t\t\t\t\tExcept\n\t\t\t\t\t\t\t\t<select name=\"regionExceptions\" data-placeholder=\"Book regions\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns\">\n\t\t\t\t\t\t<label>&nbsp;</label>\n\t\t\t\t\t\t<label>&nbsp;&nbsp;&nbsp;<input type=\"checkbox\" name=\"allRegions\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"regions") && env.getFilter("contains").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"regions"),"ALL")?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += "/> All regions</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<span class=\"price-tooltip\">(Specify at least one price. Any left empty will be automatically converted every day.)</span>\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<!--<div class=\"large-1 columns aed hide priceBlock\">\n\t\t\t\t\t\t<label>AED\n\t\t\t\t\t\t\t<input type=\"number\" name=\"aed\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"aed")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"aed"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>-->\n\t\t\t\t\t<div class=\"large-2 columns aud priceBlock\">\n\t\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"aud")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"aud"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns eur priceBlock\">\n\t\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"eur")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"eur"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns gbp priceBlock\">\n\t\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"gbp")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"gbp"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<!--<div class=\"large-1 columns nad priceBlock\">\n\t\t\t\t\t\t<label>NAD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"nad\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"nad")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"nad"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>-->\n\t\t\t\t\t<div class=\"large-2 columns usd priceBlock\">\n\t\t\t\t\t\t<label>USD\n\t\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"usd")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"usd"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-2 columns zar end priceBlock\">\n\t\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"zar")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"zar"):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tCurricula (optional)\n\t\t\t\t\t<select name=\"curriculum\" data-placeholder=\"Book curriculum\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tEdition\n\t\t\t\t\t<input type=\"text\" name=\"edition\" placeholder=\"Book edition\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"edition")), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tContent Rating\n\t\t\t\t\t<select name=\"contentrating\" data-placeholder=\"Content Rating\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"disabled":""), env.opts.autoescape);
output += " multiple>\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tPrint ISBN\n\t\t\t\t\t\t\t<input type=\"text\" name=\"printIsbn\" placeholder=\"Print ISBN\" value=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"printIsbn")?runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"printIsbn"):env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"print_isbn"))), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed") || runtime.contextOrFrameLookup(context, frame, "bulkSignoff")?"readonly":""), env.opts.autoescape);
output += " required=\"true\">\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<input type=\"checkbox\" name=\"printIsbnCheck\"> No Print ISBN\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPublication date (optional)\n\t\t\t\t\t<input type=\"text\" name=\"pubDate\" placeholder=\"Click to choose date\" readonly/>\n\t\t\t\t\t";
if(!runtime.contextOrFrameLookup(context, frame, "reviewed")) {
output += "<div class=\"input-overlay hide\" title=\"Clear field\"><span class=\"fal fa-times\"/></div>";
;
}
output += "\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t";
if((env.getFilter("hasAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"books")),"changeTags")))) {
output += "\n\t\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t\t<label>\n\t\t\t\t\t\tTags (optional)\n\t\t\t\t\t\t<select name=\"tags\" data-placeholder=\"Tags\" multiple></select>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"allowPreview\" ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"allow_preview")?"checked":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\tPreview Book in ITSI Store\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns pages-section ";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"allow_preview")?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t\t<label>\n\t\t\t\t\tSpecify pages to preview:\n\t\t\t\t\t<input type=\"text\" name=\"previewPages\" placeholder=\"Eg: 1-5, 7, 15-20, 22, 25-30\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"preview_pages"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tContributors (one per line)\n\t\t\t\t\t<textarea name=\"contributors\" placeholder=\"Book contributors\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += " rows=\"8\">";
output += runtime.suppressValue((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"contributors")?"":(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"contributors"),"\n")))), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>Keywords (optional, one per line)\n\t\t\t\t\t<textarea name=\"keywords\" placeholder=\"Book keywords\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += " rows=\"8\">";
output += runtime.suppressValue((!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"keywords")?"":(env.getFilter("escape").call(context, env.getFilter("join").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"keywords"),"\n")))), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Abstract (optional)\n\t\t\t\t\t<textarea name=\"abstract\" placeholder=\"Book abstract\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += " rows=\"8\">";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "metadata")),"abstract")), env.opts.autoescape);
output += "</textarea>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t\t<!--<div class=\"row\">\n\t\t\t";
if(runtime.contextOrFrameLookup(context, frame, "extension") == "pdf") {
output += "\n\t\t\t<div class=\"large-6 columns\">\n\t\t\t\t<label>PDF Restrictions (<a name=\"restrict-details\">What are these?</a>)\n\t\t\t\t\t<strong><div name=\"restrictions\">";
output += runtime.suppressValue(env.getFilter("showPdfRestrictions").call(context, runtime.contextOrFrameLookup(context, frame, "pdfRestrictions")), env.opts.autoescape);
output += "</div></strong>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t\t";
;
}
output += "\n\t\t</div>-->\n\t</div>\n\t<div class=\"book-cover-image-chooser\">\n\t\t<label>Click below to choose or change cover image</label><br/>\n\t\t<div class=\"book-cover-image\"></div><br/>\n\t</div>\n</div>\n";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "parsing")) {
output += "\n\t<div class=\"centered\">\n\t\t<div><span class=\"parsing\"/> <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "file")),"name"), env.opts.autoescape);
output += "</strong></div>\n\t\t<div>This book is being loaded. Please wait...</div>\n\t</div>\n";
;
}
else {
output += "\n\t<div class=\"centered\">\n\t\t<div><span class=\"fal fa-circle\"/> <strong>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "file")),"name"), env.opts.autoescape);
output += "</strong></div>\n\t\t<div>This book is queued for loading. Please wait...</div>\n\t</div>\n";
;
}
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
