var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"problem\">\n\t<div class=\"name\"><img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "urlRoot"), env.opts.autoescape);
output += "/images/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "baseFormat"), env.opts.autoescape);
output += ".png\" width=\"32px\" height=\"32px\"/>&nbsp;";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "filename"), env.opts.autoescape);
output += "</div>\n\n\t<div class=\"title\"><span class=\"fal fa-question-circle\"/> What happened?</div>\n\t<p>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "error")),"detail") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "error")),"info") || runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "error")),"message"), env.opts.autoescape);
output += "</p>\n\n\t<div class=\"title\"><span class=\"fal fa-question-circle\"/> What can I do?</div>\n\t<p>\n\t\t<button name=\"retry\" class=\"button small\">Try Again</button>&nbsp;&nbsp;\n\t\t<button class=\"button alert small\" name=\"skip\">Skip</button>\n\t</p>\n\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
