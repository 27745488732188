var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>Username\n\t\t\t<div class=\"textual big\"><strong>";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "username")), env.opts.autoescape);
output += "</strong></div>\n\t\t</label>\n\t</div>\n</div>\n\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>First Name\n\t\t\t<input type=\"text\" name=\"firstname\" placeholder=\"Your real name, or a nickname. We'll use this to be more welcoming on the site, and in emails\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "firstname")), env.opts.autoescape);
output += "\"/>\n\t\t</label>\n\t</div>\n</div>\n\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>Surname\n\t\t\t<input type=\"text\" name=\"surname\" placeholder=\"Your real name, or a nickname. We'll use this to be more welcoming on the site, and in emails\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "surname")), env.opts.autoescape);
output += "\"/>\n\t\t</label>\n\t</div>\n</div>\n\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>Email Address\n\t\t\t<input type=\"text\" name=\"email\" placeholder=\"Please use a valid email, because we will email you important information about your purchases\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "email")), env.opts.autoescape);
output += "\"/>\n\t\t</label>\n\t</div>\n</div>\n\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>VAT Number\n\t\t\t<input type=\"text\" name=\"vat\" placeholder=\"If you have a VAT number, enter it here to have it reflected on your invoices\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "vat_number")), env.opts.autoescape);
output += "\"/>\n\t\t</label>\n\t</div>\n</div>\n\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label>\n\t\t\tTimezone\n\t\t\t<select name=\"timezone\" data-placeholder=\"Timezone\">\n\t\t\t\t<option value=\"\"></option>\n\t\t\t\t";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "timezoneList");
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("zone", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t<option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.memberLookup((t_4),"name") == runtime.contextOrFrameLookup(context, frame, "timezone")?"selected":""), env.opts.autoescape);
output += ">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += " (";
output += runtime.suppressValue(runtime.memberLookup((t_4),"offset"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((t_4),"location"), env.opts.autoescape);
output += ")</option>\n\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t</select>\n\t\t</label>\n\t</div>\n</div>\n\n<div class=\"row\">\n\t<div class=\"large-12 columns\">\n\t\t<label><input type=\"checkbox\" name=\"changePassword\" /> Update password</label>\n\t</div>\n</div>\n\n<div id=\"passwordReset\" class=\"hide\">\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((!(env.getFilter("impersonateAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"users")),"passwordChangeOverride")))?"":"hide"), env.opts.autoescape);
output += "\">\n\t\t\t<label>Current Password\n\t\t\t\t<input type=\"password\" name=\"oldpassword\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>New password\n\t\t\t\t<input type=\"password\" name=\"password\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>Confirm new password\n\t\t\t\t<input type=\"password\" name=\"password2\"/>\n\t\t\t</label>\n\t\t</div>\n\t</div>\n\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns ";
output += runtime.suppressValue((!(env.getFilter("impersonateAccess").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "permissions")),"users")),"passwordChangeOverride")))?"hide":""), env.opts.autoescape);
output += "\">\n\t\t\t<label><input type=\"checkbox\" name=\"forceChange\"/> Require this user to change their password after their next successful login</label>\n\t\t</div>\n\t</div>\n</div>\n\n</br >\n\n<div class=\"row\">\n\t<div class=\"large-12 center columns\">\n\t\t<button class=\"saveDetail success button\">Update Details</button>\n\t\t<span class=\"spinner-icon spinner-small hide spinner-details-working\"/> <span class=\"details-message hide\"></span>\n\t</div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
