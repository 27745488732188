var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 1, colno = 97, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-user-plus'/> Create New School Group"):(env.getFilter("replace").call(context, "<span class='fal fa-edit'/> Editing School Group: %s","%s",env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name"))))), env.opts.autoescape);
output += " </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<h4>School Group Info</h4>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>Name of School Group *\n\t\t\t\t\t<input type=\"text\" value=\"";
output += runtime.suppressValue(env.getFilter("escape").call(context, runtime.contextOrFrameLookup(context, frame, "name")), env.opts.autoescape);
output += "\" name=\"name\" placeholder=\"Full name of school group\"/>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t";
if((lineno = 16, colno = 11, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))) {
output += " <label class=\"inline\"><input type=\"checkbox\" name=\"another\"> Create another</label>&nbsp;&nbsp;";
;
}
output += "\n\t\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 17, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
var placeholder_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"spinner-icon\"> Fetching school group data</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var placeholder_oldRoot = placeholder_obj.root;
placeholder_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	placeholder_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),	"placeholder": new nunjucks.Template( {obj: placeholder_obj, type: "code"}, env),};
