var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var main_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"modal-title\"> ";
output += runtime.suppressValue(((lineno = 0, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("<span class='fal fa-tag'/> Create New Discount"):("<span class='fal fa-edit'/> Editing Discount")), env.opts.autoescape);
output += " </div>\n<div class=\"modal-body\">\n\t<div class=\"row\">\n\t\t<div class=\"large-12 columns\">\n\t\t\t<h4>Discount Info</h4>\n\t\t</div>\n\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tName\n\t\t\t\t\t<input type=\"text\" name=\"name\" placeholder=\"Name\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "name"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tStart Date\n\t\t\t\t<input type=\"text\" name=\"startDate\" placeholder=\"Start Date\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "start_date"), env.opts.autoescape);
output += "\" autocomplete=\"off\">\n\t\t\t</label>\n\t\t</div>\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>\n\t\t\t\tExpiry Date\n\t\t\t\t<input type=\"text\" name=\"expiryDate\" placeholder=\"Expiry Date\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "expiry_date"), env.opts.autoescape);
output += "\" autocomplete=\"off\">\n\t\t\t</label>\n\t\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<label>Apply discount to</label>\n\t\t\t<label for=\"schoolDiscount\"><input id=\"schoolDiscount\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "school_discount")?"checked":""), env.opts.autoescape);
output += " name=\"schoolDiscount\" type=\"checkbox\" /> Organisations (online and offline organisations)</label>\n\t\t\t<div class=\"large-12 columns\" id=\"schoolContainer\">\n\t\t\t\t<label>\tSelect Organisations *</label>\n\t\t\t\t<label>\n\t\t\t\t\t<select multiple name=\"schools\" data-placeholder=\"Select organisation(s)...\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t\t<label>\tSelect Organisation Groups *</label>\n\t\t\t\t<label>\n\t\t\t\t\t<select multiple name=\"schoolGroups\" data-placeholder=\"Select organisation groups(s)...\">\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<label for=\"storeDiscount\"><input id=\"storeDiscount\" name=\"storeDiscount\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "store_discount")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\" /> ITSI Store (anywhere users and dependents from online schools)</label>\n\t\t\t<div class=\"large-12 columns\" id=\"customStoreContainer\">\n\t\t\t\t<label>\n\t\t\t\t\tSelect Store\n\t\t\t\t\t<select name=\"customStores\" data-placeholder=\"Select store...\"></select>\n\t\t\t\t</label>\n\t\t\t\t<label>\n\t\t\t\t\tSelect Region\n\t\t\t\t\t<select name=\"regions\" data-placeholder=\"Select region...\">\n\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "region") == runtime.contextOrFrameLookup(context, frame, "null")?"selected":""), env.opts.autoescape);
output += " value=\"ALL\">All</option>\n\t\t\t\t\t\t";
frame = frame.push();
var t_3 = (lineno = 51, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "regionList"), "regionList", context, []));
if(t_3) {var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("regionItem", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\n\t\t\t\t\t\t<option ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "region") == runtime.memberLookup((t_4),"region")?"selected":""), env.opts.autoescape);
output += " value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_4),"region"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_4),"name"), env.opts.autoescape);
output += "</option>\n\t\t\t\t\t\t";
;
}
}
frame = frame.pop();
output += "\n\t\t\t\t\t</select>\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\n\t \t<div class=\"large-12 columns radioContainer\">\n\t\t    <label>Discount provided by</label>\n\t    \t<input type=\"radio\" name=\"discountProvider\" value=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discount_provider_value") == 0?"checked":""), env.opts.autoescape);
output += " id=\"publisher\"><label for=\"publisher\">Publisher</label>\n\t\t\t<input type=\"radio\" name=\"discountProvider\" value=\"1\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discount_provider_value") == 1?"checked":""), env.opts.autoescape);
output += " id=\"itsi\"><label for=\"itsi\">ITSI</label>\n\t\t\t<input type=\"radio\" name=\"discountProvider\" value=\"2\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discount_provider_value") == 2?"checked":""), env.opts.autoescape);
output += " id=\"both\"><label for=\"both\">Both</label>\n\t  \t</div>\n\n\t  \t<div class=\"large-12 columns radioContainer\">\n\t\t    <label>Discount type</label>\n\t\t    <input type=\"radio\" name=\"discountType\" value=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discount_type_value") == 0?"checked":""), env.opts.autoescape);
output += " id=\"percentage\"><label for=\"percentage\">Percentage</label>\n\t\t    <input type=\"radio\" name=\"discountType\" value=\"1\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "discount_type_value") == 1?"checked":""), env.opts.autoescape);
output += " id=\"fixed\"><label for=\"fixed\">New Price</label>\n\t  \t</div>\n\n\t  \t<div class=\"large-12 columns\" id=\"percentageArea\">\n\t  \t\t<label id=\"publisherDiscountLabel\">Publisher provided %\n\t\t\t\t<input type=\"number\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "publisher_discount")?runtime.contextOrFrameLookup(context, frame, "publisher_discount"):env.getFilter("escape").call(context, 0)), env.opts.autoescape);
output += "\" min=\"0\" max=\"100\" name=\"publisherDiscount\"/>\n\t\t\t</label>\n\t\t\t<label id=\"itsiDiscountLabel\">ITSI provided %\n\t\t\t  <input type=\"number\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "itsi_discount")?runtime.contextOrFrameLookup(context, frame, "itsi_discount"):env.getFilter("escape").call(context, 0)), env.opts.autoescape);
output += "\" min=\"0\" max=\"100\" name=\"itsiDiscount\"/>\n\t\t  </label>\n\t  \t</div>\n\n\t  \t<div class=\"large-12 columns\" id=\"currencyArea\">\n\t\t\t<div class=\"row\">\n\t\t\t\t<div class=\"large-2 columns aud priceBlock\">\n\t\t\t\t\t<label>AUD\n\t\t\t\t\t\t<input type=\"number\" name=\"aud\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "aud") || runtime.contextOrFrameLookup(context, frame, "aud") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "aud")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns eur priceBlock\">\n\t\t\t\t\t<label>EUR\n\t\t\t\t\t\t<input type=\"number\" name=\"eur\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "eur") || runtime.contextOrFrameLookup(context, frame, "eur") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "eur")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns gbp priceBlock\">\n\t\t\t\t\t<label>GBP\n\t\t\t\t\t\t<input type=\"number\" name=\"gbp\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "gbp") || runtime.contextOrFrameLookup(context, frame, "gbp") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "gbp")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns usd priceBlock\">\n\t\t\t\t\t<label>USD\n\t\t\t\t\t\t<input type=\"number\" name=\"usd\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "usd") || runtime.contextOrFrameLookup(context, frame, "usd") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "usd")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += "/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"large-2 columns zar end priceBlock\">\n\t\t\t\t\t<label>ZAR\n\t\t\t\t\t\t<input type=\"number\" name=\"zar\" value=\"";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "zar") || runtime.contextOrFrameLookup(context, frame, "zar") == 0?env.getFilter("toFixed").call(context, runtime.contextOrFrameLookup(context, frame, "zar")):""), env.opts.autoescape);
output += "\" max=\"1000\" min=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "reviewed")?"readonly":""), env.opts.autoescape);
output += " id=\"currency\"/>\n\t\t\t\t\t</label>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\t</div>\n\n\t\t<div class=\"large-12 columns\">\n\t\t\t<h4>Discounted Products</h4>\n\t\t</div>\n\n\n\t\t<ul class=\"tabs\" data-tabs id=\"discount-tabs\">\n\t\t\t<li class=\"tabs-title is-active\" data-name=\"default\"><a href=\"#panel1\" aria-selected=\"true\">Products</a></li>\n\t\t\t<li class=\"tabs-title\" data-name=\"licenses\"><a data-tabs-target=\"panel2\" href=\"#panel2\">Licenses</a></li>\n\t\t\t<li class=\"tabs-title\" data-name=\"collections\"><a data-tabs-target=\"panel3\" href=\"#panel3\">Collections</a></li>\n\t\t</ul>\n\n\t\t<div class=\"tabs-content\" data-tabs-content=\"discount-tabs\">\n\t\t\t<div class=\"tabs-panel is-active\" id=\"panel1\">\n\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label for=\"allPublishers\"><input id=\"allPublishers\" ";
output += runtime.suppressValue(((runtime.contextOrFrameLookup(context, frame, "publishers") == runtime.contextOrFrameLookup(context, frame, "null") && (lineno = 129, colno = 102, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, [])) == false)?"checked":""), env.opts.autoescape);
output += " name=\"allPublishers\" type=\"checkbox\" /> All Publishers</label>\n\t\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t\tPublisher\n\t\t\t\t\t\t\t\t<select multiple name=\"publishers\" data-placeholder=\"Select publisher(s)...\">\n\t\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t    <label>Product Type</label>\n\t\t\t\t\t    <input type=\"radio\" name=\"bookType\" value=\"4\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "book_type") == 4?"checked":""), env.opts.autoescape);
output += " id=\"allProducts\" required><label for=\"allProducts\">All Products</label>\n\t\t\t\t    \t<input type=\"radio\" name=\"bookType\" value=\"0\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "book_type") == 0?"checked":""), env.opts.autoescape);
output += " id=\"allBooks\" required><label for=\"allBooks\">All Books</label>\n\t\t\t\t    \t<input type=\"radio\" name=\"bookType\" value=\"1\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "book_type") == 1?"checked":""), env.opts.autoescape);
output += " id=\"epub\" required><label for=\"epub\">EPUBs</label>\n\t\t\t\t\t\t<input type=\"radio\" name=\"bookType\" value=\"2\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "book_type") == 2?"checked":""), env.opts.autoescape);
output += " id=\"pdf\" required><label for=\"pdf\">PDFs</label>\n\t\t\t\t\t\t<input type=\"radio\" name=\"bookType\" value=\"5\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "book_type") == 5?"checked":""), env.opts.autoescape);
output += " id=\"services\" required><label for=\"services\">Services</label>\n\t\t\t\t  \t</div>\n\t\t\t\t\t<div class=\"large-12 columns exclusions\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tInclude Subjects - Leave blank for All.\n\t\t\t\t\t\t\t<select multiple name=\"subjects\" data-placeholder=\"Select subjects(s). Leave blank for All.\">\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t\t<input name=\"excludeSubjects\" id=\"excludeSubjects\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "exclude_subjects")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\"><label for=\"excludeSubjects\">Exclude the above items instead of including them</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-12 columns exclusions\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tInclude Grades - Leave blank for All\n\t\t\t\t\t\t\t<select name=\"grades\" data-placeholder=\"Select grade(s). Leave blank for All.\" multiple></select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t\t<input name=\"excludeGrades\" id=\"excludeGrades\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "exclude_grades")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\"><label for=\"excludeGrades\">Exclude the above items instead of including them</label>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"large-12 columns exclusions\">\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\tInclude ISBNs - Leave blank for All.\n\t\t\t\t\t\t\t<input type=\"text\" name=\"isbns\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "isbns"), env.opts.autoescape);
output += "\" placeholder=\"Comma separated list.\" />\n\t\t\t\t\t\t</label>\n\t\t\t\t\t\t<input name=\"excludeIsbns\" id=\"excludeIsbns\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "exclude_isbns")?"checked":""), env.opts.autoescape);
output += " type=\"checkbox\"><label for=\"excludeIsbns\">Exclude the above items instead of including them</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\n\t\t\t</div>\n\n\n\t\t\t<div class=\"tabs-panel\" id=\"panel2\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>\tSelect Licenses</label>\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<select multiple name=\"licenses\" data-placeholder=\"Select license(s)...\">\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\n\t\t\t<div class=\"tabs-panel\" id=\"panel3\">\n\t\t\t\t<div class=\"row\">\n\t\t\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t\t\t<label>\tSelect Collections</label>\n\t\t\t\t\t\t<label>\n\t\t\t\t\t\t\t<select multiple name=\"collections\" data-placeholder=\"Select collection(s)...\">\n\t\t\t\t\t\t\t</select>\n\t\t\t\t\t\t</label>\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\n\n</div>\n<div class=\"modal-footer left\">\n\t<button class=\"button save success\">";
output += runtime.suppressValue(((lineno = 201, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "isNew"), "isNew", context, []))?("Create"):("Update")), env.opts.autoescape);
output += " </button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<button class=\"button small skip secondary\">Cancel</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t<span class=\"spinner-icon spinner-small hide spinner-working\"/> <span class=\"message hide\"></span>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var main_oldRoot = main_obj.root;
main_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	main_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = new nunjucks.Template( {obj: main_obj, type: "code"}, env );
