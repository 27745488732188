module.exports={
	"Other": [
		{"name": "All Grades", "value": "ALL"},
		{"name": "Teacher/Educator/Lecturer", "value": "TE", "alt": 0},
		{"name": "Training", "value": "TRN"}
	],

	"Pre-primary": [
		{"name": "Grade 0", "value": "G0"},
		{"name": "Grade R", "value": "GR"},
		{"name": "Grade RR", "value": "GRR"},
		{"name": "Grade RRR", "value": "GRRR"}
	],

	"Primary": [
		{"name": "Grade 1", "value": "G1", "alt": 1, "lexical": "Grade 01"},
		{"name": "Grade 2", "value": "G2", "alt": 2, "lexical": "Grade 02"},
		{"name": "Grade 3", "value": "G3", "alt": 3, "lexical": "Grade 03"},
		{"name": "Grade 4", "value": "G4", "alt": 4, "lexical": "Grade 04"},
		{"name": "Grade 5", "value": "G5", "alt": 5, "lexical": "Grade 05"},
		{"name": "Grade 6", "value": "G6", "alt": 6, "lexical": "Grade 06"},
		{"name": "Grade 7", "value": "G7", "alt": 7, "lexical": "Grade 07"}
	],

	"Secondary": [
		{"name": "Grade 8", "value": "G8", "alt": 8, "lexical": "Grade 08"},
		{"name": "Grade 9", "value": "G9", "alt": 9, "lexical": "Grade 09"},
		{"name": "Grade 10", "value": "G10", "alt": 10},
		{"name": "Grade 11", "value": "G11", "alt": 11},
		{"name": "Grade 12", "value": "G12", "alt": 12}
	],

	"Tertiary": [
		{"name": "1st Year", "value": "T1", "alt": 13},
		{"name": "2nd Year", "value": "T2", "alt": 14},
		{"name": "3rd Year", "value": "T3", "alt": 15},
		{"name": "4th Year", "value": "T4", "alt": 16},
		{"name": "5th Year", "value": "T5"},
		{"name": "6th Year", "value": "T6"},
		{"name": "Honours", "value": "T7"},
		{"name": "Masters", "value": "T8"},
		{"name": "Doctorate", "value": "T9"},
		{"name": "General Tertiary", "value": "TX" },
		{"name": "Skills Course", "value": "SK" },
		{"name": "Short Course", "value": "SC" }
	],

	"NQF": [
		{"name": "NQF1", "value": "NQF1", "lexical": "NQF01"},
		{"name": "NQF2", "value": "NQF2", "lexical": "NQF02"},
		{"name": "NQF3", "value": "NQF3", "lexical": "NQF03"},
		{"name": "NQF4", "value": "NQF4", "lexical": "NQF04"},
		{"name": "NQF5", "value": "NQF5", "lexical": "NQF05"},
		{"name": "NQF6", "value": "NQF6", "lexical": "NQF06"},
		{"name": "NQF7", "value": "NQF7", "lexical": "NQF07"},
		{"name": "NQF8", "value": "NQF8", "lexical": "NQF08"},
		{"name": "NQF9", "value": "NQF9", "lexical": "NQF09"},
		{"name": "NQF10", "value": "NQF10"}
	],

	"KS": [
		{"name": "KS1", "value": "KS1", "lexical": "KS01"},
		{"name": "KS2", "value": "KS2", "lexical": "KS02"},
		{"name": "KS3", "value": "KS3", "lexical": "KS03"},
		{"name": "KS4", "value": "KS4", "lexical": "KS04"},
		{"name": "KS5", "value": "KS5", "lexical": "KS05"}
	],

	"Years": [
		{"name": "Year 1", "value": "Y1", "lexical": "Y01"},
		{"name": "Year 2", "value": "Y2", "lexical": "Y02"},
		{"name": "Year 3", "value": "Y3", "lexical": "Y03"},
		{"name": "Year 4", "value": "Y4", "lexical": "Y04"},
		{"name": "Year 5", "value": "Y5", "lexical": "Y05"},
		{"name": "Year 6", "value": "Y6", "lexical": "Y06"},
		{"name": "Year 7", "value": "Y7", "lexical": "Y07"},
		{"name": "Year 8", "value": "Y8", "lexical": "Y08"},
		{"name": "Year 9", "value": "Y9", "lexical": "Y09"},
		{"name": "Year 10", "value": "Y10"},
		{"name": "Year 11", "value": "Y11"},
		{"name": "Year 12", "value": "Y12"},
		{"name": "Year 13", "value": "Y13"}
	],

	"NCV": [
		{"name": "Level 2", "value": "NCV2"},
		{"name": "Level 3", "value": "NCV3"},
		{"name": "Level 4", "value": "NCV4"}
	],

	"Nated": [
		{"name": "N1", "value": "N1"},
		{"name": "N2", "value": "N2"},
		{"name": "N3", "value": "N3"},
		{"name": "N4", "value": "N4"},
		{"name": "N5", "value": "N5"},
		{"name": "N6", "value": "N6"}
	]
}