module.exports={
	"Australia": {
		"name": "Australia",
		"yearStart": 1,
		"productRegions": ["AU"]
	},
	"England": {
		"name": "UK and Ireland",
		"yearStart": 8,
		"productRegions": ["GB"]
	},
	"Scotland": {
		"name": "Scotland",
		"yearStart": 7,
		"productRegions": ["GB"]
	},
	"South Africa": {
		"name": "South Africa",
		"yearStart": 0,
		"productRegions": ["ZA"]
	},
	"United Arab Emirates": {
		"name": "United Arab Emirates",
		"yearStart": 7,
		"productRegions": ["AE"]
	},
	"United States": {
		"name": "United States",
		"yearStart": 1,
		"productRegions": ["US"]
	},
	"Namibia": {
		"name": "Namibia",
		"yearStart": 0,
		"productRegions": ["NA"]
	}
}