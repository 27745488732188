var nunjucks = require( "nunjucks" );
var env = nunjucks.env || new nunjucks.Environment();env.opts.autoescape = false;
var editor_obj = (function () {function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "\n\t<div class=\"modal-title\"> Quote Details </div>\n\t<div class=\"modal-body\">\n\t\t<div class=\"row\">\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<h5>Billing</h5>\n\t\t\t\t<input id=\"applyVat\" name=\"applyVat\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "vat")?"checked":""), env.opts.autoescape);
output += " ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += " type=\"checkbox\" />\n\t\t\t\t<label for=\"applyVat\">Apply VAT</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tAccount Manager\n\t\t\t\t\t<select name=\"accountManager\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += "></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tDelivery Cost (Incl) (ZAR)\n\t\t\t\t\t<input type=\"number\" name=\"shippingCost\" placeholder=\"0.00\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "shipping_cost"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<hr />\n\t\t\t\t<h5>Client</h5>\n\t\t\t\t<label>\n\t\t\t\t\tSearch Order to Cash\n\t\t\t\t\t<select name=\"o2cClient\"></select>\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tAccount ID\n\t\t\t\t\t<input type=\"text\" name=\"clientGuid\" disabled value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_guid"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPastel Account Name\n\t\t\t\t\t<input type=\"text\" name=\"clientPastelName\" disabled value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_pastel_name"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tClient *\n\t\t\t\t\t<input type=\"text\" name=\"clientName\" disabled placeholder=\"Name\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_name"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tContact *\n\t\t\t\t\t<input type=\"text\" name=\"clientContact\" disabled placeholder=\"Contact\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_contact_person"), env.opts.autoescape);
output += "\" />\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tEmail *\n\t\t\t\t\t<input type=\"text\" name=\"clientEmail\" disabled placeholder=\"Email\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_contact_email"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tTel *\n\t\t\t\t\t<input type=\"text\" name=\"clientTel\" disabled placeholder=\"Tel\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_contact_number"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tReg Nr\n\t\t\t\t\t<input type=\"text\" name=\"clientRegNr\" disabled placeholder=\"Registration Number\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_reg_nr"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tVat Nr\n\t\t\t\t\t<input type=\"text\" name=\"clientVatNr\" disabled placeholder=\"Vat Number\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_vat_nr"), env.opts.autoescape);
output += "\">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-12 columns\">\n\t\t\t\t<hr />\n\t\t\t\t<h5>Delivery Address</h5>\n\t\t\t\t<label>\n\t\t\t\t\tStreet\n\t\t\t\t\t<input type=\"text\" name=\"clientStreet\" placeholder=\"Street\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_street"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tSuburb\n\t\t\t\t\t<input type=\"text\" name=\"clientSuburb\" placeholder=\"Suburb\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_suburb"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tCity\n\t\t\t\t\t<input type=\"text\" name=\"clientCity\" placeholder=\"City\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_city"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tProvince\n\t\t\t\t\t<input type=\"text\" name=\"clientProvince\" placeholder=\"Province\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_province"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\n\t\t\t<div class=\"large-3 columns\">\n\t\t\t\t<label>\n\t\t\t\t\tPostal Code\n\t\t\t\t\t<input type=\"text\" name=\"clientPostCode\" placeholder=\"Post Code\" value=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "client_postal_code"), env.opts.autoescape);
output += "\" ";
output += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "status") == 5?"disabled":""), env.opts.autoescape);
output += ">\n\t\t\t\t</label>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n\t<div class=\"modal-footer left\">\n\t\t<button class=\"button save success\">Done</button>&nbsp;&nbsp;&nbsp;&nbsp;\n\t\t<button class=\"button small skip secondary\">Cancel</button>\n\t</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};
})();
var editor_oldRoot = editor_obj.root;
editor_obj.root = function( env, context, frame, runtime, cb ) {
	var oldGetTemplate = env.getTemplate;
	env.getTemplate = function( name, ec, parentName, cb ) {
		if( typeof ec === "function" ) {
			cb = ec;
			ec = false;
		}
		var _require = function(name) {
			try {
				return require(name);
			} catch (e) {
				if ( frame.get( "_require" ) ) return frame.get( "_require" )( name )
			}
		};
		var tmpl = _require( name );
		frame.set( "_require", _require );
		if( ec ) tmpl.compile();
		cb( null, tmpl );
	};	editor_oldRoot( env, context, frame, runtime, function( err, res ) {
		env.getTemplate = oldGetTemplate;
		cb( err, res );
	} );
};
module.exports = {
	"editor": new nunjucks.Template( {obj: editor_obj, type: "code"}, env),};
